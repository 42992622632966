import { Base } from '.';

export class Inconterms extends Base {
  read = async (params) => {
    const result = await this.apiClient.get('/incoterms', { query: { ...params } });

    return result;
  };

  create = async (data) => {
    await this.apiClient.post('/incoterms', { body: { ...data } });
  };

  update = async (data) => {
    await this.apiClient.put('/incoterms', { body: { ...data } });
  };

  delete = async ({ id }) => {
    await this.apiClient.delete(`/incoterms/${id}`);
  };
}
