import dayjs from 'dayjs';
import { getProperWagonName } from './getProperWagonName';

export function normalize1520BuilderData(data) {
  const tableData = data.map((record) => ({
    ...record,
    reloadWagonNumber: getProperWagonName(record, 'reload', 'reloadWagonNumber'),
    arrivalDate: record?.arrivalDate && dayjs(record.arrivalDate).format('DD.MM.YYYY'),
    inclusionDate: record?.inclusionDate && dayjs(record.inclusionDate).format('DD.MM.YYYY'),
    recordDate: record?.recordDate && dayjs.utc(record.recordDate).format('DD.MM.YYYY, HH:mm'),
    trackingDate:
      record?.trackingDate && dayjs.utc(record.trackingDate).format('DD.MM.YYYY, HH:mm'),
  }));

  const modalData = data.map((record) => ({
    ...record,
    reloadId: record.reload.id,
    reloadWagonNumber: getProperWagonName(record, 'reload', 'reloadWagonNumber'),
    arrivalDate: record?.arrivalDate && dayjs(record.arrivalDate),
    inclusionDate: record?.inclusionDate && dayjs(record.inclusionDate),
    trackingDate: record?.trackingDate && dayjs.utc(record.trackingDate),
    recordDate: record?.recordDate && dayjs.utc(record.recordDate),
  }));

  return { tableData, modalData };
}
