import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Card, theme, Typography } from 'antd';
import { useTranslation } from '@features/localization';
import { PAGE_KEYS } from '@constants';

const { useToken } = theme;

export function WagonCard({ data }) {
  const { t } = useTranslation();
  const { token } = useToken();

  if (!data) return null;

  return (
    <Card style={{ marginBottom: '0.5rem' }} bodyStyle={{ padding: '0.8rem' }}>
      <div
        style={{
          display: 'grid',
          rowGap: '0.25rem',
          columnGap: '1rem',
          gridTemplateColumns: 'repeat(6, 0.5fr)',
          gridTemplateRows: 'repeat(2, 0.5fr)',
        }}
      >
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.shopName')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem', fontWeight: 600, color: token.colorPrimary }}>
            <Link to={`/${PAGE_KEYS.CLIENTS}/${data.shop.id}`} target='blank'>
              {data.shop.name}
            </Link>
          </Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.order')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>{data.order.name}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.number')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem', fontWeight: 600, color: token.colorPrimary }}>
            <Link to={`/${PAGE_KEYS.TRACKING}?startWith=${data.number}`} target='blank'>
              {data.number}
            </Link>
          </Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.weight')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>{data?.weight || '—'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.quantity')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>{data?.quantity || '—'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.sendDate')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>
            {dayjs(data.sendDate).format('DD.MM.YYYY') || '—'}
          </Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.count')} (1435)</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>
            {data?.statistic?.['1435'].count || '—'}
          </Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.weight')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>
            {data?.statistic?.['1435'].weight || '—'}
          </Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.quantity')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>
            {data?.statistic?.['1435'].quantity || '—'}
          </Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.count')} (1520)</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>
            {data?.statistic?.['1520'].count || '—'}
          </Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.weight')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>
            {data?.statistic?.['1520'].weight || '—'}
          </Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.quantity')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>
            {data?.statistic?.['1520'].quantity || '—'}
          </Typography.Text>
        </div>
      </div>
    </Card>
  );
}

WagonCard.propTypes = {
  data: PropTypes.object,
};

WagonCard.defaultProps = {
  data: null,
};
