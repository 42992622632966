export function normalizeApiData(data) {
  return {
    id: data.id,
    countryId: data.country?.value,
    name: data.name,
    stationNumber: data.number,
    psc: data.psc,
    note: data.note,
  };
}
