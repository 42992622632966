import { Base } from '.';

export class Currency extends Base {
  read = async () => {
    const result = await this.apiClient.get('/currency');

    return result;
  };

  readById = async ({ id }) => {
    const result = await this.apiClient.get(`/currency/${id}`);

    return result;
  };

  create = async (data) => {
    await this.apiClient.post('/currency', { body: { ...data } });
  };

  delete = async ({ id }) => {
    await this.apiClient.delete(`/currency/${id}`);
  };
}
