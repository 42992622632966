import { getProperDate, getProperDateWithTime } from '@lib';

export function normalize1435ApiData(data, mode) {
  const normalized = {
    ...data,
    id: Number(data.id),
    isEmpty: data?.isEmpty?.hasOwnProperty('value') ? data.isEmpty.value : data.isEmpty,
    recordDate: data?.recordDate && getProperDateWithTime(data?.recordDate),
    trackingDate: data?.trackingDate && getProperDateWithTime(data?.trackingDate),
    wagonSendDate: data?.wagonSendDate && getProperDate(data?.wagonSendDate),
  };

  return mode === 'CREATE' || mode === 'COPY' ? [normalized] : normalized;
}
