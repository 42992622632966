import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router';
import dayjs from 'dayjs';
import { BuilderModal } from './BuilderModal';
import { BuilderModalButton } from '.';

// eslint-disable-next-line max-lines-per-function
export function BuilderModalContainer({
  mode,
  defaultData,
  schema,
  overrrides,
  pageKey,
  isPageLoading,
  refreshData,
  normalizer,
  validation,
  query,
  prefill,
}) {
  const params = useParams();

  const trackingDateSessionStorage = sessionStorage.getItem('trackingDateSession');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (mode === 'UPDATE' || mode === 'COPY') setData(defaultData);
    if (mode === 'DELETE') setData({ id: defaultData.id });
    if (mode === 'ADD') {
      setData({
        number: defaultData.number,
        wagonId: defaultData.id,
        wagonSendDate: dayjs(defaultData.sendDate),
        ...(trackingDateSessionStorage && {
          trackingDate: dayjs(trackingDateSessionStorage),
        }),
      });
    }
  }, [defaultData, trackingDateSessionStorage]);

  const prefillData = useCallback(
    async (prefillOptions) => {
      try {
        const { api, normalize } = prefillOptions;

        const fetchedData = await api(params);

        const normalized = normalize ? normalize(fetchedData) : fetchedData;

        setData(normalized);
      } catch (error) {
        // Add toast if needed
        console.warn(error);
      }
    },
    [query]
  );

  useEffect(() => {
    if (prefill && mode === 'CREATE') {
      prefillData(prefill);
    }
  }, [prefill, mode, isModalOpen]);

  const handleChange = useCallback((name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleChangeError = useCallback(
    (name, value) => {
      setErrors((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    },
    [errors]
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setErrors({});
    if (mode === 'CREATE') {
      setData({});
    }
  }, []);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <div onClick={(e) => e.stopPropagation()}>
      <BuilderModalButton
        mode={mode}
        defaultData={defaultData}
        pageKey={pageKey}
        isPageLoading={isPageLoading}
        handleOpenModal={handleOpenModal}
      />
      {isModalOpen && (
        <BuilderModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          mode={mode}
          data={data}
          setData={setData}
          handleChange={handleChange}
          handleChangeError={handleChangeError}
          handleCloseModal={handleCloseModal}
          errors={errors}
          setErrors={setErrors}
          schema={schema}
          modalButtons={overrrides?.modalButtons}
          pageKey={pageKey}
          isPageLoading={isPageLoading}
          refreshData={refreshData}
          normalizer={normalizer}
          validationSchema={validation}
          query={query}
        />
      )}
    </div>
  );
}

BuilderModalContainer.propTypes = {
  mode: PropTypes.oneOf(['CREATE', 'UPDATE', 'COPY', 'DELETE', 'ADD']).isRequired,
  defaultData: PropTypes.object,
  pageKey: PropTypes.string.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  refreshData: PropTypes.func.isRequired,
  schema: PropTypes.shape({
    api: PropTypes.func,
    fields: PropTypes.array,
  }).isRequired,
  overrrides: PropTypes.object,
  normalizer: PropTypes.func,
  validation: PropTypes.object,
  query: PropTypes.object,
  prefill: PropTypes.shape({
    api: PropTypes.func,
    normalize: PropTypes.func,
  }),
};

BuilderModalContainer.defaultProps = {
  defaultData: undefined,
  normalizer: undefined,
  validation: undefined,
  query: undefined,
  overrrides: undefined,
  prefill: undefined,
};
