/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-lines-per-function */

import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from '@features/localization';
import { ItemLabel } from '@components';
import { Inputs } from '@features/builder';
import { SELECT_BOOL_OPTIONS } from '@constants';

export function UpdateFields({
  data,
  errors,
  setData,
  pageKey,
  handleSubmit,
  handleDelete,
  isLoading,
}) {
  const { t } = useTranslation();

  const { Panel } = Collapse;

  const handleChange = useCallback(
    (name, value, key) => {
      const newData = data.map((rowData) =>
        rowData.fieldId === key ? { ...rowData, [name]: value } : rowData
      );

      setData((prev) => ({ ...prev, modalData: newData }));
    },
    [data]
  );

  const handleSave = useCallback(
    (id, createNewMail = false) => handleSubmit(id, createNewMail),
    [data]
  );

  const handleRemove = useCallback((id) => handleDelete(id), [data]);

  const preventPropagation = useCallback((event) => event.stopPropagation(), []);

  const deleteBtn = (id) => (
    <Popconfirm
      title={t(`modal.delete`)}
      description={t(`${pageKey}.modal.deleteText`)}
      okText={t(`modal.delete`)}
      cancelText={t(`modal.cancel`)}
      onConfirm={(event) => {
        preventPropagation(event);
        handleRemove(id);
      }}
      onCancel={preventPropagation}
    >
      <Button danger type='text' onClick={preventPropagation} icon={<DeleteOutlined />} />
    </Popconfirm>
  );

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '40.5rem',
        minHeight: '30rem',
        overflowY: 'auto',
        marginRight: '-1rem',
        paddingRight: '1rem',
      }}
    >
      <Collapse defaultActiveKey={['0']} accordion>
        {data?.map((_, index) => {
          const rowData = data?.find(({ fieldId }) => fieldId === index) || {};

          const rowErrors = errors.find(({ fieldId }) => fieldId === index) || {};

          return (
            <Panel
              header={`${t(`${pageKey}.fields.transshipment`)} ${index + 1}`}
              key={index}
              extra={deleteBtn(index)}
            >
              <div key={index}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '1rem',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateRows: 'auto',
                      gridTemplateColumns: 'repeat(auto-fit, minmax(10rem, 1fr))',
                      rowGap: '0.5rem',
                      columnGap: '0.5rem',
                      marginBottom: '0.5rem',
                    }}
                  >
                    <ItemLabel id='smgs' label={t(`${pageKey}.fields.smgs`)}>
                      <Inputs.Input
                        id={'smgs'}
                        placeholder={t(`${pageKey}.fields.smgs`)}
                        value={rowData?.smgs}
                        data={rowData}
                        errors={rowErrors}
                        onChange={handleChange}
                        fieldKey={index}
                      />
                    </ItemLabel>
                    <ItemLabel id='smgsDate' label={t(`${pageKey}.fields.smgsDate`)}>
                      <Inputs.Date
                        id={'smgsDate'}
                        placeholder={t(`${pageKey}.fields.smgsDate`)}
                        fieldProps={{ style: { width: '100%' } }}
                        data={rowData}
                        errors={rowErrors}
                        onChange={handleChange}
                        fieldKey={index}
                      />
                    </ItemLabel>
                    <ItemLabel id='reloadDate' label={t(`${pageKey}.fields.reloadDate`)}>
                      <Inputs.Date
                        id={'reloadDate'}
                        placeholder={t(`${pageKey}.fields.reloadDate`)}
                        fieldProps={{ style: { width: '100%' } }}
                        data={rowData}
                        errors={rowErrors}
                        onChange={handleChange}
                        fieldKey={index}
                      />
                    </ItemLabel>
                    <ItemLabel id='weight' label={t(`${pageKey}.fields.weight`)}>
                      <Inputs.Input
                        id={'weight'}
                        fieldProps={{ type: 'number', min: 1, style: { width: '100%' } }}
                        placeholder={t(`${pageKey}.fields.weight`)}
                        value={rowData?.weight}
                        data={rowData}
                        errors={rowErrors}
                        onChange={handleChange}
                        fieldKey={index}
                      />
                    </ItemLabel>
                    <ItemLabel id='quantity' label={t(`${pageKey}.fields.quantity`)}>
                      <Inputs.Input
                        id={'quantity'}
                        fieldProps={{ type: 'number', min: 1, style: { width: '100%' } }}
                        placeholder={t(`${pageKey}.fields.quantity`)}
                        value={rowData?.quantity}
                        data={rowData}
                        errors={rowErrors}
                        onChange={handleChange}
                        fieldKey={index}
                      />
                    </ItemLabel>
                    <ItemLabel id='number' label={t(`${pageKey}.fields.number`)}>
                      <Inputs.Input
                        id={'number'}
                        placeholder={t(`${pageKey}.fields.number`)}
                        value={rowData?.number}
                        data={rowData}
                        errors={rowErrors}
                        onChange={handleChange}
                        fieldKey={index}
                      />
                    </ItemLabel>
                    <ItemLabel id='isTracking' label={t(`${pageKey}.fields.isTracking`)}>
                      <Inputs.Select
                        id={'isTracking'}
                        placeholder={t(`${pageKey}.fields.isTracking`)}
                        fieldProps={{
                          options: SELECT_BOOL_OPTIONS,
                        }}
                        data={rowData}
                        errors={rowErrors}
                        onChange={handleChange}
                        fieldKey={index}
                      />
                    </ItemLabel>
                    <ItemLabel id='isStatistic' label={t(`${pageKey}.fields.isStatistic`)}>
                      <Inputs.Select
                        id={'isStatistic'}
                        placeholder={t(`${pageKey}.fields.isStatistic`)}
                        fieldProps={{
                          options: SELECT_BOOL_OPTIONS,
                        }}
                        data={rowData}
                        errors={rowErrors}
                        onChange={handleChange}
                        fieldKey={index}
                      />
                    </ItemLabel>
                  </div>
                  <ItemLabel id='note' label={t(`${pageKey}.fields.note`)}>
                    <Inputs.Input
                      id={'note'}
                      placeholder={t(`${pageKey}.fields.note`)}
                      fieldProps={{ type: 'textarea' }}
                      value={rowData?.note}
                      data={rowData}
                      errors={rowErrors}
                      onChange={handleChange}
                      fieldKey={index}
                    />
                  </ItemLabel>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                  <Button onClick={() => handleSave(index)} loading={isLoading} type='primary'>
                    {t(`modal.save`)}
                  </Button>
                  <Button onClick={() => handleSave(index, true)} loading={isLoading} type='dashed'>
                    {t(`modal.createNewMail`)}
                  </Button>
                </div>
              </div>
            </Panel>
          );
        })}
      </Collapse>
    </form>
  );
}

UpdateFields.propTypes = {
  data: PropTypes.array,
  errors: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  pageKey: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

UpdateFields.defaultProps = {
  data: undefined,
};
