import dayjs from 'dayjs';

export function normalizeUpdateData(data) {
  const { reloads, ...wagonData } = data;

  const modalData = reloads.map((entry, fieldId) => ({
    ...entry,
    reloadDate: dayjs(entry.reloadDate),
    fieldId,
  }));

  return { modalData, wagonData };
}
