/* eslint-disable max-lines-per-function */

import { PAGE_KEYS } from '@constants';
import { Builder, Columns, Inputs, INPUT_NORMALIZERS } from '@features/builder';
import { ApiService } from '@services';
import { getPermissionsOptionList } from '@features/permissions';
import { normalizeBuilderData, normalizeApiData, rolesSchema } from '@features/roles';

export function RolesPage() {
  const pageKey = PAGE_KEYS.ROLES;

  const modalFields = [
    {
      id: 'name',
      required: true,
      component: Inputs.Input,
    },
    {
      id: 'permissions',
      required: true,
      component: Inputs.Select,
      fieldProps: {
        normalizer: INPUT_NORMALIZERS.selectPermission,
        options: getPermissionsOptionList(),
        mode: 'multiple',
        allowClear: true,
        style: { width: '100%' },
      },
    },
  ];

  const CREATE_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const UPDATE_MODAL = {
    api: ApiService[pageKey].update,
    fields: modalFields,
  };

  const DELETE_MODAL = {
    api: ApiService[pageKey].delete,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read,
    columns: [
      {
        id: 'name',
        type: 'string',
        // width: 300,
        component: Columns.Text,
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'UPDATE' }, { type: 'DELETE' }],
        },
        component: Columns.Actions,
      },
    ],
  };

  const schema = {
    CREATE: CREATE_MODAL,
    READ: TABLE_SCHEMA,
    UPDATE: UPDATE_MODAL,
    DELETE: DELETE_MODAL,
    overrides: {
      normalizeBuilderData,
      normalizeApiData,
    },
    validationSchema: rolesSchema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
