import { Base } from '.';

export class Clients extends Base {
  read = async (params) => {
    const result = await this.apiClient.get('/shop', { query: { ...params } });

    return result;
  };

  readById = async ({ id }) => {
    const result = await this.apiClient.get(`/shop/${id}`);

    return result;
  };

  create = async (data) => {
    await this.apiClient.post('/shop', { body: { ...data } });
  };

  update = async (data) => {
    await this.apiClient.put('/shop', { body: { ...data } });
  };

  delete = async ({ id }) => {
    await this.apiClient.delete(`/shop/${id}`, { body: { id } });
  };
}
