export function getProperWagonName(record, keyMain, keyCustom) {
  const {
    [keyMain]: { number: mainName },
    [keyCustom]: customName,
  } = record;

  if (mainName === customName) {
    return mainName;
  }

  return `${mainName}-${customName}`;
}
