import React, { useCallback } from 'react';
import qs from 'query-string';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { ApiService } from '@services';
import { NOTIFICATION_TYPES, PAGE_KEYS } from '@constants';
import { getErrorNotificationMessage } from '@features/errors';
import { showNotification } from '@lib';
import { useTranslation } from '@features/localization';

export function ActionPanel({ selectedRows, setSelectedRows, refreshData, query }) {
  const { t } = useTranslation();

  const handleDeleteRows = useCallback(async () => {
    try {
      await ApiService[PAGE_KEYS.WAGONS].deleteMany({ ids: selectedRows });

      refreshData();

      setSelectedRows([]);

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        message: t('notifications:title.success'),
        description: t(`notifications:messages.${PAGE_KEYS.WAGONS}.DELETE_MANY.success`),
      });
    } catch (error) {
      const message = getErrorNotificationMessage(error.message);

      showNotification(
        message
          ? message
          : showNotification({
              type: NOTIFICATION_TYPES.ERROR,
              message: t('notifications:title.error'),
              description: t(`notifications:messages.${PAGE_KEYS.WAGONS}.DELETE_MANY.error`),
            })
      );
    }
  });

  const handleExportXLS = useCallback(async () => {
    const queryObject = qs.stringify(query);

    try {
      const response = await ApiService[PAGE_KEYS.WAGONS].exportXls(queryObject);

      if (!response.ok) {
        throw new Error('Bad response');
      }

      const contentType = response.headers.get('Content-Type');
      const arrayBuffer = await response.arrayBuffer();

      const blob = new Blob([arrayBuffer], {
        type: contentType,
      });
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = 'docs_wagon_list.xlsx';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('notifications:title.error'),
        description: t(`notifications:messages.${PAGE_KEYS.WAGONS}.EXPORT_XLSX.error`),
      });
    }
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: '1rem' }}>
      <Button type='primary' danger disabled={!selectedRows.length} onClick={handleDeleteRows}>
        Delete
      </Button>
      <Button type='primary' onClick={handleExportXLS}>
        Export XLS
      </Button>
    </div>
  );
}

ActionPanel.propTypes = {
  selectedRows: PropTypes.array.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
};

ActionPanel.defaultProps = {};
