import { PAGE_KEYS } from '@constants';
import { Builder, Columns, Inputs } from '@features/builder';
import { ApiService } from '@services';
import { normalizeBuilderData, normalizeApiData, wagonTypeSchema } from '@features/wagonType';

export function WagonTypePage() {
  const pageKey = PAGE_KEYS.WAGONTYPE;

  const modalFields = [
    {
      id: 'name',
      required: true,
      component: Inputs.Input,
    },
    {
      id: 'description',
      component: Inputs.Input,
      fieldProps: {
        type: 'textarea',
      },
    },
    // {
    //   id: 'isDefault',
    //   component: Inputs.Select,
    //   fieldProps: {
    //     options: SELECT_BOOL_OPTIONS,
    //     defaultValue: false,
    //   },
    // },
  ];

  const CREATE_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const UPDATE_MODAL = {
    api: ApiService[pageKey].update,
    fields: modalFields,
  };

  const COPY_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const DELETE_MODAL = {
    api: ApiService[pageKey].delete,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read,
    columns: [
      {
        id: 'name',
        type: 'string',
        width: 450,
        component: Columns.Text,
      },
      {
        id: 'description',
        type: 'string',
        width: 450,
        component: Columns.Text,
      },
      // {
      //   id: 'isDefault',
      //   width: 450,
      //   component: Columns.Boolean,
      // },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'UPDATE' }, { type: 'DELETE' }, { type: 'COPY' }],
        },
        component: Columns.Actions,
      },
    ],
  };

  const schema = {
    CREATE: CREATE_MODAL,
    READ: TABLE_SCHEMA,
    UPDATE: UPDATE_MODAL,
    DELETE: DELETE_MODAL,
    COPY: COPY_MODAL,
    overrides: {
      normalizeBuilderData,
      normalizeApiData,
    },
    validationSchema: wagonTypeSchema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
