export const permissionsSchema = {
  wagons: {
    key: 'wagons',
    read: 'readonly',
    write: 'full',
    none: 'none',
  },
  clients: {
    key: 'clients',
    read: 'readonly',
    write: 'full',
    none: 'none',
  },
  tracking: {
    key: 'tracking',
    read: 'readonly',
    write: 'full',
    none: 'none',
  },
  transshipment: {
    key: 'reloading',
    read: 'readonly',
    write: 'full',
    none: 'none',
  },
  catalogs: {
    key: 'referenceBooks',
    read: 'readonly',
    write: 'full',
    none: 'none',
  },
  users: {
    key: 'adminPermissions',
    read: 'readonly',
    write: 'full',
    none: 'none',
  },
  roles: {
    key: 'adminPermissions',
    read: 'readonly',
    write: 'full',
    none: 'none',
  },
  configuration: {
    key: 'adminPermissions',
    read: 'readonly',
    write: 'full',
    none: 'none',
  },
};
