import AppConfig from '@config';
import { PAGE_KEYS } from '@constants';
import { ApiClient } from './ApiClient';
import {
  Countries,
  Inconterms,
  Media,
  Organizations,
  Currency,
  Roles,
  Stations,
  Clients,
  User,
  Users,
  Orders,
  Configuration,
  Wagons,
  WagonType,
  Transshipment,
  Tracking,
  TrackingPlace,
  Queue,
  TrackingLogs,
} from './entities';

class ApiService {
  constructor({ apiUrl, token } = {}) {
    if (!apiUrl) {
      throw new Error('Invalid apiUrl');
    }

    const apiClient = new ApiClient({ url: apiUrl, token });

    this[PAGE_KEYS.CURRENCY] = new Currency({ apiClient });
    this[PAGE_KEYS.ROLES] = new Roles({ apiClient });
    this[PAGE_KEYS.ORGANIZATIONS] = new Organizations({ apiClient });
    this[PAGE_KEYS.INCONTERMS] = new Inconterms({ apiClient });
    this[PAGE_KEYS.STATIONS] = new Stations({ apiClient });
    this[PAGE_KEYS.COUNTRIES] = new Countries({ apiClient });
    this[PAGE_KEYS.MEDIA_LIBRARY] = new Media({ apiClient });
    this[PAGE_KEYS.USERS] = new Users({ apiClient });
    this[PAGE_KEYS.CLIENTS] = new Clients({ apiClient });
    this[PAGE_KEYS.ORDERS] = new Orders({ apiClient });
    this[PAGE_KEYS.CONFIGURATION] = new Configuration({ apiClient });
    this[PAGE_KEYS.WAGONS] = new Wagons({ apiClient });
    this[PAGE_KEYS.WAGONTYPE] = new WagonType({ apiClient });
    this[PAGE_KEYS.TRANSSHIPMENT] = new Transshipment({ apiClient });
    this[PAGE_KEYS.TRACKING] = new Tracking({ apiClient });
    this[PAGE_KEYS.TRACKING_PLACE] = new TrackingPlace({ apiClient });
    this[PAGE_KEYS.QUEUE] = new Queue({ apiClient });
    this[PAGE_KEYS.TRACKING_LOGS] = new TrackingLogs({ apiClient });
    this.user = new User({ apiClient });
  }
}

export default new ApiService({
  apiUrl: AppConfig.apiUrl,
});
