import { NOTIFICATION_TYPES } from '@constants';
import i18n from '@i18n';
import { SERVER_ERROR_MESSAGES } from '../constants';

export function getErrorNotificationMessage(serverMessage) {
  if (SERVER_ERROR_MESSAGES.includes(serverMessage)) {
    return {
      type: NOTIFICATION_TYPES.ERROR,
      message: i18n.t('notifications:title.error'),
      description: i18n.t(`errors:server.${serverMessage}`),
    };
  }
}
