/* eslint-disable no-nested-ternary */
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { useTranslation } from '@features/localization';
import { useQuery } from '@features/builder/hooks';
import { userSelectors } from '@features/user';
import { checkWritePermission } from '@features/permissions';
import { BuilderModal } from '../BuilderModal';

export function BuilderHeader({
  schema,
  pageKey,
  isLoading,
  refreshData,
  normalizer,
  validation,
  overrides,
  defaultQuery,
  searchPanel,
}) {
  const { t } = useTranslation();

  const user = useSelector(userSelectors.userData);

  const permissions = user?.role?.permissions ?? [];

  const hasWritePermission = useMemo(() => checkWritePermission(permissions, pageKey), []);

  const [query, setQuery] = useQuery(searchPanel?.fields);

  useEffect(() => {
    setQuery(defaultQuery);
  }, []);

  const renderModal = () => (
    <BuilderModal
      mode='CREATE'
      pageKey={pageKey}
      isPageLoading={isLoading}
      schema={schema}
      refreshData={refreshData}
      normalizer={normalizer}
      prefill={overrides?.prefill}
      validation={validation}
      query={query}
    />
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
      }}
    >
      <Typography.Title>{t(`layout.menu.${pageKey}`)}</Typography.Title>
      {schema && hasWritePermission && (
        <>
          {overrides.headerActions ? (
            <overrides.headerActions
              pageKey={pageKey}
              isPageLoading={isLoading}
              schema={schema}
              refreshData={refreshData}
              normalizer={normalizer}
              validation={validation}
              query={query}
            />
          ) : overrides?.queryAddCondition ? (
            overrides.queryAddCondition(query) && renderModal()
          ) : (
            renderModal()
          )}
        </>
      )}
    </div>
  );
}

BuilderHeader.propTypes = {
  pageKey: PropTypes.string.isRequired,
  schema: PropTypes.shape({
    api: PropTypes.func,
    fields: PropTypes.array,
    heightModal: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  refreshData: PropTypes.func.isRequired,
  normalizer: PropTypes.func,
  validation: PropTypes.object,
  overrides: PropTypes.object,
  defaultQuery: PropTypes.object,
  searchPanel: PropTypes.object,
};

BuilderHeader.defaultProps = {
  schema: null,
  isLoading: false,
  normalizer: undefined,
  validation: undefined,
  overrides: undefined,
  defaultQuery: undefined,
  searchPanel: undefined,
};
