/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Divider, Empty, Spin } from 'antd';
import { useTranslation } from '@features/localization';
import { normalizeUpdateData } from '@features/transshipment';
import { ApiService } from '@services';
import { NOTIFICATION_TYPES, PAGE_KEYS } from '@constants';
import { showNotification } from '@lib';
import { TransshipmentCard } from './TransshipmentCard';

export function Transshipment({ wagonId, handleCancel }) {
  const { t } = useTranslation();

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleFetchData = useCallback(async () => {
    try {
      setIsLoading(true);

      const newData = await ApiService[PAGE_KEYS.TRANSSHIPMENT].readById({ id: wagonId });

      const { modalData: normalizedData } = normalizeUpdateData(newData);

      setData(normalizedData);
    } catch (error) {
      console.warn(error);

      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('notifications:title.error'),
        description: t('notifications:messages.READ.error'),
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <>
      <form
        autoComplete='off'
        style={{
          maxHeight: '23.5rem',
          overflowY: 'auto',
          marginRight: '-1rem',
          paddingRight: '1rem',
        }}
      >
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '23.5rem',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spin />
          </div>
        ) : !data?.length ? (
          <div
            style={{
              height: '23.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Empty description={t(`transshipment.fields.noTransshipments`)} />
          </div>
        ) : (
          data.map((reload, index) => <TransshipmentCard data={reload} key={index} />)
        )}
      </form>
      <Divider style={{ margin: '1rem 0' }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
        <Button disabled={isLoading} onClick={handleCancel}>
          {t(`modal.cancel`)}
        </Button>
      </div>
    </>
  );
}

Transshipment.propTypes = {
  wagonId: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
