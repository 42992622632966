/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines-per-function */

import { PAGE_KEYS } from '@constants';
import { Builder, Columns, Inputs } from '@features/builder';
import { ApiService } from '@services';
import { normalizeBuilderData, normalizeApiData, countriesSchema } from '@features/countries';

export function CountriesPage() {
  const pageKey = PAGE_KEYS.COUNTRIES;

  const modalFields = [
    {
      id: 'short',
      required: true,
      component: Inputs.Input,
    },
    {
      id: 'name',
      required: true,
      component: Inputs.Input,
    },
    {
      id: 'nameEng',
      required: true,
      component: Inputs.Input,
    },
  ];

  const CREATE_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const UPDATE_MODAL = {
    api: ApiService[pageKey].update,
    fields: modalFields,
  };
  const COPY_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const DELETE_MODAL = {
    api: ApiService[pageKey].delete,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read,
    columns: [
      {
        id: 'short',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'name',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'nameEng',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'UPDATE' }, { type: 'DELETE' }, { type: 'COPY' }],
        },
        component: Columns.Actions,
      },
    ],
  };

  const schema = {
    CREATE: CREATE_MODAL,
    READ: TABLE_SCHEMA,
    UPDATE: UPDATE_MODAL,
    COPY: COPY_MODAL,
    DELETE: DELETE_MODAL,
    overrides: {
      normalizeBuilderData,
      normalizeApiData,
    },
    validationSchema: countriesSchema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
