/* eslint-disable react/no-array-index-key */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-lines-per-function */

import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Form } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from '@features/localization';
import { ItemLabel } from '@components';
import { Inputs, INPUT_NORMALIZERS } from '@features/builder';
import { ApiService } from '@services';
import { PAGE_KEYS } from '@constants';

export function Notifications({ data, errors, setErrors, setData, pageKey }) {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (name, value, key) => {
      const newData = data.map((rowData) =>
        rowData.fieldId === key ? { ...rowData, [name]: value } : rowData
      );

      setData(newData);
    },
    [data]
  );

  const handleAdd = useCallback(() => {
    const fieldId = data.length;

    const newData = [
      ...data,
      { fieldId, position: '', firm: undefined, emails: [], notification: [] },
    ];

    setData(newData);
  }, [data]);

  const handleRemove = useCallback(
    (key) => {
      const newData = data
        ?.filter(({ fieldId }) => fieldId !== key)
        .map((fieldValue, index) => ({ ...fieldValue, fieldId: index }));

      const newErrors = errors
        ?.filter(({ fieldId }) => fieldId !== key)
        .map(({ fieldId, ...rest }) => ({
          ...rest,
          fieldId: fieldId > key ? fieldId - 1 : fieldId,
        }));

      setData(newData);
      setErrors(newErrors);
    },
    [data, errors]
  );

  const handleChangeError = useCallback(
    (name, value, key) => {
      const newErrors = errors.map((rowData) =>
        rowData.fieldId === key ? { ...rowData, [name]: value } : rowData
      );

      setErrors(newErrors);
    },
    [errors]
  );

  return (
    <form
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '40.5rem',
        minHeight: '30rem',
        overflowY: 'auto',
        marginRight: '-1rem',
        paddingRight: '1rem',
      }}
    >
      {data.map((_, index) => {
        const rowData = data?.find(({ fieldId }) => fieldId === index) || {};

        const rowErrors = errors.find(({ fieldId }) => fieldId === index) || {};

        return (
          <div key={index}>
            <div style={{ display: 'flex', width: '100%', gap: '0.5rem' }}>
              <Form.Item style={{ width: '100%' }}>
                <ItemLabel id={'position'} label={t(`${pageKey}.fields.position`)}>
                  <Inputs.Input
                    id={'position'}
                    data={rowData}
                    placeholder={t(`${pageKey}.fields.position`)}
                    onChange={handleChange}
                    fieldKey={index}
                    errors={rowErrors}
                    fieldProps={{
                      disabled: index <= 2,
                    }}
                    value={rowData.position}
                  />
                </ItemLabel>
              </Form.Item>
              <Form.Item style={{ width: '100%' }}>
                <ItemLabel id={'firm'} label={t(`${pageKey}.fields.firm`)}>
                  <Inputs.Select
                    id={'firm'}
                    data={rowData}
                    errors={rowErrors}
                    placeholder={t(`${pageKey}.fields.firm`)}
                    onChange={handleChange}
                    fieldKey={index}
                    fieldProps={{
                      api: ApiService[PAGE_KEYS.ORGANIZATIONS].read,
                    }}
                  />
                </ItemLabel>
              </Form.Item>
              <Form.Item style={{ width: '100%' }}>
                <ItemLabel id={'emails'} label={t(`${pageKey}.fields.emails`)}>
                  <Inputs.MultipleInput
                    id={`emails`}
                    data={rowData}
                    errors={rowErrors}
                    handleChangeError={handleChangeError}
                    placeholder={t(`${pageKey}.fields.emails`)}
                    onChange={handleChange}
                    fieldKey={index}
                    pageKey={pageKey}
                    fieldProps={{ id: 'email' }}
                  />
                </ItemLabel>
              </Form.Item>
              <Form.Item style={{ width: '100%' }}>
                <ItemLabel id={'notification'} label={t(`${pageKey}.fields.notification`)}>
                  <Inputs.Select
                    id={'notification'}
                    data={rowData}
                    placeholder={t(`${pageKey}.fields.notification`)}
                    onChange={handleChange}
                    fieldKey={index}
                    errors={rowErrors}
                    fieldProps={{
                      includeAllOption: true,
                      allowClear: true,
                      normalizer: INPUT_NORMALIZERS.selectAll,
                      mode: 'multiple',
                      showArrow: true,
                      options: [
                        { value: 'createNorm', label: 'Vytvorenie vozňa (norm)' },
                        { value: 'reloading', label: 'Prekládka' },
                        { value: 'changeStation', label: 'Zmena stanice' },
                      ],
                    }}
                    style={{ with: '10rem' }}
                  />
                </ItemLabel>
              </Form.Item>
              {index > 2 && (
                <Button
                  style={{ marginTop: '1.6rem' }}
                  type='link'
                  danger
                  onClick={() => handleRemove(index)}
                  icon={<MinusCircleOutlined />}
                />
              )}
            </div>
            {index === 2 && <Divider style={{ margin: '1rem 0' }} />}
          </div>
        );
      })}
      <Button type='dashed' onClick={handleAdd}>
        <PlusOutlined /> {t(`modal.addField`)}
      </Button>
    </form>
  );
}

Notifications.propTypes = {
  data: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  setErrors: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  pageKey: PropTypes.string.isRequired,
};
