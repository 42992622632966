/* eslint-disable max-lines-per-function */

import { PAGE_KEYS } from '@constants';
import { Builder, Columns, Inputs, INPUT_NORMALIZERS } from '@features/builder';
import { ROUTES } from '@features/navigation';
import { ApiService } from '@services';
import { normalizeBuilderData, normalizeApiData, stationsSchema } from '@features/stations';

export function StationsPage() {
  const pageKey = PAGE_KEYS.STATIONS;

  const modalFields = [
    {
      id: 'name',
      required: true,
      component: Inputs.Input,
    },
    {
      id: 'country',
      required: true,
      component: Inputs.Select,
      link: {
        to: ROUTES.SETTINGS.children.CATALOGS.children.COUNTRIES.path,
        target: '_blank',
      },
      fieldProps: {
        api: ApiService[PAGE_KEYS.COUNTRIES].read,
      },
    },
    {
      id: 'number',
      component: Inputs.Input,
    },
    {
      id: 'psc',
      component: Inputs.Input,
    },
    {
      id: 'note',
      component: Inputs.Input,
      fieldProps: { type: 'textarea' },
    },
  ];

  const CREATE_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const UPDATE_MODAL = {
    api: ApiService[pageKey].update,
    fields: modalFields,
  };

  const COPY_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const DELETE_MODAL = {
    api: ApiService[pageKey].delete,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read,
    columns: [
      {
        id: 'country',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'name',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'number',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'psc',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'note',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'UPDATE' }, { type: 'DELETE' }, { type: 'COPY' }],
        },
        component: Columns.Actions,
      },
    ],
    searchPanel: {
      fields: [
        {
          id: 'countryId',
          component: Inputs.Select,
          fieldProps: {
            normalizer: INPUT_NORMALIZERS.selectValues,
            api: ApiService[PAGE_KEYS.COUNTRIES].read,
            apiById: ApiService[PAGE_KEYS.COUNTRIES].readById,
            includeAllOption: true,
            style: { width: '12rem' },
            defaultValue: '',
          },
        },
      ],
    },
  };

  const schema = {
    CREATE: CREATE_MODAL,
    READ: TABLE_SCHEMA,
    UPDATE: UPDATE_MODAL,
    COPY: COPY_MODAL,
    DELETE: DELETE_MODAL,
    overrides: {
      normalizeBuilderData,
      normalizeApiData,
    },
    validationSchema: stationsSchema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
