/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Divider, Empty, Spin } from 'antd';
import { useTranslation } from '@features/localization';
import { getNotificationMessages, showNotification } from '@lib';
import { getErrorNotificationMessage, validate } from '@features/errors';
import { normalizeUpdateApiData, normalizeUpdateData } from '@features/transshipment/lib';
import { transshipmentUpdateSchema } from '@features/transshipment/schemas';
import { ApiService } from '@services';
import { NOTIFICATION_TYPES, PAGE_KEYS } from '@constants';
import { WagonCard } from '../../WagonCard';
import { UpdateFields } from '.';

export function Update({ defaultData, mode, pageKey, refreshData, api, handleCancel }) {
  const { t } = useTranslation();

  const [data, setData] = useState({});
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);

  const handleFetchData = useCallback(async () => {
    try {
      setIsLoading(true);

      const wagonId = defaultData.id;

      const newData = await ApiService[PAGE_KEYS.TRANSSHIPMENT].readById({ id: wagonId });

      const normalizedData = normalizeUpdateData(newData);

      setErrors([]);
      setData(normalizedData);
    } catch (error) {
      console.warn(error);

      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('notifications:title.error'),
        description: t('notifications:messages.READ.error'),
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    if (refreshModal) {
      handleFetchData();
      setRefreshModal(false);
    }
  }, [refreshModal]);

  const handleSubmit = useCallback(
    async (id, createNewMail) => {
      const notificationMode = mode === 'COPY' ? 'CREATE' : mode;

      try {
        setIsLoading(true);

        const rowData = data.modalData.find(({ fieldId }) => fieldId === id);

        const validationErrors = validate(transshipmentUpdateSchema, rowData);

        if (validationErrors) {
          setErrors([{ ...validationErrors, fieldId: id }]);

          return;
        }

        const normalizedData = normalizeUpdateApiData(rowData);

        await api({ ...normalizedData, createNewMail });

        showNotification(getNotificationMessages(pageKey)[notificationMode].success);

        setData({});
        refreshData();
        setRefreshModal(true);
      } catch (error) {
        console.warn(error);

        if (error.errorFields) {
          return;
        }
        if (error.serverValidation) {
          setErrors([{ ...error.serverValidation, fieldId: id }]);

          return;
        }

        const message = getErrorNotificationMessage(error.message);

        showNotification(
          message ? message : getNotificationMessages(pageKey)[notificationMode].error
        );
      } finally {
        setIsLoading(false);
      }
    },
    [data]
  );

  const handleDelete = useCallback(
    async (id) => {
      const notificationMode = 'DELETE';

      try {
        setIsLoading(true);

        const rowId = data.modalData.find(({ fieldId }) => fieldId === id).id;

        await ApiService[PAGE_KEYS.TRANSSHIPMENT].delete({ id: rowId });

        showNotification(getNotificationMessages(pageKey)[notificationMode].success);

        setData({});
        refreshData();
        setRefreshModal(true);
      } catch (error) {
        console.warn(error);

        if (error.errorFields) {
          return;
        }
        if (error.serverValidation) {
          setErrors(error.serverValidation);

          return;
        }

        const message = getErrorNotificationMessage(error.message);

        showNotification(
          message ? message : getNotificationMessages(pageKey)[notificationMode].error
        );
      } finally {
        setIsLoading(false);
      }
    },
    [data]
  );

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '39rem',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin />
        </div>
      ) : !data?.modalData?.length ? (
        <div
          style={{
            height: '39rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Empty description={t(`${pageKey}.fields.noTransshipments`)} />
        </div>
      ) : (
        <>
          <WagonCard data={data?.wagonData} />
          <UpdateFields
            data={data?.modalData}
            errors={errors}
            setErrors={setErrors}
            setData={setData}
            pageKey={pageKey}
            handleSubmit={handleSubmit}
            handleDelete={handleDelete}
            isLoading={isLoading}
          />
        </>
      )}
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
        <Button disabled={isLoading} onClick={handleCancel}>
          {t(`modal.cancel`)}
        </Button>
      </div>
    </>
  );
}

Update.propTypes = {
  mode: PropTypes.oneOf(['CREATE', 'UPDATE', 'COPY', 'DELETE']).isRequired,
  defaultData: PropTypes.object,
  pageKey: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  api: PropTypes.func.isRequired,
};

Update.defaultProps = {
  defaultData: undefined,
};
