import { Base } from '.';

export class Countries extends Base {
  read = async (params) => {
    const result = await this.apiClient.get('/countries', { query: { ...params } });

    return result;
  };

  readById = async ({ id }) => {
    const result = await this.apiClient.get(`/countries/${id}`);

    return result;
  };

  create = async (data) => {
    await this.apiClient.post('/countries', { body: { ...data } });
  };

  update = async (data) => {
    await this.apiClient.put('/countries', { body: { ...data } });
  };

  delete = async ({ id }) => {
    await this.apiClient.delete(`/countries/${id}`);
  };
}
