export const theme = {
  token: {
    colorPrimary: '#1a4683',
    colorInfo: '#2056a2',
  },

  components: {
    Table: {
      padding: 4,
      paddingContentVerticalLG: 4,
      borderRadiusLG: 0,
    },
    Input: {
      colorBorder: '#1a4683',
      colorPrimaryHover: '#3F88C5',
      paddingSM: 5,
    },
    InputNumber: {
      colorBorder: '#1a4683',
      colorPrimaryHover: '#3F88C5',
      paddingSM: 5,
    },
    Select: {
      colorBorder: '#1a4683',
      colorPrimaryHover: '#3F88C5',
      paddingSM: 5,
    },
    DatePicker: {
      colorBorder: '#1a4683',
      colorPrimaryHover: '#3F88C5',
      paddingSM: 5,
    },
    Modal: {
      colorBgElevated: '#d0d0d0',
    },
    Divider: {
      colorSplit: '#1a4683',
      marginLG: 12,
    },
    Collapse: {
      colorBgContainer: '#d0d0d0',
      colorBorder: '#1a4683',
      colorFillAlter: 'white',
    },
    Tabs: {
      margin: 8,
    },
    Button: {
      colorBgContainerDisabled: '#b2b2b2',
      colorTextDisabled: '#757575',
    },
  },
};
