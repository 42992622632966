export const getPermissionsValues = (permissionsList) => {
  const permissionsValues = Object.keys(permissionsList)
    .filter((key) => !permissionsList[key].includes('none'))
    .flatMap((key) => {
      if (key === 'adminPermissions') {
        return ['admin'];
      }

      const permission = permissionsList[key];

      const permissionRead = `${key}_read`;
      const permissionWrite = `${key}_write`;

      if (permission.includes('full')) {
        return [permissionRead, permissionWrite];
      }

      return [permissionRead];
    });

  return permissionsValues;
};
