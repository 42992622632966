import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ButtonGroup } from '@components';

export function Boolean({ record, columnProps, id }) {
  const value = record[id];

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {value ? <CheckOutlined /> : <CloseOutlined />}
      {columnProps?.buttons && value && (
        <ButtonGroup buttons={columnProps?.buttons} record={record} />
      )}
    </div>
  );
}

Boolean.propTypes = {
  record: PropTypes.object,
  columnProps: PropTypes.object,
  id: PropTypes.string,
};

Boolean.defaultProps = {
  record: {},
  columnProps: {},
  id: undefined,
};
