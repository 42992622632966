/* eslint-disable no-param-reassign */
import i18next from 'i18next';
import { notification } from 'antd';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiService } from '@services';
import { LOADING_STATES } from '@store/constants';
import { showNotification } from '@lib';
import { getErrorNotificationMessage } from '@features/errors';

const initialState = {
  userData: null,
  token: null,
  loadingState: LOADING_STATES.IDLE,
};

export const login = createAsyncThunk('user/login', async (payload, { dispatch }) => {
  try {
    const token = await ApiService.user.login(payload);

    dispatch(userActions.setToken(token));

    const user = await ApiService.user.getCurrentUser();

    notification.success({ message: `${i18next.t(`login.messages.success`)}` });

    return user;
  } catch (error) {
    const message = getErrorNotificationMessage(error.message);

    showNotification(message);
    throw new Error(error);
  }
});

export const getCurrentUser = createAsyncThunk('user/getCurrentUser', async () => {
  try {
    const user = await ApiService.user.getCurrentUser();

    return user;
  } catch (error) {
    notification.error({ message: `${i18next.t(`error.userData`)}` });
    throw new Error(error);
  }
});

export const refreshToken = createAsyncThunk(
  'user/refreshToken',
  async (_payload, { dispatch }) => {
    try {
      const token = await ApiService.user.refreshToken();

      dispatch(userActions.setToken(token));

      const user = await ApiService.user.getCurrentUser();

      return user;
    } catch (error) {
      notification.error({ message: `${i18next.t(`error.token`)}` });
      throw new Error(error);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userData = action.payload;
    },
    unSetUser: (state) => {
      state.userData = null;
    },
    setUserLoadingState: (state, action) => {
      state.loadingState = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    unSetToken: (state) => {
      state.token = null;
    },
    logout: (state) => {
      state.userData = null;
      state.token = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(login.fulfilled.type, (state, action) => {
        state.userData = action.payload;
        state.loadingState = LOADING_STATES.LOADED;
      })
      .addCase(login.pending.type, (state) => {
        state.loadingState = LOADING_STATES.LOADING;
      })
      .addCase(login.rejected.type, (state) => {
        state.loadingState = LOADING_STATES.ERROR;
      })
      .addCase(getCurrentUser.fulfilled.type, (state, action) => {
        state.userData = action.payload;
        state.loadingState = LOADING_STATES.LOADED;
      })
      .addCase(getCurrentUser.pending.type, (state) => {
        state.loadingState = LOADING_STATES.LOADING;
      })
      .addCase(getCurrentUser.rejected.type, (state) => {
        state.loadingState = LOADING_STATES.ERROR;
      })
      .addCase(refreshToken.fulfilled.type, (state, action) => {
        state.userData = action.payload;
        state.loadingState = LOADING_STATES.LOADED;
      })
      .addCase(refreshToken.pending.type, (state) => {
        state.loadingState = LOADING_STATES.LOADING;
      })
      .addCase(refreshToken.rejected.type, (state) => {
        state.loadingState = LOADING_STATES.ERROR;
        state.token = null;
        state.userData = null;
      }),
});

export const userSelectors = {
  user: (state) => state.user,
  userData: (state) => userSelectors.user(state).userData,
  token: (state) => userSelectors.user(state).token,
  loadingState: (state) => userSelectors.user(state).loadingState,
};

const { actions: userActions, reducer: userReducer } = userSlice;

export { userActions };

export default userReducer;
