import i18n from '@i18n';
import { PAGE_KEYS } from '@constants';
import { checkPermission, permissionsSchema } from '@features/permissions';
import { MENU_ICONS_BY_ROUTE } from '../constants';

export const getMenuItem = (routes, routeKey, permissions) => {
  const route = routes[routeKey];

  if (
    !route.protected ||
    (route?.restricted && !checkPermission(permissions, permissionsSchema[routeKey.toLowerCase()]))
  ) {
    return null;
  }

  const transKey = PAGE_KEYS[routeKey];
  const path = routes[routeKey].path;
  const children = routes[routeKey].children;

  return {
    key: path,
    label: i18n.t(`layout.menu.${transKey}`),
    icon: MENU_ICONS_BY_ROUTE[path],
    children:
      children &&
      Object.keys(children).map((childrenRouteKey) =>
        getMenuItem(children, childrenRouteKey, permissions)
      ),
  };
};
