/* eslint-disable no-magic-numbers */
import * as yup from 'yup';
import { errorTexts } from '@features/errors';

export const usersSchema = yup.object().shape({
  login: yup.string().required(errorTexts.required()),
  password: yup.string().required(errorTexts.required()).min(8, errorTexts.minLength(8)),
  fullname: yup.string(),
  firm: yup.string(),
  shopIds: yup.array().required(errorTexts.required()),
  roleId: yup
    .object()
    .shape({
      value: yup.number().required(errorTexts.required()),
      label: yup.string().required(errorTexts.required()),
    })
    .required(errorTexts.required()),
});
