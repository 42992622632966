/* eslint-disable no-magic-numbers */
import * as yup from 'yup';
import { errorTexts } from '@features/errors';
import { wagonsSchema } from './wagonsSchema';

export const complexWagonsSchema = wagonsSchema.concat(
  yup.object().shape({
    damagePrice: yup.number(errorTexts.formatNumber()).nullable(),
    insuranceDate: yup.object().nullable(),
    damageDate: yup.object().nullable(),
  })
);
