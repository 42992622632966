import { AutoCompleteInput } from './AutoCompleteInput';
import { Input } from './Input';
import { MultipleInput } from './MultipleInput';
import { Date } from './Date';
import { Select } from './Select';
import { Upload } from './Upload';
import { Checkbox } from './Checkbox';
import { OptimizedInput } from './OptimizedInput';

export const Inputs = {
  Input,
  MultipleInput,
  AutoCompleteInput,
  Date,
  Select,
  Upload,
  Checkbox,
  OptimizedInput,
};
