import { allPermissionsList } from '../constants';

export const setPermissionsValues = (permissionsList) => {
  if (!permissionsList || !permissionsList.length) {
    return [];
  }
  const permissionsValues = Object.fromEntries(
    Object.entries(allPermissionsList).map(([permission, data]) => {
      const { key } = data;

      let option = 'none';

      if (permissionsList.includes(`${key}_read`)) {
        option = 'readonly';
      }
      if (permissionsList.includes(`${key}_write`) || permissionsList.includes(key)) {
        option = 'full';
      }

      return [permission, option];
    })
  );

  return permissionsValues;
};
