/* eslint-disable max-lines-per-function */
/* eslint-disable react/no-multi-comp */
import { useMemo } from 'react';
import { Divider } from 'antd';
import PropTypes from 'prop-types';

import { ItemLabel } from '@components';
import { Inputs, QUERY_FORMATTERS } from '@features/builder';
import { useTranslation } from '@features/localization';
import { ApiService } from '@services';
import { PAGE_KEYS } from '@constants';

export function UpdateFields({ pageKey, data, errors, handleChange }) {
  const { t } = useTranslation();

  const gridStyle = {
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'repeat(auto-fit, minmax(10rem, 1fr))',
    gap: '1rem',
    marginBottom: '1rem',
  };

  const commonProps = useMemo(
    () => ({ data, errors, onChange: handleChange }),
    [data, errors, handleChange]
  );

  return (
    <>
      <ItemLabel id='note' label={t(`${pageKey}.fields.note`)}>
        <Inputs.Input
          id={'note'}
          placeholder={t(`${pageKey}.fields.note`)}
          fieldProps={{ type: 'textarea' }}
          {...commonProps}
        />
      </ItemLabel>
      <Divider />
      <div style={gridStyle}>
        <ItemLabel id='invoiceCustomer' label={t(`${pageKey}.fields.invoiceCustomer`)}>
          <Inputs.Input
            id={'invoiceCustomer'}
            placeholder={t(`${pageKey}.fields.invoiceCustomer`)}
            {...commonProps}
          />
        </ItemLabel>
        <ItemLabel id='invoiceNumber' label={t(`${pageKey}.fields.invoiceNumber`)}>
          <Inputs.Input
            id={'invoiceNumber'}
            placeholder={t(`${pageKey}.fields.invoiceNumber`)}
            {...commonProps}
          />
        </ItemLabel>
        <ItemLabel id='wagontype' label={t(`${pageKey}.fields.wagontype`)}>
          <Inputs.Select
            id={'wagontype'}
            placeholder={t(`${pageKey}.fields.wagontype`)}
            fieldProps={{
              api: ApiService[PAGE_KEYS.WAGONTYPE].read,
            }}
            {...commonProps}
          />
        </ItemLabel>
        <ItemLabel id='insuranceDate' label={t(`${pageKey}.fields.insuranceDate`)}>
          <Inputs.Date
            id={'insuranceDate'}
            placeholder={t(`${pageKey}.fields.insuranceDate`)}
            fieldProps={{ formatter: QUERY_FORMATTERS.date }}
            {...commonProps}
          />
        </ItemLabel>
      </div>
      <ItemLabel id='insuranceNote' label={t(`${pageKey}.fields.insuranceNote`)}>
        <Inputs.Input
          id={'insuranceNote'}
          placeholder={t(`${pageKey}.fields.insuranceNote`)}
          fieldProps={{ type: 'textarea' }}
          {...commonProps}
        />
      </ItemLabel>
      <Divider />
      <div style={gridStyle}>
        <ItemLabel id='damageCurrency' label={t(`${pageKey}.fields.damageCurrency`)}>
          <Inputs.Select
            id={'damageCurrency'}
            placeholder={t(`${pageKey}.fields.damageCurrency`)}
            fieldProps={{
              api: ApiService[PAGE_KEYS.CURRENCY].read,
              selectApiRecord: (record) => ({ value: record.short, label: record.name }),
            }}
            {...commonProps}
          />
        </ItemLabel>
        <ItemLabel id='damageDate' label={t(`${pageKey}.fields.damageDate`)}>
          <Inputs.Date
            id={'damageDate'}
            placeholder={t(`${pageKey}.fields.damageDate`)}
            fieldProps={{ formatter: QUERY_FORMATTERS.date, style: { width: '15rem' } }}
            {...commonProps}
          />
        </ItemLabel>
        <ItemLabel id='damagePrice' label={t(`${pageKey}.fields.damagePrice`)}>
          <Inputs.Input
            id={'damagePrice'}
            placeholder={t(`${pageKey}.fields.damagePrice`)}
            fieldProps={{ type: 'number', style: { width: '15rem' } }}
            {...commonProps}
          />
        </ItemLabel>
      </div>
      <ItemLabel id='damageNote' label={t(`${pageKey}.fields.damageNote`)}>
        <Inputs.Input
          id={'damageNote'}
          placeholder={t(`${pageKey}.fields.damageNote`)}
          fieldProps={{ type: 'textarea' }}
          {...commonProps}
        />
      </ItemLabel>
    </>
  );
}

UpdateFields.propTypes = {
  pageKey: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  data: PropTypes.object,
  errors: PropTypes.object,
};

UpdateFields.defaultProps = {
  data: undefined,
  errors: undefined,
};
