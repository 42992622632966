import { Base } from '.';

export class Configuration extends Base {
  read = async () => {
    const result = await this.apiClient.get('/parameters');

    return result;
  };

  update = async (data) => {
    await this.apiClient.post('/parameters', { body: data });
  };

  readSchedule = async () => {
    const result = await this.apiClient.get('/parameters/schedule');

    return result;
  };

  updateSchedules = async (data) => {
    await this.apiClient.post('/parameters/schedule', { body: data });
  };
}
