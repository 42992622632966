/* eslint-disable more/no-numeric-endings-for-variables */
import { getProperDate, getProperDateWithTime } from '@lib';

export function normalizeApiData(data) {
  const tracking1520 = data.map((record) => ({
    reloadWagonNumber: record.reloadWagonNumber,
    trackingStation: record.trackingStation,
    trackingNumber: record.trackingNumber,
    trackingDate: record.trackingDate && getProperDateWithTime(record.trackingDate),
    wagonStationDestionation: record.wagonStationDestionation,
    arrivalDate: record.arrivalDate && getProperDate(record.arrivalDate),
    inclusionDate: record.arrivalDate && getProperDate(record.arrivalDate),
    wagonStationFrom: record.wagonStationFrom,
    wagonStationTo: record.wagonStationTo,
    wagonStationBorder: record.wagonStationBorder,
    wagonGoods: record.wagonGoods,
    recordDate: record.recordDate && getProperDateWithTime(record.recordDate),
    eta: record.eta >= 0 ? record.eta : null,
  }));

  return { tracking1520 };
}
