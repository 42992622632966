/* eslint-disable func-names */

import * as yup from 'yup';
import { errorTexts } from '@features/errors';

yup.addMethod(yup.array, 'unique', function (message, path) {
  return this.test('unique', message, function (list) {
    const mapper = (x) => path.split('.').reduce((acc, val) => acc && acc[val], x);
    const seen = new Set();
    const duplicates = new Set();

    list.forEach((item) => {
      const val = mapper(item);

      if (seen.has(val)) {
        duplicates.add(val);
      } else {
        seen.add(val);
      }
    });

    if (!duplicates.size) {
      return true;
    }

    const errors = [];

    list.forEach((item, index) => {
      const val = mapper(item);

      const firstOccurrence = list.findIndex(({ position }) => position === val) === index;

      if (duplicates.has(val) && val !== '' && !firstOccurrence) {
        const error = this.createError({
          path: `[${index}].${path}`,
          message: message || 'This field must be unique',
        });

        errors.push(error);
      }
    });

    if (errors.length) {
      throw new yup.ValidationError(errors);
    }

    return true;
  });
});

export const wagonsImportSchema = yup
  .array()
  .of(
    yup.object().shape({
      fieldId: yup.number().required(),
      shop: yup
        .object()
        .shape({
          value: yup.number().required(errorTexts.required()),
          label: yup.string(),
        })
        .required(errorTexts.required()),
      order: yup
        .object()
        .shape({
          value: yup.number().required(errorTexts.required()),
          label: yup.string(),
        })
        .required(errorTexts.required()),
      number: yup.string().required(errorTexts.required()),
      sendDate: yup.object().required(errorTexts.required()),
      weight: yup.number(errorTexts.formatNumber()).nullable(),
      quantity: yup.number(errorTexts.formatNumber()).nullable(),
      orderNumber: yup.string().nullable(),
      cim: yup.string().nullable(),
    })
  )
  .unique(errorTexts.unique(), 'number');
