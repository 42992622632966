import i18n from '@i18n';

export const SELECT_BOOL_OPTIONS = [
  {
    value: true,
    label: i18n.t(`modal.yes`),
  },
  {
    value: false,
    label: i18n.t(`modal.no`),
  },
];
