import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from '@features/localization';
import { ButtonGroup } from '@components';
import { showNotification } from '@lib';
import { userSelectors } from '@features/user';
import { NOTIFICATION_TYPES } from '@constants';

export function MediaViewer({ text, record, columnProps }) {
  const { t } = useTranslation();
  const { id } = record;
  const userToken = useSelector(userSelectors.token);
  const [fileSrc, setFileSrc] = useState(null);

  const infoModalView = () => {
    Modal.info({
      title: 'File',
      content: (
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            display: 'flex',
          }}
        >
          {fileSrc && (
            <img
              style={{ width: '80%', height: '80%', margin: '2rem' }}
              src={fileSrc}
              alt={'File preview'}
            />
          )}
        </div>
      ),
      centered: true,
      maskClosable: true,
    });
  };

  const renderLink = () => {
    if (columnProps.link) {
      return (
        // eslint-disable-next-line react/jsx-no-bind
        <a onClick={infoModalView}>{text}</a>
      );
    }
  };

  async function fetchFile() {
    try {
      // eslint-disable-next-line no-undef
      const response = await fetch(`${APP_CONFIG.apiUrl}/file/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      const arrayBuffer = await response.arrayBuffer();
      const blob = new Blob([arrayBuffer]);
      const objectURL = URL.createObjectURL(blob);

      setFileSrc(objectURL);
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('notifications:title.error'),
        description: t('notifications:messages.media_library.DOWNLOAD.error'),
      });
    }
  }

  useEffect(() => {
    fetchFile();
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {renderLink()}
      {columnProps?.buttons && text && (
        <ButtonGroup buttons={columnProps.buttons} record={record} />
      )}
    </div>
  );
}

MediaViewer.propTypes = {
  text: PropTypes.string,
  record: PropTypes.object.isRequired,
  columnProps: PropTypes.object,
};

MediaViewer.defaultProps = {
  text: null,
  columnProps: {},
};
