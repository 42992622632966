/* eslint-disable react/no-array-index-key */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-nested-ternary */
import { Button, Divider, Modal, Typography, theme } from 'antd';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { useParams } from 'react-router';
import { useTranslation } from '@features/localization';
import { ItemLabel } from '@components';
import { getNotificationMessages, showNotification } from '@lib';
import { getErrorNotificationMessage, validate } from '@features/errors';
import { getBuilderServerErrors } from '@features/builder/lib';

const { useToken } = theme;

// eslint-disable-next-line max-lines-per-function
export function BuilderModal({
  isModalOpen,
  mode,
  data,
  setData,
  errors,
  setErrors,
  handleCloseModal,
  handleChange,
  handleChangeError,
  schema: { api, fields, heightModal },
  modalButtons,
  pageKey,
  refreshData,
  normalizer,
  validationSchema,
  query,
}) {
  const params = useParams();
  const { t } = useTranslation();
  const { token } = useToken();

  const [isModalLoading, setIsModalLoading] = useState(false);

  const modalWidth = useMemo(() => {
    if (!fields?.length) return 416; // default value by antd
    if (fields.length > 15) return 905;
    if (fields.length > 10) return 700;
    if (fields.length > 7) return 600;

    return 416;
  }, [fields]);

  const handleCancel = useCallback(() => {
    handleCloseModal();
  }, []);

  const handleSubmit = useCallback(async () => {
    const notificationMode = mode === 'COPY' ? 'CREATE' : mode;

    try {
      setIsModalLoading(true);

      if (validationSchema && mode !== 'DELETE') {
        const validationErrors = validate(validationSchema, data);

        if (validationErrors) {
          setErrors(validationErrors);

          return;
        }
      }

      const normalizedData = normalizer ? normalizer(data, mode) : data;

      await api(normalizedData, params);

      sessionStorage.setItem('trackingDateSession', normalizedData.trackingDate);

      showNotification(getNotificationMessages(pageKey)[notificationMode].success);

      setData({});
      refreshData();
      handleCloseModal();
    } catch (error) {
      console.warn(error);

      if (error.errorFields) {
        return;
      }
      if (error.serverValidation) {
        setErrors(getBuilderServerErrors(error.serverValidation));

        return;
      }

      const message = getErrorNotificationMessage(error.message);

      showNotification(
        message ? message : getNotificationMessages(pageKey)[notificationMode].error
      );
    } finally {
      setIsModalLoading(false);
    }
  }, [data, params]);

  return (
    <Modal
      confirmLoading={isModalLoading}
      destroyOnClose
      title={t(
        `${pageKey}.modal.title.${mode === 'ADD' ? 'CREATE'.toLowerCase() : mode.toLowerCase()}`
      )}
      onCancel={handleCancel}
      open={isModalOpen}
      width={modalWidth}
      footer={[
        <Button key='ok' onClick={handleSubmit} danger={mode === 'DELETE'} type='primary'>
          {t(
            `modal.${
              mode === 'CREATE' || mode === 'COPY' || mode === 'ADD'
                ? 'add'
                : mode === 'UPDATE'
                ? 'save'
                : 'delete'
            }`
          )}
        </Button>,
        modalButtons?.map(({ component: Component, key, value, modes: buttonModes }) => {
          const buttonText = t(`modal.${key}`);

          return (
            buttonModes.includes(mode) && (
              <Component
                key={key}
                name={key}
                text={buttonText}
                value={value}
                data={data}
                setData={handleChange}
                handleSubmit={handleSubmit}
              />
            )
          );
        }),
        <Button key='cancel' onClick={handleCancel}>
          {t(`modal.cancel`)}
        </Button>,
      ]}
      centered
    >
      <Divider style={{ margin: '1rem 0' }} />
      {mode === 'DELETE' ? (
        <Typography.Title level={4}>{t(`${pageKey}.modal.deleteText`)}</Typography.Title>
      ) : (
        <form
          autoComplete='off'
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            gap: '0.5rem',
            maxHeight: heightModal ? heightModal : '37rem',
            overflowY: 'auto',
            marginRight: '-1rem',
            paddingRight: '1rem',
          }}
        >
          {fields.map(({ id, required, fieldProps, link, component: Component, style }) => {
            const labelText = t(`${pageKey}.fields.${id}`);

            const label = link ? <Link {...link}>{labelText}</Link> : labelText;

            return (
              <div key={id} style={{ display: 'inline-block' }}>
                <ItemLabel
                  key={id}
                  label={label}
                  required={required}
                  style={{ ...style, color: token.colorPrimary }}
                >
                  {Component && (
                    <Component
                      id={id}
                      required={required}
                      placeholder={labelText}
                      data={data}
                      value={data[id]}
                      errors={errors}
                      fieldProps={fieldProps}
                      onChange={handleChange}
                      handleChangeError={handleChangeError}
                      pageKey={pageKey}
                      query={query}
                    />
                  )}
                </ItemLabel>
              </div>
            );
          })}
        </form>
      )}
      <Divider style={{ margin: '1rem 0' }} />
    </Modal>
  );
}

BuilderModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeError: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['CREATE', 'UPDATE', 'COPY', 'DELETE', 'ADD']).isRequired,
  data: PropTypes.object,
  reserveData: PropTypes.object,
  errors: PropTypes.object,
  pageKey: PropTypes.string.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  refreshData: PropTypes.func.isRequired,
  schema: PropTypes.shape({
    api: PropTypes.func,
    fields: PropTypes.array,
    heightModal: PropTypes.string,
  }).isRequired,
  modalButtons: PropTypes.array,
  normalizer: PropTypes.func,
  validationSchema: PropTypes.object,
  query: PropTypes.object,
};

BuilderModal.defaultProps = {
  data: undefined,
  reserveData: undefined,
  errors: undefined,
  normalizer: undefined,
  validationSchema: undefined,
  query: undefined,
  modalButtons: undefined,
};
