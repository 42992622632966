import React, { useState, useLayoutEffect } from 'react';
import { Router } from 'react-router-dom';
import PropTypes from 'prop-types';

export function CustomRouter({ history, ...props }) {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
}

CustomRouter.propTypes = {
  history: PropTypes.object.isRequired,
};
