/* eslint-disable no-magic-numbers */
export function normalizeNotificationsData(data) {
  const sorterIndex = (type) => {
    switch (type) {
      case 'Odosielateľ':
        return 1;
      case 'Prijímateľ':
        return 2;
      case 'Prekladateľ':
        return 3;
      default:
        return 4;
    }
  };

  const normalizedData = data
    .map((entry) => ({
      id: entry.id,
      sorterIndex: sorterIndex(entry.position),
      firm: entry?.organization?.id && {
        value: entry?.organization?.id,
        label: entry?.organization?.name,
      },
      position: entry.position === 'null' ? '' : entry.position,
      emails: entry.emails.map((email, fieldId) => ({ fieldId, email })),
      notification: Object.keys(entry)
        .map((key) => entry[key] === true && key)
        .filter((value) => value),
    }))
    .sort((a, b) => a.sorterIndex - b.sorterIndex)
    .map((entry, fieldId) => ({ ...entry, fieldId }));

  return normalizedData;
}
