import dayjs from 'dayjs';
import { PAGE_KEYS } from '@constants';
import { ApiService } from '@services';

export function normalizeBuilderData(data) {
  const { records, count, page, total } = data;
  const meta = { count, page, total };

  const tableData = records.map((record) => ({
    ...record,
    sendDate: record?.sendDate && dayjs(record.sendDate).format('DD.MM.YYYY'),
    shop: record?.shop?.name,
    order: record?.order?.name,
    wagontype: record?.wagontype?.name,
  }));

  const modalData = records.map((record) => ({
    ...record,
    hidden: !record.hidden,
    sendDate: record?.sendDate && dayjs(record.sendDate),
    insuranceDate: record?.insuranceDate && dayjs(record.insuranceDate),
    damageDate: record?.damageDate && dayjs(record.damageDate),
    shop: record?.shop?.id ? { value: record?.shop?.id, label: record?.shop?.name } : null,
    order: record?.order?.id ? { value: record?.order?.id, label: record?.order?.name } : null,
    wagontype: record?.wagonType?.id
      ? { value: record?.wagonType?.id, label: record?.wagonType?.name }
      : null,
    currency: record?.currency
      ? { api: ApiService[PAGE_KEYS.CURRENCY].readById, id: record?.currency }
      : null,
    damageCurrency: record?.damageCurrency
      ? { api: ApiService[PAGE_KEYS.CURRENCY].readById, id: record?.damageCurrency }
      : null,
    orderInfo: record?.order,
  }));

  return { tableData, modalData, meta };
}
