/* eslint-disable func-names */
import * as yup from 'yup';
import { errorTexts } from '@features/errors';

yup.addMethod(yup.array, 'unique', function (message, paths) {
  return this.test('unique', message, function (list) {
    const mapper = (x) =>
      paths.map((path) => path.split('.').reduce((acc, val) => acc && acc[val], x)).join('_');
    const seen = new Map();

    list.forEach((item) => {
      const val = mapper(item);

      if (seen.has(val)) {
        seen.get(val).duplicate = true;
      } else {
        seen.set(val, { isStatistic: item.isStatistic, duplicate: false });
      }
    });

    const errors = [];

    list.forEach((item, index) => {
      const val = mapper(item);

      if (seen.get(val).duplicate && seen.get(val).isStatistic) {
        const error = this.createError({
          path: `[${index}].${paths.join('.')}`,
          message: message || 'These fields must be unique',
        });

        errors.push(error);
      }
    });

    if (errors.length && list.some((item) => item.isStatistic)) {
      throw new yup.ValidationError(errors);
    }

    return true;
  });
});

export const transshipmentImportSchema = yup
  .array()
  .of(
    yup.object().shape({
      number1435: yup.string().required(errorTexts.required()),
      number: yup.string().required(errorTexts.required()),
      smgsDate: yup.object().required(errorTexts.required()),
    })
  )
  .unique(errorTexts.unique(), ['number', 'isStatistic']);
