/* eslint-disable max-lines-per-function */
import { useCallback } from 'react';
import { NOTIFICATION_TYPES } from '@constants';
import { showNotification } from '@lib';
import { useTranslation } from '@features/localization';

export function useSelectHandlers({
  id,
  value,
  data,
  includeAllOption,
  allOption,
  rawOptions,
  options,
  api,
  normalizer,
  selectApiRecord,
  setIsLoading,
  setOptions,
  onChange,
  fieldKey,
  customSourceKey,
  customOptionKey,
  includeQuery,
  query,
}) {
  const { t } = useTranslation();

  const handleSearch = useCallback(
    async (startWith) => {
      if (!api) return;

      try {
        setIsLoading(true);

        let newOptions;

        if (customOptionKey) {
          if (!!!data?.[customSourceKey]) {
            return;
          }

          const fetchId = data[customSourceKey].hasOwnProperty('value')
            ? data[customSourceKey].value
            : data[customSourceKey];

          const response = await api({ id: fetchId });

          newOptions =
            response?.[customOptionKey]?.map((record) =>
              selectApiRecord ? selectApiRecord(record) : { value: record.id, label: record.name }
            ) || [];
        } else {
          const response = await api(includeQuery && query ? query : { startWith }, startWith);

          newOptions =
            (response?.records || response)?.map((record) =>
              selectApiRecord ? selectApiRecord(record) : { value: record.id, label: record.name }
            ) || [];
        }

        if (includeAllOption) newOptions.unshift(allOption);

        setOptions(newOptions);
      } catch (error) {
        showNotification({
          type: NOTIFICATION_TYPES.ERROR,
          message: t('notifications:title.error'),
          description: t(`errors:server.${error.message}`),
        });
      } finally {
        setIsLoading(false);
      }
    },
    [data, api]
  );

  const handleFilter = useCallback(
    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
    []
  );

  const handleChange = useCallback(
    (values, selectedValue, treeExtra) => {
      const normalizedValue = normalizer
        ? normalizer({
            values,
            selectedValue,
            data,
            options: rawOptions || options,
            id,
            treeExtra,
          })
        : selectedValue;

      onChange(id, normalizedValue, fieldKey);
    },
    [id, onChange, fieldKey, data, rawOptions, options, id]
  );

  const handleSelectClick = useCallback(() => {
    if (api) handleSearch('');
  }, [data, api]);

  const handleFetchById = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await value.api({ id: value.id });
      const newValue = selectApiRecord
        ? selectApiRecord(response)
        : { value: response.id, label: response.name };

      const newOptions = [newValue];

      if (includeAllOption) newOptions.unshift(allOption);

      const normalizedValue = normalizer ? newValue.value : newValue;

      onChange(id, normalizedValue);

      setOptions(newOptions);
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('notifications:title.error'),
        description: t(`errors:server.${error.message}`),
      });
    } finally {
      setIsLoading(false);
    }
  }, [value]);

  return {
    handleSearch,
    handleFilter,
    handleChange,
    handleSelectClick,
    handleFetchById,
  };
}
