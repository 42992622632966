import dayjs from 'dayjs';

export function parseXLSXData(rawData) {
  const tableData = rawData.map((item, index) => ({
    id: item.B + index,
    key: item.B + index,
    fieldId: index,
    reloadWagonNumber: item.B,
    trackingStation: item.C,
    trackingNumber: item.D,
    trackingDate: item.E && dayjs(item.E, 'DD.MM.YYYY HH:mm'),
    wagonStationDestionation: item.F,
    arrivalDate: item.G && dayjs(item.G, 'DD.MM.YYYY'),
    eta: Number(item.H),
    inclusionDate: item.I && dayjs(item.I, 'DD.MM.YYYY'),
    wagonStationFrom: item.J,
    wagonStationTo: item.K,
    wagonStationBorder: item.L,
    wagonGoods: item.M,
    recordDate: item.N && dayjs(item.N, 'DD.MM.YYYY HH:mm'),
  }));

  return { tableData };
}
