import dayjs from 'dayjs';
import { Base } from './Base';

export class TrackingLogs extends Base {
  read1435Logs = async (params) => {
    const query = {
      ...params,
      filterLastState: params?.filterLastState || false,
      filterSendDateTo: params?.filterSendDateTo || dayjs().format('YYYY-MM-DD'),
    };

    const result = await this.apiClient.get('/tracking/1435/find', { query: { ...query } });

    return result;
  };

  read1520Logs = async (params) => {
    const query = {
      ...params,
      filterLastState: params?.filterLastState || false,
      filterArrivalDateTo: params?.filterArrivalDateTo || dayjs().format('YYYY-MM-DD'),
    };

    const result = await this.apiClient.get('/tracking/1520/find', { query: { ...query } });

    return result;
  };
}
