import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';

dayjs.extend(utcPlugin);

export function getProperDateWithTime(rawDate) {
  const offsetDateTimeString = dayjs(rawDate).format('YYYY-MM-DD HH:mm');

  return offsetDateTimeString;
}
