/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Divider, theme } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from '@features/localization';
import { getNotificationMessages, showNotification } from '@lib';
import { ItemLabel } from '@components';
import { getErrorNotificationMessage, validate } from '@features/errors';
import { complexWagonsSchema, normalizeUpdateApiData } from '@features/wagons/';
import { ApiService } from '@services';
import { PAGE_KEYS } from '@constants';
import { Inputs } from './../../../../builder/components/Inputs/index';

const { useToken } = theme;

export function Update({
  defaultData,
  mode,
  fields,
  pageKey,
  refreshData,
  api,
  normalizer,
  handleCancel,
}) {
  const { t } = useTranslation();
  const { token } = useToken();

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const normaliedFormData = useMemo(() => {
    return Object.fromEntries(
      Object.entries(defaultData).map(([key, value]) => {
        const formatter = fields?.find((field) => key === field.id)?.formatter;

        return [key, formatter ? formatter(value) : value];
      })
    );
  }, [defaultData, fields]);

  useEffect(() => {
    setData(normaliedFormData);
  }, [normaliedFormData]);

  const handleChange = useCallback((name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback(
    async (createNewMail = false) => {
      const notificationMode = mode === 'COPY' ? 'CREATE' : mode;

      try {
        setIsLoading(true);

        const validationErrors = validate(complexWagonsSchema, data);

        if (validationErrors) {
          setErrors(validationErrors);

          return;
        }

        const normalizedData = mode === 'COPY' ? normalizer(data) : normalizeUpdateApiData(data);

        await api({ ...normalizedData, createNewMail });

        showNotification(getNotificationMessages(pageKey)[notificationMode].success);

        setData({});
        refreshData();
        handleCancel();
      } catch (error) {
        console.warn(error);

        if (error.errorFields) {
          return;
        }
        if (error.serverValidation) {
          setErrors(error.serverValidation);

          return;
        }

        const message = getErrorNotificationMessage(error.message);

        showNotification(
          message ? message : getNotificationMessages(pageKey)[notificationMode].error
        );
      } finally {
        setIsLoading(false);
      }
    },
    [data]
  );

  const commonProps = useMemo(
    () => ({ data, errors, onChange: handleChange, pageKey }),
    [data, errors, handleChange]
  );

  const priceIndex = fields.findIndex((field) => field.id === 'price');

  return (
    <>
      <form
        autoComplete='off'
        style={{
          height: '23.5rem',
          maxHeight: '23.5rem',
          overflowY: 'auto',
          marginRight: '-1rem',
          paddingRight: '1rem',
          display: 'flex',
          width: '100%',
        }}
      >
        <div
          style={{
            height: '54rem',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            gap: '0.3rem',
            width: '49.5%',
          }}
        >
          {fields.map(({ id, required, fieldProps, link, component: Component, style }, index) => {
            const labelText = t(`${pageKey}.fields.${id}`);

            const label = link ? <Link {...link}>{labelText}</Link> : labelText;

            if (index === priceIndex) {
              return (
                <>
                  <ItemLabel
                    id='invoiceCustomer'
                    label={t(`${pageKey}.fields.invoiceCustomer`)}
                    style={{ color: token.colorPrimary }}
                  >
                    <Inputs.Input
                      id={'invoiceCustomer'}
                      placeholder={t(`${pageKey}.fields.invoiceCustomer`)}
                      value={data.invoiceCustomer}
                      {...commonProps}
                    />
                  </ItemLabel>
                  <ItemLabel
                    id='invoiceNumber'
                    label={t(`${pageKey}.fields.invoiceNumber`)}
                    style={{ color: token.colorPrimary }}
                  >
                    <Inputs.Input
                      id={'invoiceNumber'}
                      placeholder={t(`${pageKey}.fields.invoiceNumber`)}
                      value={data.invoiceNumber}
                      {...commonProps}
                    />
                  </ItemLabel>
                  <ItemLabel
                    key={id}
                    label={label}
                    required={required}
                    style={{ ...style, color: token.colorPrimary }}
                  >
                    <Component
                      id={id}
                      required={required}
                      placeholder={labelText}
                      data={data}
                      value={data[id]}
                      errors={errors}
                      fieldProps={fieldProps}
                      onChange={handleChange}
                      pageKey={pageKey}
                      style={{ width: '100%' }}
                    />
                  </ItemLabel>
                </>
              );
            }

            return (
              <ItemLabel
                key={id}
                label={label}
                required={required}
                style={{ ...style, color: token.colorPrimary }}
              >
                <Component
                  id={id}
                  required={required}
                  placeholder={labelText}
                  data={data}
                  value={data[id]}
                  errors={errors}
                  fieldProps={fieldProps}
                  onChange={handleChange}
                  pageKey={pageKey}
                  style={{ width: '100%' }}
                />
              </ItemLabel>
            );
          })}
          <ItemLabel
            id='insuranceDate'
            label={t(`${pageKey}.fields.insuranceDate`)}
            style={{ color: token.colorPrimary }}
          >
            <Inputs.Date
              id={'insuranceDate'}
              placeholder={t(`${pageKey}.fields.insuranceDate`)}
              fieldProps={{ style: { width: '100%' } }}
              {...commonProps}
            />
          </ItemLabel>
          <ItemLabel
            id='insuranceNote'
            label={t(`${pageKey}.fields.insuranceNote`)}
            style={{ color: token.colorPrimary }}
          >
            <Inputs.Input
              id={'insuranceNote'}
              placeholder={t(`${pageKey}.fields.insuranceNote`)}
              fieldProps={{ type: 'textarea' }}
              value={data.insuranceNote}
              {...commonProps}
            />
          </ItemLabel>
          <ItemLabel
            id='damageCurrency'
            label={t(`${pageKey}.fields.damageCurrency`)}
            style={{ color: token.colorPrimary }}
          >
            <Inputs.Select
              id={'damageCurrency'}
              placeholder={t(`${pageKey}.fields.damageCurrency`)}
              fieldProps={{
                api: ApiService[PAGE_KEYS.CURRENCY].read,
                selectApiRecord: (record) => ({ value: record.short, label: record.name }),
              }}
              {...commonProps}
            />
          </ItemLabel>
          <ItemLabel
            id='damagePrice'
            label={t(`${pageKey}.fields.damagePrice`)}
            style={{ color: token.colorPrimary }}
          >
            <Inputs.Input
              id={'damagePrice'}
              placeholder={t(`${pageKey}.fields.damagePrice`)}
              fieldProps={{ type: 'number', style: { width: '100%' } }}
              value={data.damagePrice}
              {...commonProps}
            />
          </ItemLabel>
          <ItemLabel
            id='damageDate'
            label={t(`${pageKey}.fields.damageDate`)}
            style={{ color: token.colorPrimary }}
          >
            <Inputs.Date
              id={'damageDate'}
              placeholder={t(`${pageKey}.fields.damageDate`)}
              fieldProps={{ style: { width: '100%' } }}
              {...commonProps}
            />
          </ItemLabel>
          <ItemLabel
            id='damageNote'
            label={t(`${pageKey}.fields.damageNote`)}
            style={{ color: token.colorPrimary }}
          >
            <Inputs.Input
              id={'damageNote'}
              placeholder={t(`${pageKey}.fields.damageNote`)}
              fieldProps={{ type: 'textarea' }}
              value={data.damageNote}
              {...commonProps}
            />
          </ItemLabel>
        </div>
      </form>
      <Divider style={{ margin: '1rem 0' }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
        <Button disabled={isLoading} onClick={handleCancel}>
          {t(`modal.cancel`)}
        </Button>
        <Button loading={isLoading} type='primary' onClick={() => handleSubmit()}>
          {t(`modal.save`)}
        </Button>
        <Button loading={isLoading} type='dashed' onClick={() => handleSubmit(true)}>
          {t(`modal.createNewMail`)}
        </Button>
      </div>
    </>
  );
}

Update.propTypes = {
  mode: PropTypes.oneOf(['CREATE', 'UPDATE', 'COPY', 'DELETE']).isRequired,
  defaultData: PropTypes.object,
  fields: PropTypes.array.isRequired,
  pageKey: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  api: PropTypes.func.isRequired,
  normalizer: PropTypes.func,
};

Update.defaultProps = {
  defaultData: undefined,
  normalizer: undefined,
};
