import dayjs from 'dayjs';

export function getFieldsData(data) {
  const fieldsData = Object.fromEntries(
    data.map(({ id, value }) => {
      // eslint-disable-next-line no-magic-numbers
      if (id === 4) {
        return [String(id), dayjs(value)];
      }

      return [String(id), value];
    })
  );

  return fieldsData;
}
