import dayjs from 'dayjs';

export function normalizeBuilderData(data) {
  const tableData = data.map((record) => ({
    id: record?.id,
    status: record?.status,
    sendDate: record?.sendDate && dayjs(record.sendDate).format('DD.MM.YYYY, HH:mm'),
    emailId: record?.emailId,
    errorMessage: record?.errorMessage,
    number: record?.wagon?.number,
    wagonId: record?.wagon?.id,
    wagonSendDate: record?.wagon?.sendDate && dayjs(record?.wagon?.sendDate).format('DD.MM.YYYY'),
    typeId: record?.type?.id,
    titleSk: record?.type?.titleSk,
    titleEn: record?.type?.titleEn,
    emails: record?.emails.join(', '),
  }));

  return { tableData };
}
