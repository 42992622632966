/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines-per-function */

import { PAGE_KEYS } from '@constants';
import { Builder, Columns, Inputs } from '@features/builder';
import { ApiService } from '@services';
import { normalizeBuilderData, normalizeApiData, incontermsSchema } from '@features/incoterms';

export function IncontermsPage() {
  const pageKey = PAGE_KEYS.INCONTERMS;

  const modalFields = [
    {
      id: 'short',
      required: true,
      component: Inputs.Input,
    },
    {
      id: 'description',
      required: true,
      component: Inputs.Input,
    },
    {
      id: 'text',
      component: Inputs.Input,
    },
    {
      id: 'note',
      component: Inputs.Input,
      fieldProps: { type: 'textarea' },
    },
  ];

  const CREATE_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const UPDATE_MODAL = {
    api: ApiService[pageKey].update,
    fields: modalFields,
  };

  const COPY_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const DELETE_MODAL = {
    api: ApiService[pageKey].delete,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read,
    columns: [
      {
        id: 'short',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'description',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'text',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'note',
        width: 450,
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'UPDATE' }, { type: 'DELETE' }, { type: 'COPY' }],
        },
        component: Columns.Actions,
      },
    ],
  };

  const schema = {
    CREATE: CREATE_MODAL,
    READ: TABLE_SCHEMA,
    UPDATE: UPDATE_MODAL,
    COPY: COPY_MODAL,
    DELETE: DELETE_MODAL,
    overrides: {
      normalizeBuilderData,
      normalizeApiData,
    },
    validationSchema: incontermsSchema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
