/* eslint-disable security/detect-unsafe-regex */
/* eslint-disable no-magic-numbers */
import * as yup from 'yup';
import { errorTexts } from '@features/errors';
import { REGEXES_DICTIONARY } from '@constants';

export const organizationsSchema = yup.object().shape({
  short: yup.string().required(errorTexts.required()),
  phones: yup.array().of(
    yup.object().shape({
      fieldId: yup.number().required(),
      phone: yup.string().matches(REGEXES_DICTIONARY.phoneRegex, errorTexts.phone()),
    })
  ),
  mobilePhones: yup.array().of(
    yup.object().shape({
      fieldId: yup.number().required(),
      mobilePhone: yup.string().matches(REGEXES_DICTIONARY.phoneRegex, errorTexts.phone()),
    })
  ),
  name: yup.string().required(errorTexts.required()),
  emailMain: yup.string().nullable().email(errorTexts.email()),
  site: yup.string().nullable().matches(REGEXES_DICTIONARY.urlRegex, errorTexts.url()),
  emailsAvizo: yup.array().of(
    yup.object().shape({
      fieldId: yup.number().required(),
      emailAvizo: yup.string().nullable().email(errorTexts.email()),
    })
  ),
});
