import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { useTranslation } from '@features/localization';
import { BuilderModalButton } from '@features/builder';
import { Update } from './Update';

export function TransshipmentModal({
  defaultData,
  mode,
  schema: { api, fields },
  pageKey,
  isPageLoading,
  refreshData,
  normalizer,
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleCancel = useCallback(() => {
    handleCloseModal();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <div onClick={(e) => e.stopPropagation()}>
      <BuilderModalButton
        mode={mode}
        defaultData={defaultData}
        pageKey={pageKey}
        isPageLoading={isPageLoading}
        handleOpenModal={handleOpenModal}
      />
      <Modal
        destroyOnClose
        title={t(`${pageKey}.modal.title.${mode.toLowerCase()}`)}
        onCancel={handleCancel}
        footer={null}
        open={isModalOpen}
        width={1100}
        centered
      >
        <Update
          mode={mode}
          defaultData={defaultData}
          fields={fields}
          pageKey={pageKey}
          refreshData={refreshData}
          api={api}
          normalizer={normalizer}
          handleCancel={handleCancel}
        />
      </Modal>
    </div>
  );
}

TransshipmentModal.propTypes = {
  mode: PropTypes.oneOf(['CREATE', 'UPDATE', 'COPY', 'DELETE']).isRequired,
  defaultData: PropTypes.object,
  pageKey: PropTypes.string.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  refreshData: PropTypes.func.isRequired,
  schema: PropTypes.shape({
    api: PropTypes.func,
    fields: PropTypes.array,
  }).isRequired,
  normalizer: PropTypes.func,
};

TransshipmentModal.defaultProps = {
  defaultData: undefined,
  normalizer: undefined,
};
