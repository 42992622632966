import { DateService } from '@features/app';

export function normalizeBuilderData(data) {
  const { records, count, page, total } = data;
  const meta = { count, page, total };

  const tableData = records.map((record) => ({
    ...record,
    id: record.id,
    clientId: record.id,
    name: record.name,
    organization: record.organization?.short,
    createdAt: DateService.getFormatDate(record.createdAt),
    reload: record.reload,
    note: record.note,
    children: record.orders?.map((order) => ({
      id: `${record.id}-${order.id}`,
      orderId: order.id,
      clientId: record.id,
      sessionAbbreviation: order.name,
      stationFrom: order.stationFrom?.name,
      stationTo: order.stationTo?.name,
      nhm: order.nhm,
      goods: order.goods,
      orderStationFrom: order.orderStationFrom?.name,
      orderStationTo: order.orderStationTo?.name,
      sender: order.sender?.short,
      recipient: order.recipient?.short,
      reload: record.reload,
    })),
  }));

  const modalData = records.map((record) => ({
    ...record,
    id: record.id,
    clientId: record.id,
    name: record.name,
    organization: {
      value: record.organization?.id,
      label: record.organization?.name,
    },
    createdAt: DateService.getFormatDate(record.createdAt),
    reload: record.reload,
    note: record.note,
    hidden: !record.hidden,
    children: record.orders?.map((order) => ({
      id: `${record.id}-${order.id}`,
      orderId: order.id,
      clientId: record.id,
      sessionAbbreviation: order.name,
      stationFrom: order.stationFrom?.name,
      stationTo: order.stationTo?.name,
      nhm: order.nhm,
      goods: order.goods,
      orderStationFrom: order.orderStationFrom?.name,
      orderStationTo: order.orderStationTo?.name,
      sender: order.sender?.short,
      recipient: order.recipient?.short,
      reload: record.reload,
    })),
  }));

  return { tableData, modalData, meta };
}
