export function normalizeApiData(data) {
  return {
    id: data.id,
    name: data.name,
    orgId: data.organization?.value,
    note: data.note,
    hidden: data?.hidden?.hasOwnProperty('value') ? !data.hidden.value : !data.hidden,
    reload: data?.reload?.hasOwnProperty('value') ? data.reload.value : data.reload,
  };
}
