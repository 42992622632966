import { getPermissionsValues } from '@features/permissions';

export function normalizeBuilderData(data) {
  const { records, count, page, total } = data;
  const meta = { count, page, total };

  const tableData = records.map((role) => ({
    id: role.id,
    name: role.name,
    permissions: getPermissionsValues(role.permissions),
  }));

  const modalData = records.map((role) => ({
    id: role.id,
    name: role.name,
    permissions: getPermissionsValues(role.permissions),
  }));

  return { tableData, modalData, meta };
}
