/* eslint-disable max-lines-per-function */

import { Modal, Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import { useTranslation } from '@features/localization';
import { BuilderModalButton } from '@features/builder';
import { Fcr } from './Fcr';
import { Update } from './Update';
import { WagonCard } from './WagonCard';
import { Transshipment } from './Transshipment';
import { Tracking } from './Tracking';

export function ComplexModal({
  defaultData,
  mode,
  schema: { api, fields },
  pageKey,
  isPageLoading,
  refreshData,
  normalizer,
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleCancel = useCallback(() => {
    handleCloseModal();
  }, []);

  const tabs = useMemo(
    () => [
      {
        key: '1',
        label: t(`${pageKey}.modal.tabs.update`),
        children: (
          <Update
            mode={mode}
            defaultData={defaultData}
            fields={fields}
            pageKey={pageKey}
            refreshData={refreshData}
            api={api}
            normalizer={normalizer}
            handleCancel={handleCancel}
          />
        ),
      },
      {
        key: '2',
        label: t(`${pageKey}.modal.tabs.transshipment`),
        children: (
          <Transshipment
            wagonId={defaultData.id}
            refreshData={refreshData}
            handleCancel={handleCancel}
          />
        ),
      },
      {
        key: '3',
        label: t(`${pageKey}.modal.tabs.tracking`),
        children: (
          <Tracking
            wagonId={defaultData.id}
            refreshData={refreshData}
            handleCancel={handleCancel}
          />
        ),
      },
      {
        key: '4',
        label: t(`${pageKey}.modal.tabs.fcr`),
        children: (
          <Fcr
            defaultData={defaultData}
            pageKey={pageKey}
            refreshData={refreshData}
            handleCancel={handleCancel}
          />
        ),
      },
    ],
    [defaultData]
  );

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <div onClick={(e) => e.stopPropagation()}>
      <BuilderModalButton
        mode={mode}
        defaultData={defaultData}
        pageKey={pageKey}
        isPageLoading={isPageLoading}
        handleOpenModal={handleOpenModal}
      />
      <Modal
        destroyOnClose
        title={t(`${pageKey}.modal.title.${mode.toLowerCase()}`)}
        onCancel={handleCancel}
        footer={null}
        open={isModalOpen}
        width={1300}
        centered
      >
        <WagonCard data={defaultData} />
        {mode === 'UPDATE' ? (
          <Tabs size='small' defaultActiveKey='1' items={tabs} />
        ) : (
          tabs[0].children
        )}
      </Modal>
    </div>
  );
}

ComplexModal.propTypes = {
  mode: PropTypes.oneOf(['CREATE', 'UPDATE', 'COPY', 'DELETE']).isRequired,
  defaultData: PropTypes.object,
  pageKey: PropTypes.string.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  refreshData: PropTypes.func.isRequired,
  schema: PropTypes.shape({
    api: PropTypes.func,
    fields: PropTypes.array,
  }).isRequired,
  normalizer: PropTypes.func,
};

ComplexModal.defaultProps = {
  defaultData: undefined,
  normalizer: undefined,
};
