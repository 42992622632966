import { Base } from '.';

export class Orders extends Base {
  read = async (params) => {
    const result = await this.apiClient.get(`/shop/${params.id}`);

    return result;
  };

  readById = async ({ id }) => {
    const result = await this.apiClient.get(`/order/${id}`);

    return result;
  };

  readEmailsById = async (params) => {
    const result = await this.apiClient.get(`/order/emails/${params.id}`);

    return result;
  };

  createEmails = async (data) => {
    await this.apiClient.post('/order/emails', { body: data });
  };

  create = async (data, params) => {
    const bodyData = {
      ...data,
      shopId: Number(params.id),
    };

    await this.apiClient.post('/order', { body: bodyData });
  };

  update = async (data) => {
    await this.apiClient.put('/order', { body: { ...data } });
  };

  delete = async ({ id }) => {
    await this.apiClient.delete(`/order/${id}`);
  };
}
