import dayjs from 'dayjs';

export function normalize1520LogsBuilderData(data) {
  const { records, count, page, total } = data;
  const meta = { count, page, total };

  const tableData = records.map((record) => ({
    ...record,
    recordDate: record?.recordDate && dayjs.utc(record.recordDate).format('DD.MM.YYYY, HH:mm'),
    trackingDate:
      record?.trackingDate && dayjs.utc(record.trackingDate).format('DD.MM.YYYY, HH:mm'),
    inclusionDate: record?.inclusionDate && dayjs(record.inclusionDate).format('DD.MM.YYYY'),
    arrivalDate: record?.arrivalDate && dayjs(record.arrivalDate).format('DD.MM.YYYY'),
  }));

  return { tableData, meta };
}
