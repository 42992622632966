import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { appActions, appSelectors } from '@features/app';
import { AVAILABLE_LOCALES } from '@i18n';

export const LangDropdown = memo(() => {
  const dispatch = useDispatch();
  const language = useSelector(appSelectors.language);
  const { i18n } = useTranslation();

  const handleLanguageChange = useCallback(
    async ({ key: lang }) => {
      if (lang === language) return;

      dayjs.locale(lang);
      dispatch(appActions.setLanguage(lang));
      await i18n.changeLanguage(lang);
    },
    [language]
  );

  return (
    <Dropdown
      menu={{
        onClick: handleLanguageChange,
        selectedKeys: [language],
        items: AVAILABLE_LOCALES.map((lang) => ({
          key: lang,
          label: lang.toUpperCase(),
        })),
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.25rem',
          cursor: 'pointer',
          color: 'white',
        }}
      >
        <span style={{ fontWeight: 500, lineHeight: 1.5 }}>{language?.toUpperCase()}</span>
        <GlobalOutlined />
      </div>
    </Dropdown>
  );
});

LangDropdown.displayName = 'LangDropdown';
