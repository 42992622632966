/* eslint-disable max-lines-per-function */

import { PAGE_KEYS, SELECT_BOOL_OPTIONS, SELECT_HIDDEN_OPTIONS } from '@constants';
import { Builder, Columns, Inputs, INPUT_NORMALIZERS } from '@features/builder';
import { ROUTES } from '@features/navigation';
import { ApiService } from '@services';
import {
  normalizeBuilderData,
  normalizeApiData,
  organizationsSchema,
} from '@features/organizations';

export function OrganizationsPage() {
  const pageKey = PAGE_KEYS.ORGANIZATIONS;

  const modalFields = [
    {
      id: 'short',
      required: true,
      component: Inputs.Input,
    },
    {
      id: 'name',
      required: true,
      component: Inputs.Input,
    },
    {
      id: 'ico',
      component: Inputs.Input,
    },
    {
      id: 'dic',
      component: Inputs.Input,
    },
    {
      id: 'icDph',
      component: Inputs.Input,
    },
    {
      id: 'address',
      component: Inputs.Input,
    },
    {
      id: 'postcode',
      component: Inputs.Input,
    },
    {
      id: 'city',
      component: Inputs.Input,
    },
    {
      id: 'country',
      component: Inputs.Select,
      link: {
        to: ROUTES.SETTINGS.children.CATALOGS.children.COUNTRIES.path,
        target: '_blank',
      },
      fieldProps: {
        api: ApiService[PAGE_KEYS.COUNTRIES].read,
      },
    },
    {
      id: 'contactName',
      component: Inputs.Input,
    },
    {
      id: 'contactSurname',
      component: Inputs.Input,
    },
    {
      id: 'phones',
      component: Inputs.MultipleInput,
      fieldProps: { id: 'phone' },
    },
    {
      id: 'mobilePhones',
      component: Inputs.MultipleInput,
      fieldProps: { id: 'mobilePhone' },
    },
    {
      id: 'emailMain',
      component: Inputs.Input,
    },
    {
      id: 'site',
      component: Inputs.Input,
    },
    {
      id: 'emailsAvizo',
      component: Inputs.MultipleInput,
      fieldProps: { id: 'emailAvizo' },
    },
    {
      id: 'hidden',
      component: Inputs.Select,
      fieldProps: {
        options: SELECT_BOOL_OPTIONS,
        defaultValue: false,
      },
    },
  ];

  const CREATE_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const UPDATE_MODAL = {
    api: ApiService[pageKey].update,
    fields: modalFields,
  };

  const COPY_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const DELETE_MODAL = {
    api: ApiService[pageKey].delete,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read,
    columns: [
      {
        id: 'name',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'short',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'address',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'postcode',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'city',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'country',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'UPDATE' }, { type: 'DELETE' }, { type: 'COPY' }],
        },
        component: Columns.Actions,
      },
    ],
    searchPanel: {
      fields: [
        {
          id: 'hidden',
          component: Inputs.Select,
          fieldProps: {
            style: { width: '12rem' },
            normalizer: INPUT_NORMALIZERS.selectValues,
            options: SELECT_HIDDEN_OPTIONS,
            includeAllOption: true,
            defaultValue: '',
          },
        },
      ],
    },
  };

  const schema = {
    CREATE: CREATE_MODAL,
    READ: TABLE_SCHEMA,
    UPDATE: UPDATE_MODAL,
    COPY: COPY_MODAL,
    DELETE: DELETE_MODAL,
    overrides: { normalizeBuilderData, normalizeApiData },
    validationSchema: organizationsSchema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
