/* eslint-disable max-lines-per-function */
import { Button, Select } from 'antd';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@features/localization';
import { ItemLabel } from '@components';

export function BuilderSearchPanel({
  fields,
  isLoading,
  onSearch,
  pageKey,
  query,
  tableFeatures,
  options,
}) {
  const { t } = useTranslation();

  const normalizedQuery = useMemo(() => {
    const newQuery = Object.fromEntries(
      Object.keys(query).map((fieldKey) => {
        const field = fields.find(({ id }) => id === fieldKey);
        const queryField = query[fieldKey];

        if (field?.fieldProps?.apiById) {
          return [fieldKey, { id: queryField, api: field?.fieldProps?.apiById }];
        }

        return [fieldKey, queryField];
      })
    );

    return newQuery;
  }, [query, fields]);

  const [data, setData] = useState(normalizedQuery);

  useEffect(() => {
    if (Object.keys(normalizedQuery).length) setData(normalizedQuery);
  }, [normalizedQuery]);

  const { hiddenColumns, columnOptions } = tableFeatures;

  const handleChange = useCallback((name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback(async () => {
    onSearch({ ...data, page: 1 });
  }, [data]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginBottom: '1rem',
        backgroundColor: '#D0D0D0',
        paddingTop: '0.5rem',
      }}
    >
      <form>
        <div
          style={{
            display: 'flex',
            gap: '0.5rem',
            flexWrap: options ? 'wrap' : 'nowrap',
            ...(options ? {} : { flexDirection: 'row' }),
            alignItems: 'flex-end',
          }}
        >
          {fields.map(({ id, fieldProps, component: Component }) => {
            const label = t(`${pageKey}.fields.${id}`);

            return (
              <ItemLabel
                key={id}
                label={label}
                style={{ ...(options ? { minWidth: '8rem', maxWidth: '10rem' } : {}) }}
              >
                <Component
                  key={id}
                  id={id}
                  placeholder={label}
                  data={data}
                  value={data[id]}
                  disabled={isLoading}
                  fieldProps={fieldProps}
                  onChange={handleChange}
                />
              </ItemLabel>
            );
          })}
          <Button
            type='primary'
            onClick={handleSubmit}
            disabled={isLoading}
            style={{ padding: '0 2rem' }}
          >
            {t('table.search')}
          </Button>
        </div>
      </form>
      {!!columnOptions.length && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Select
            mode='multiple'
            placeholder={t('table.hidden')}
            options={columnOptions}
            onChange={tableFeatures.handleSelectHiddenColumn}
            value={hiddenColumns}
            allowClear
            style={{ maxWidth: '30rem', minWidth: '12rem' }}
            maxTagCount={2}
          />
          <Button
            type='dashed'
            onClick={tableFeatures.handleHideDefaultColumns}
            disabled={isLoading}
            style={{ padding: '0 2rem' }}
          >
            {t('table.default')}
          </Button>
        </div>
      )}
    </div>
  );
}

BuilderSearchPanel.propTypes = {
  fields: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageKey: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  tableFeatures: PropTypes.shape({
    hiddenColumns: PropTypes.array,
    columnOptions: PropTypes.array,
    handleHideDefaultColumns: PropTypes.func,
    handleSelectHiddenColumn: PropTypes.func,
  }),
  options: PropTypes.object,
};

BuilderSearchPanel.defaultProps = {
  tableFeatures: null,
  options: null,
};
