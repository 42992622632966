import { resolveWithDelay } from '@lib';
import { Base } from './Base';

// for testing
const mockData = {
  id: '1',
  wagonNumber: 318127433312,
  dispatchDate: '10.10.2022',
  smgs: '0000',
  weight: 51690,
  nhm: 7123,
  numberOfPieces: '54 co ils',
  shop: 'Bekaert EU',
  relation: 'ex OSOPPO to Hlohovec',
  trackings: [
    {
      id: '1',
      transactionDate: '10.10.2022',
      placeOfDispatch: 'Osoppo',
      numberOfDispatch: 143,
      etaDt: '',
      etaKm: '',
      direction: 'Hlohovec',
    },
    {
      id: '2',
      transactionDate: '10.10.2022',
      placeOfDispatch: 'Osoppo',
      numberOfDispatch: 143,
      etaDt: '',
      etaKm: '',
      direction: 'Hlohovec',
    },
    {
      id: '3',
      transactionDate: '10.10.2022',
      placeOfDispatch: 'Osoppo',
      numberOfDispatch: 143,
      etaDt: '',
      etaKm: '',
      direction: 'Hlohovec',
    },
  ],
};

export class TrackingPlace extends Base {
  read = async () => {
    const result = await resolveWithDelay(mockData);

    return result;
  };

  create = async ({ data }) => {
    const result = await resolveWithDelay(data);

    return result;
  };

  update = async ({ data }) => {
    const result = await resolveWithDelay(data);

    return result;
  };

  delete = async ({ id }) => {
    const result = await resolveWithDelay(id);

    return result;
  };
}
