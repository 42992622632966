/* eslint-disable no-magic-numbers */
import * as yup from 'yup';
import { errorTexts } from '@features/errors';

export const mediaSchema = yup.object().shape({
  media: yup
    .mixed()
    .test('file', errorTexts.required, (value) => !!value)
    .required(errorTexts.required()),
});
