import { permissionsSchema } from '../constants';

export function checkWritePermission(permissionsList, pageKey) {
  const isAdmin = permissionsList.adminPermissions !== 'none';

  if (isAdmin) {
    return true;
  }

  const allowPermission = permissionsSchema[pageKey];

  if (!permissionsList || !allowPermission) return false;

  const permissionKey = allowPermission.key;

  const permission = permissionsList[permissionKey];

  const hasPermission = permission === allowPermission.full;

  return hasPermission;
}
