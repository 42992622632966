/* eslint-disable react/jsx-no-bind, max-lines-per-function */
import { useMemo } from 'react';
import { Table as ATable, Typography } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from '@features/localization';
import { ItemLabel } from '@components';

export function Table({ pageKey, data, errors, columns, onSelectRow, onChange, onChangeError }) {
  const { t } = useTranslation();

  const tableColumns = useMemo(
    () =>
      columns.map(({ id, width, component: Component, required, fieldProps }) => ({
        dataIndex: id,
        width,
        title: (
          <Typography.Text style={{ color: 'white' }}>
            {t(`${pageKey}.fields.${id}`)}
          </Typography.Text>
        ),
        ...fieldProps,
        render: (_, record, index) => {
          const placeholder = t(`${pageKey}.fields.${id}`);
          const label = required ? 'Required' : '‎';
          const rowErrors = errors?.find(({ fieldId }) => fieldId === index) || {};

          return (
            <ItemLabel key={id} label={label} required={required} style={{ minHeigth: 58 }}>
              <Component
                id={id}
                required={required}
                placeholder={placeholder}
                data={record}
                errors={rowErrors}
                fieldProps={{ ...fieldProps, style: { width: '100%' } }}
                recordIndex={index}
                onChange={(name, value) => onChange({ name, value, index })}
                handleChangeError={onChangeError}
                pageKey={pageKey}
              />
            </ItemLabel>
          );
        },
      })),
    [columns, errors]
  );

  return (
    <div style={{ minWidth: '600px', backgroundColor: 'white', overflowX: 'auto' }}>
      <ATable
        key='loaded'
        columns={tableColumns}
        tableLayout='fixed'
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        dataSource={data}
        rowSelection={{ type: 'checkbox', onChange: onSelectRow }}
        childrenColumnName='children'
        size='small'
        defaultExpandAllRows
        pagination={{
          hideOnSinglePage: true,
          pageSize: 200,
        }}
      />
    </div>
  );
}

Table.propTypes = {
  pageKey: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  columns: PropTypes.array.isRequired,
  onSelectRow: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeError: PropTypes.func.isRequired,
};

Table.defaultProps = {
  data: null,
  errors: null,
};
