import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Card, Typography } from 'antd';
import { useTranslation } from '@features/localization';

export function TransshipmentCard({ data }) {
  const { t } = useTranslation();

  if (!data) return null;

  return (
    <Card style={{ marginBottom: '0.5rem' }} bodyStyle={{ padding: '0.5rem' }}>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.number')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>{data.number}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.reloadDate')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>
            {dayjs(data.reloadDate).format('DD.MM.YYYY') || '—'}
          </Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.smgsDate')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>
            {dayjs(data.smgsDate).format('DD.MM.YYYY') || '—'}
          </Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.weight')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>{data?.weight || '—'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.quantity')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>{data?.quantity || '—'}</Typography.Text>
        </div>
        <div>
          <Typography.Title level={5}>{t('transshipment.fields.smgs')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>{data?.smgs || '—'}</Typography.Text>
        </div>
      </div>
    </Card>
  );
}

TransshipmentCard.propTypes = {
  data: PropTypes.object,
};

TransshipmentCard.defaultProps = {
  data: null,
};
