import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: 'en',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.language = action.payload;
    },
  },
});

export const appSelectors = {
  app: (state) => state.app,
  language: (state) => appSelectors.app(state).language,
};

const { actions: appActions, reducer: appReducer } = appSlice;

export { appActions };

export default appReducer;
