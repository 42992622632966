import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@features/localization';
import { useQuery } from '../useQuery';

export function useHiddenColumns(columns, defaultRemovedColumns, queryHiddenColumns, pageKey) {
  const { t } = useTranslation();

  const [query] = useQuery();

  const hidden = useMemo(() => {
    const removedColumns = [];

    if (queryHiddenColumns) {
      queryHiddenColumns.forEach(({ queryKey, tableKey }) => {
        if (query[queryKey]) {
          removedColumns.push(tableKey);
        }
      });
    }

    if (defaultRemovedColumns) {
      return removedColumns.concat(defaultRemovedColumns);
    }

    return removedColumns;
  }, [query, defaultRemovedColumns, queryHiddenColumns]);

  const [hiddenColumns, setHiddenColumns] = useState(hidden);

  useEffect(() => {
    setHiddenColumns(hidden);
  }, [hidden]);

  const handleSelectHiddenColumn = useCallback((value) => {
    setHiddenColumns(value);
  }, []);

  const handleHideColumn = useCallback((value) => {
    setHiddenColumns((prev) => [...prev, value]);
  }, []);

  const handleHideDefaultColumns = useCallback(() => {
    setHiddenColumns(defaultRemovedColumns);
  }, []);

  const columnOptions = useMemo(() => {
    return columns
      .filter(({ removable }) => removable)
      .map(({ id }) => ({ value: id, label: t(`${pageKey}.fields.${id}`) }));
  }, [columns, t]);

  const visibleColumns = useMemo(
    () => columns.filter(({ id }) => !hiddenColumns.includes(id)),
    [hiddenColumns]
  );

  return {
    hiddenColumns,
    columnOptions,
    visibleColumns,
    handleHideDefaultColumns,
    handleSelectHiddenColumn,
    handleHideColumn,
  };
}
