import { getProperDateWithTime } from '@lib';

export function normalize1520SimpleApiData(data) {
  return {
    reloadId: data?.reloadId,
    reloadWagonNumber: data?.number1520,
    trackingStation: data?.trackingStation,
    trackingDate: data?.trackingDate && getProperDateWithTime(data?.trackingDate),
  };
}
