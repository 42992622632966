export function normalizeApiData(data) {
  return {
    id: data.id,
    name: data.sessionAbbreviation,
    shopId: data.shopId?.value,
    sendIncotermsId: data.logispedSentCondition?.value,
    stationFromId: data.stationFrom?.value,
    deliveryIncotermsId: data.logispedDeliveryCondition?.value,
    stationToId: data.stationTo?.value,
    orderCurrency: data.currencyOfValueOfGoods?.value,
    expectedDays: data.maxTransportationTime,
    CimSmgsDate: data?.dateSMGS?.hasOwnProperty('value') ? data.dateSMGS.value : data.dateSMGS,
    isTracking: data?.presetTracking?.hasOwnProperty('value')
      ? data.presetTracking.value
      : data.dateSMGS,
    orderSendIncotermsId: data.sessionSentCondition?.value,
    orderStationFromId: data.orderStationFrom?.value,
    orderDeliveryIncotermsId: data.sessionDeliveryCondition?.value,
    orderStationToId: data.orderStationTo?.value,
    note: data.note,
    goods: data.goods,
    nhm: data.nhm,
    senderId: data.sender?.value,
    recipientId: data.recipient?.value,
    notificationFileName: data.prefixXLSNotification,
    notificationNote: data.noteForNotification,
    hidden: data?.display?.hasOwnProperty('value') ? !data.display.value : !data.display,
  };
}
