import React from 'react';
import PropTypes from 'prop-types';
import { Card, theme, Typography } from 'antd';
import { useTranslation } from '@features/localization';

const { useToken } = theme;

export function ClientCard({ data }) {
  const { t } = useTranslation();
  const { token } = useToken();

  if (!data) return null;

  return (
    <Card style={{ marginBottom: '1rem' }} bodyStyle={{ padding: '1rem' }}>
      <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
        <div style={{ flex: '0 1 15rem' }}>
          <Typography.Title level={4}>{t('orders.fields.name')}</Typography.Title>
          <Typography.Text
            style={{ fontSize: '1.5rem', fontWeight: 600, color: token.colorPrimary }}
          >
            {data.name}
          </Typography.Text>
        </div>
        <div style={{ flex: '0 1 10rem' }}>
          <Typography.Title level={4}>{t('orders.fields.organization')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>{data.organization}</Typography.Text>
        </div>
        <div style={{ flex: '0 1 10rem' }}>
          <Typography.Title level={4}>{t('orders.fields.sender')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>{data.sender}</Typography.Text>
        </div>
        <div style={{ flex: '0 1 8rem' }}>
          <Typography.Title level={4}>{t('orders.fields.createdAt')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>{data.createdAt}</Typography.Text>
        </div>
        <div style={{ flex: '0 1 5rem' }}>
          <Typography.Title level={4}>{t('orders.fields.reload')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>
            {data.reload ? t(`modal.yes`) : t(`modal.no`)}
          </Typography.Text>
        </div>
        <div style={{ flex: '0 1 25rem' }}>
          <Typography.Title level={4}>{t('orders.fields.note')}</Typography.Title>
          <Typography.Text style={{ fontSize: '1rem' }}>{data.note}</Typography.Text>
        </div>
      </div>
    </Card>
  );
}

ClientCard.propTypes = {
  data: PropTypes.object,
};

ClientCard.defaultProps = {
  data: null,
};
