import { useCallback, useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from '@features/localization';
import { NotificationsModal } from '@features/clients';
import { getTableColData } from '@features/builder';
import { MODAL_ICONS_BY_MODE } from '@constants';
import { BuilderModal } from '../../BuilderModal';

export function Actions({
  text,
  record,
  pageKey,
  schema,
  overrrides,
  isLoading,
  data,
  modalData,
  columnProps,
  refreshData,
}) {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const { colData, isChildCol } = getTableColData(modalData, record.id);

  if (isChildCol && columnProps.childrenColumn) {
    const { component: Component, columnProps: childrenColumnProps } = columnProps.childrenColumn;

    return (
      <Component
        text={text}
        record={record}
        pageKey={pageKey}
        schema={schema}
        overrrides={overrrides}
        isLoading={isLoading}
        data={data}
        value={data[record.id]}
        columnProps={childrenColumnProps}
      />
    );
  }

  const renderModal = (type) => {
    const modalProps = {
      mode: type,
      pageKey,
      isPageLoading: isLoading,
      schema: schema[type],
      defaultData: colData,
      overrrides,
      refreshData,
      normalizer: schema?.overrides?.normalizeApiData,
      validation: schema?.validationSchema,
    };

    if (schema?.overrides?.customModal && schema?.overrides?.customModal.modes.includes(type)) {
      return <schema.overrides.customModal.modal {...modalProps} />;
    }

    if (type === 'NOTIFICATIONS') {
      return (
        // eslint-disable-next-line react/jsx-no-bind
        <div onClick={(e) => e.stopPropagation()}>
          <Button
            type='primary'
            style={{ width: 24, height: 24, padding: 0 }}
            title={t(`modal.notifications`)}
            icon={MODAL_ICONS_BY_MODE[type]}
            onClick={handleOpenModal}
          />
          {isModalOpen && (
            <NotificationsModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              record={record}
            />
          )}
        </div>
      );
    }

    return <BuilderModal {...modalProps} />;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
      {columnProps.actions.map(({ type }) => (
        <div key={type}>{renderModal(type)}</div>
      ))}
    </div>
  );
}

Actions.propTypes = {
  text: PropTypes.string,
  record: PropTypes.object.isRequired,
  pageKey: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  refreshData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  modalData: PropTypes.array,
  columnProps: PropTypes.object,
  overrrides: PropTypes.object,
};

Actions.defaultProps = {
  text: null,
  isLoading: false,
  data: null,
  modalData: null,
  columnProps: {},
  overrrides: {},
};
