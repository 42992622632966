import React, { memo, useCallback, useState, useMemo } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Layout, Row, Spin, Typography } from 'antd';
import { Navigate, Outlet, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { LOADING_STATES } from '@store/constants';
import { userActions, userSelectors } from '@features/user';
import { useTranslation } from '@features/localization';
import { checkPresencePermisson, getAllowedRoute } from '@features/permissions';
import { CustomMenu } from '../Menu';
import { LangDropdown } from '../LangDropdown';

const { Header, Sider, Content } = Layout;

export const AuthenticatedLayout = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);

  const user = useSelector(userSelectors.userData);

  const permissions = user?.role?.permissions ?? [];

  const loadingState = useSelector(userSelectors.loadingState);

  const toggleCollapse = useCallback(() => setCollapsed((prev) => !prev), []);

  const handleLogout = useCallback(() => {
    dispatch(userActions.logout());
  });

  const { pathname } = useLocation();

  const hasPermission = useMemo(() => checkPresencePermisson(pathname, permissions), [permissions]);

  if (!hasPermission) {
    const allowedRoute = getAllowedRoute(permissions);

    return <Navigate to={allowedRoute} />;
  }

  if (loadingState === LOADING_STATES.LOADING) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin size='large' />
      </div>
    );
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed} style={{ overflowY: 'auto' }}>
        <Row
          align='middle'
          style={{
            height: '4rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography.Title
            level={2}
            style={{
              color: 'white',
              fontWeight: 700,
              margin: 0,
              marginLeft: '1.75rem',
              whiteSpace: 'nowrap',
            }}
          >
            {!collapsed ? 'Logisped' : 'LS'}
          </Typography.Title>
        </Row>
        <CustomMenu />
      </Sider>
      <Layout className='site-layout'>
        <Header
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            type='text'
            onClick={toggleCollapse}
            style={{ color: 'white' }}
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          />
          <Row align='middle'>
            {user?.general.login && (
              <Typography.Text style={{ color: 'white', opacity: 0.65, margin: '0 1rem' }}>
                {user.general.login}
              </Typography.Text>
            )}
            <LangDropdown />
            <Button type='link' danger onClick={handleLogout}>
              {t(`layout.button.signOut`)}
            </Button>
          </Row>
        </Header>
        <Content
          style={{
            padding: '1.5rem',
            overflow: 'auto',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
});

AuthenticatedLayout.displayName = 'AuthenticatedLayout';
