import { useCallback } from 'react';
import { Button, Form } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from '@features/localization';
import { Inputs } from '@features/builder';

export function LoginForm({ handleLogin, isLoading, errors, data, handleChange }) {
  const { t } = useTranslation();

  const handleSubmit = useCallback((values) => {
    handleLogin(values);
  });

  return (
    <Form requiredMark={false} layout='vertical' onFinish={handleSubmit} autoComplete='off'>
      <Form.Item label={t(`login.form.login`)}>
        <Inputs.Input
          id={'login'}
          data={data}
          placeholder={t(`login.form.login`)}
          onChange={handleChange}
          errors={errors}
        />
      </Form.Item>
      <Form.Item label={t(`login.form.password`)}>
        <Inputs.Input
          id={'password'}
          data={data}
          placeholder={t(`login.form.password`)}
          onChange={handleChange}
          errors={errors}
          fieldProps={{ type: 'password' }}
        />
      </Form.Item>
      <Form.Item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button type='primary' htmlType='submit' loading={isLoading}>
          {t(`login.button.login`)}
        </Button>
      </Form.Item>
    </Form>
  );
}

LoginForm.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};
