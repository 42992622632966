export function normalizeTableDataSource(data) {
  return data?.map((item) => {
    if (item?.children && Array.isArray(item.children)) {
      return {
        ...item,
        children: item.children.map((children) => ({ ...children, key: children.id })),
        key: item.id,
      };
    }

    return { ...item, key: item.id };
  });
}
