import dayjs from 'dayjs';

export function normalizeBuilderData(data) {
  const { records, count, page, total } = data;
  const meta = { count, page, total };

  const tableData = records.map((record) => ({
    id: record.wagonId,
    number: record.number,
    sendDate: dayjs(record.sendDate).format('DD.MM.YYYY'),
    cim: record.cim,
    weight: record.weight,
    quantity: record.quantity,
    unit: record.unit,
    shop: record.shop.name,
    order: record.order.name,
    trackingDate:
      record.position?.trackingDate && dayjs(record.position?.trackingDate).format('DD.MM.YYYY'),
    trackingStation: record.position?.trackingStation,
    smer: record.position?.smer,
    children: record.reloads?.map((reload) => ({
      id: `${record.wagonId}-${reload.id}`,
      reloadId: reload.id,
      number: reload.number,
      sendDate: record.reloadDate && dayjs(record.reloadDate).format('DD.MM.YYYY'),
      cim: reload.smgs,
      weight: reload.weight,
      quantity: reload.quantity,
      trackingDate:
        reload.position?.trackingDate && dayjs(reload.position?.trackingDate).format('DD.MM.YYYY'),
      trackingStation: reload.position?.trackingStation,
      arrivalDate:
        reload.position?.arrivalDate && dayjs(reload.position?.arrivalDate).format('DD.MM.YYYY'),
      eta: reload.position?.eta,
      smer: reload.position?.smer,
    })),
  }));

  const modalData = records.map((record) => ({
    id: record.wagonId,
    number: record.number,
    sendDate: dayjs(record.sendDate).format('YYYY-MM-DD'),
    cim: record.cim,
    weight: record.weight,
    quantity: record.quantity,
    unit: record.unit,
    shop: record.shop.name,
    order: record.order.name,
    trackingDate:
      record.position?.trackingDate && dayjs(record.position?.trackingDate).format('DD.MM.YYYY'),
    trackingStation: record.position?.trackingStation,
    smer: record.position?.smer,
    children: record.reloads?.map((reload) => ({
      id: `${record.wagonId}-${reload.id}`,
      reloadId: reload.id,
      number: reload.number,
      sendDate: record.reloadDate && dayjs(record.reloadDate).format('DD.MM.YYYY'),
      cim: reload.smgs,
      weight: reload.weight,
      quantity: reload.quantity,
      trackingDate:
        reload.position?.trackingDate && dayjs(reload.position?.trackingDate).format('DD.MM.YYYY'),
      trackingStation: reload.position?.trackingStation,
      arrivalDate:
        reload.position?.arrivalDate && dayjs(reload.position?.arrivalDate).format('DD.MM.YYYY'),
      eta: reload.position?.eta,
      smer: reload.position?.smer,
    })),
  }));

  return { tableData, modalData, meta };
}
