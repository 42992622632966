import { store } from '@store';
import { Base } from '.';

export class Media extends Base {
  read = async (params) => {
    const result = await this.apiClient.get('/file', { query: { ...params, entity: 'media' } });

    return result;
  };

  create = async (data) => {
    const token = store.getState().user.token;
    const file = new FormData();

    if (data.media) {
      file.append(`entity`, 'media');
      file.append(`entityId`, '123');
      file.append(`note`, data.note ? data.note : '');
      file.append(`file`, data.media, data.media.name);
    }
    // eslint-disable-next-line no-undef
    await this.apiClient.postFile(`${APP_CONFIG.apiUrl}/file/upload`, file, token);
  };

  delete = async ({ id }) => {
    await this.apiClient.delete(`/file/${id}`);
  };

  downloadFile = async ({ id }) => {
    await this.apiClient.get(`/file/${id}`);
  };
}
