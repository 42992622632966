/* eslint-disable max-lines-per-function */

import { PAGE_KEYS, SELECT_BOOL_OPTIONS, SELECT_HIDDEN_OPTIONS } from '@constants';
import { Builder, Columns, Inputs, INPUT_NORMALIZERS, QUERY_FORMATTERS } from '@features/builder';
import { ApiService } from '@services';
import i18n from '@i18n';
import {
  ActionPanel,
  ComplexModal,
  wagonsSchema,
  normalizeBuilderData,
  normalizeApiData,
  HeaderActions,
} from '@features/wagons';

export function WagonsPage() {
  const pageKey = PAGE_KEYS.WAGONS;

  const modalFields = [
    {
      id: 'shop',
      required: true,
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.CLIENTS].read,
        apiById: ApiService[PAGE_KEYS.CLIENTS].readById,
        queryKey: 'filterShopId',
      },
    },
    {
      id: 'order',
      required: true,
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.CLIENTS].readById,
        apiById: ApiService[PAGE_KEYS.ORDERS].readById,
        queryKey: 'filterOrderId',
        customOptionKey: 'orders',
        customSourceKey: 'shop',
      },
    },
    {
      id: 'number',
      required: true,
      component: Inputs.Input,
      style: { fontWeight: 'bold' },
      fieldProps: {
        style: { fontWeight: 'bold' },
      },
    },
    {
      id: 'wagontype',
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.WAGONTYPE].read,
      },
    },
    {
      id: 'sendDate',
      required: true,
      component: Inputs.Date,
      fieldProps: {
        style: { width: '100%' },
      },
    },
    {
      id: 'weight',
      component: Inputs.Input,
      fieldProps: {
        type: 'number',
        min: 1,
        style: { width: '100%' },
      },
    },
    {
      id: 'quantity',
      component: Inputs.Input,
      fieldProps: {
        type: 'number',
        min: 1,
        style: { width: '100%' },
      },
    },
    {
      id: 'cim',
      component: Inputs.Input,
    },
    {
      id: 'unit',
      component: Inputs.Input,
    },
    {
      id: 'orderNumber',
      component: Inputs.Input,
    },
    {
      id: 'isTracking',
      required: true,
      component: Inputs.Select,
      fieldProps: {
        options: SELECT_BOOL_OPTIONS,
        defaultValue: true,
      },
    },
    {
      id: 'note',
      component: Inputs.Input,
      fieldProps: { type: 'textarea' },
    },
    {
      id: 'hidden',
      required: true,
      component: Inputs.Select,
      fieldProps: {
        options: SELECT_BOOL_OPTIONS,
        defaultValue: false,
      },
    },
    {
      id: 'price',
      component: Inputs.Input,
      fieldProps: {
        type: 'number',
        min: 1,
        style: { width: '100%' },
      },
    },
    {
      id: 'currency',
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.CURRENCY].read,
        apiById: ApiService[PAGE_KEYS.CURRENCY].readById,
        selectApiRecord: (record) => ({ value: record.short, label: record.name }),
      },
    },
  ];

  const CREATE_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const UPDATE_MODAL = {
    api: ApiService[pageKey].update,
    fields: modalFields,
  };

  const COPY_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const DELETE_MODAL = {
    api: ApiService[pageKey].delete,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read,
    options: {
      rowSelection: true,
      queryRemovedColumns: [
        { queryKey: 'filterOrderId', tableKey: 'order' },
        { queryKey: 'filterWeight', tableKey: 'weight' },
        { queryKey: 'filterNumber', tableKey: 'number' },
      ],
    },
    columns: [
      {
        id: 'order',
        type: 'string',
        width: 250,
        component: Columns.Text,
      },
      {
        id: 'number',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'sendDate',
        type: 'date',
        component: Columns.Text,
      },
      {
        id: 'weight',
        component: Columns.Text,
        type: 'number',
      },
      {
        id: 'quantity',
        component: Columns.Text,
        type: 'number',
      },
      {
        id: 'unit',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'cim',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'orderNumber',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'price',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'currency',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'isTracking',
        component: Columns.Boolean,
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'UPDATE' }, { type: 'DELETE' }, { type: 'COPY' }],
        },
        component: Columns.Actions,
      },
    ],
    searchPanel: {
      fields: [
        {
          id: 'filterShopId',
          component: Inputs.Select,
          fieldProps: {
            style: { width: '8rem' },
            normalizer: INPUT_NORMALIZERS.selectValues,
            api: ApiService[PAGE_KEYS.CLIENTS].read,
            apiById: ApiService[PAGE_KEYS.CLIENTS].readById,
            includeAllOption: true,
            defaultValue: '',
          },
        },
        {
          id: 'filterOrderId',
          component: Inputs.Select,
          fieldProps: {
            style: { width: '8rem' },
            normalizer: INPUT_NORMALIZERS.selectValues,
            api: ApiService[PAGE_KEYS.CLIENTS].readById,
            apiById: ApiService[PAGE_KEYS.ORDERS].readById,
            customOptionKey: 'orders',
            customSourceKey: 'filterShopId',
            includeAllOption: true,
            defaultValue: '',
          },
        },
        {
          id: 'startWith',
          component: Inputs.Input,
        },
        {
          id: 'filterNumber',
          component: Inputs.Input,
        },
        {
          id: 'filterSendDateFrom',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
        },
        {
          id: 'filterSendDateTo',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
        },
        {
          id: 'filterNhm',
          component: Inputs.Input,
        },
        {
          id: 'filterWeight',
          component: Inputs.Input,
        },
        {
          id: 'hidden',
          component: Inputs.Select,
          fieldProps: {
            style: { width: '8rem' },
            normalizer: INPUT_NORMALIZERS.selectValues,
            options: SELECT_HIDDEN_OPTIONS,
            includeAllOption: true,
            defaultValue: '',
          },
        },
        {
          id: 'sortField',
          component: Inputs.Select,
          fieldProps: {
            style: { width: '8rem' },
            normalizer: INPUT_NORMALIZERS.selectValues,
            options: [
              { value: 'order', label: i18n.t(`wagons.fields.order`) },
              { value: 'number', label: i18n.t(`wagons.fields.number`) },
              { value: 'sendDate', label: i18n.t(`wagons.fields.sendDate`) },
            ],
            includeAllOption: true,
            defaultValue: '',
          },
        },
        {
          id: 'sortDirection',
          component: Inputs.Select,
          fieldProps: {
            style: { width: '8rem' },
            normalizer: INPUT_NORMALIZERS.selectValues,
            options: [
              { value: '', label: i18n.t(`wagons.fields.default`) },
              { value: 'asc', label: i18n.t(`wagons.fields.asc`) },
              { value: 'desc', label: i18n.t(`wagons.fields.desc`) },
            ],
            defaultValue: '',
          },
        },
      ],
    },
    actionPanel: ActionPanel,
  };

  const schema = {
    CREATE: CREATE_MODAL,
    READ: TABLE_SCHEMA,
    UPDATE: UPDATE_MODAL,
    COPY: COPY_MODAL,
    DELETE: DELETE_MODAL,
    overrides: {
      normalizeBuilderData,
      normalizeApiData,
      customModal: { modal: ComplexModal, modes: ['UPDATE', 'COPY'] },
      headerActions: HeaderActions,
    },
    validationSchema: wagonsSchema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
