import { Inputs, QUERY_FORMATTERS } from '@features/builder';

export const COLUMNS = [
  {
    id: 'sendDate',
    width: 125,
    type: 'date',
    required: true,
    component: Inputs.Date,
    formatter: QUERY_FORMATTERS.date,
  },
  {
    id: 'number1435',
    width: 150,
    type: 'number',
    required: true,
    component: Inputs.OptimizedInput,
  },
  {
    id: 'number',
    width: 150,
    type: 'number',
    required: true,
    component: Inputs.OptimizedInput,
  },
  {
    id: 'reloadDate',
    width: 125,
    type: 'date',
    required: true,
    component: Inputs.Date,
    formatter: QUERY_FORMATTERS.date,
  },
  {
    id: 'smgsDate',
    width: 125,
    type: 'date',
    required: true,
    component: Inputs.Date,
    formatter: QUERY_FORMATTERS.date,
  },
  {
    id: 'smgs',
    width: 150,
    type: 'string',
    component: Inputs.OptimizedInput,
  },
  {
    id: 'weight',
    width: 100,
    type: 'number',
    component: Inputs.OptimizedInput,
    fieldProps: {
      type: 'number',
      min: 1,
    },
  },
  {
    id: 'quantity',
    width: 100,
    type: 'number',
    component: Inputs.OptimizedInput,
    fieldProps: {
      type: 'number',
      min: 1,
    },
  },
  {
    id: 'isTracking',
    width: 75,
    type: 'string',
    component: Inputs.Checkbox,
  },
  {
    id: 'isStatistic',
    width: 75,
    type: 'string',
    component: Inputs.Checkbox,
  },
  {
    id: 'note',
    width: 200,
    type: 'string',
    component: Inputs.OptimizedInput,
  },
];
