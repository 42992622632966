import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Upload as AUpload, Form, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export function Upload({ id, fieldProps, onChange, errors, data }) {
  const { t } = useTranslation();

  const { rules, ...uploadProps } = fieldProps;

  const handleChange = useCallback(
    (newValue) => {
      if (newValue.file.status !== 'removed') {
        onChange(id, newValue.file);

        return;
      }
      onChange(id, '');
    },
    [id]
  );

  const handleBeforeUpload = useCallback(() => false, []);

  const handleNormFile = useCallback((e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  }, []);

  return (
    <Form.Item
      rules={rules}
      help={errors[id]}
      validateStatus={errors[id] && 'error'}
      getValueFromEvent={handleNormFile}
      style={{ marginBottom: 0 }}
    >
      <AUpload
        maxCount={1}
        onChange={handleChange}
        fileList={data[id] ? [data[id]] : []}
        {...uploadProps}
        beforeUpload={handleBeforeUpload}
      >
        <Button icon={<UploadOutlined />}>{t(`modal.upload`)}</Button>
      </AUpload>
    </Form.Item>
  );
}

Upload.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fieldProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object,
};

Upload.defaultProps = {
  disabled: false,
  fieldProps: {},
  errors: {},
};
