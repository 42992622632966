import { Base } from '.';

export class Users extends Base {
  read = async () => {
    const result = await this.apiClient.get('/user');

    return result;
  };

  create = async (data) => {
    await this.apiClient.post('/user', { body: { ...data } });
  };

  update = async (data) => {
    await this.apiClient.put('/user', { body: { ...data } });
  };

  delete = async ({ id }) => {
    await this.apiClient.delete(`/user/${id}`);
  };
}
