export function getBuilderServerErrors(rawErrors) {
  const errorKeys = Object.keys(rawErrors);

  const nestedKey = errorKeys.find((key) => Array.isArray(rawErrors[key]));

  if (nestedKey) {
    return rawErrors[nestedKey][0];
  }

  return rawErrors;
}
