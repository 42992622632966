import PropTypes from 'prop-types';
import { Button, DatePicker, Divider, Form, Modal } from 'antd';
import { useCallback, useState } from 'react';
import { useTranslation } from '@features/localization';
import { getNotificationMessages, getProperDate, showNotification } from '@lib';
import { NOTIFICATION_TYPES, PAGE_KEYS } from '@constants';
import { ItemLabel } from '@components';
import { ApiService } from '@services';

export function ActionPanel({ selectedRows, setSelectedRows, refreshData }) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState({});

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedRows([]);
    setSelectedDate({});
    setIsModalOpen(false);
  }, []);

  const handleCancel = useCallback(() => {
    handleCloseModal();
  }, []);

  const handleChange = useCallback((value) => {
    setSelectedDate(value && getProperDate(value));
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);

      const modifiedArray = selectedRows.map((item) => {
        const parts = item.split('-');

        return parts.length > 1 ? parts[1] : item;
      });

      const combinedData = { wagonIds: modifiedArray, trackingDate: selectedDate };

      await ApiService[PAGE_KEYS.TRACKING].addLastTracking(combinedData);

      showNotification(getNotificationMessages(PAGE_KEYS.TRACKING).FINISHED.success);
      handleCancel();

      setSelectedRows([]);
      refreshData();
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('notifications:title.error'),
        description: t('notifications:messages.READ.error'),
      });
    } finally {
      setIsLoading(false);
    }
  }, [selectedDate]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: '1rem' }}>
      <Button type='primary' disabled={!selectedRows.length} onClick={handleOpenModal}>
        {t(`table.finishTracking`)}
      </Button>
      <Modal
        destroyOnClose
        title={t(`tracking.modal.title.edit`)}
        onCancel={handleCancel}
        footer={null}
        open={isModalOpen}
        width={400}
        centered
      >
        <Form>
          <ItemLabel label={t(`tracking.fields.trackingDate`)}>
            <DatePicker
              disabled={isLoading}
              placeholder={t(`tracking.fields.trackingDate`)}
              onChange={handleChange}
              style={{ width: '100%' }}
              format='DD.MM.YYYY'
            />
          </ItemLabel>
          <Divider style={{ margin: '1rem 0' }} />
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
            <Button disabled={isLoading} onClick={handleCancel}>
              {t(`modal.cancel`)}
            </Button>
            <Button
              loading={isLoading}
              type='primary'
              onClick={handleSubmit}
              htmlType='submit'
              disabled={selectedDate.length === undefined}
            >
              {t(`modal.save`)}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

ActionPanel.propTypes = {
  selectedRows: PropTypes.array.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
};
