import { getMultipleValues } from '@features/builder';

export function normalizeBuilderData(data) {
  const { records, count, page, total } = data;
  const meta = { count, page, total };

  const tableData = records.map((record) => {
    return {
      ...record,
      country: record.country?.name,
    };
  });

  const modalData = records.map((record) => {
    const { phones, emailsAvizo, ...recordData } = record;

    return {
      ...recordData,
      hidden: !record.hidden,
      mobilePhones: getMultipleValues(phones, 'mobilePhone', 'phone', 'type', 'mobile'),
      phones: getMultipleValues(phones, 'phone', 'phone', 'type', 'stationary'),
      emailsAvizo: getMultipleValues(emailsAvizo, 'emailAvizo'),
      country: record.country?.id
        ? {
            value: record.country?.id,
            label: record.country?.name,
          }
        : null,
    };
  });

  return { tableData, modalData, meta };
}
