import { getProperDate } from '@lib';

export function normalizeUpdateApiData(data) {
  const normalizedData = {
    ...data,
    isStatistic: data?.isStatistic?.hasOwnProperty('value')
      ? data.isStatistic.value
      : data.isStatistic,
    isTracking: data?.isTracking?.hasOwnProperty('value') ? data.isTracking.value : data.isTracking,
    reloadDate: data?.reloadDate && getProperDate(data?.reloadDate),
  };

  return normalizedData;
}
