import * as yup from 'yup';
import { errorTexts } from '@features/errors';

export const stationsSchema = yup.object().shape({
  name: yup.string().required(errorTexts.required()),
  country: yup
    .object()
    .shape({
      value: yup.number().required(errorTexts.required()),
      label: yup.string().required(errorTexts.required()),
    })
    .required(errorTexts.required()),
});
