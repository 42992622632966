import React, { useEffect, useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import { Inputs } from '@features/builder';

export function OptimizedInput({
  id,
  pageKey,
  placeholder,
  data,
  errors,
  required,
  fieldProps,
  onChange,
  handleChangeError,
}) {
  const value = data[id];

  const [inputValue, setInputValue] = useState(value);

  const handleChangeInputValue = useCallback((_, newValue) => {
    setInputValue(newValue);
  }, []);

  const handleBlurInputValue = useCallback(() => {
    onChange(id, inputValue);
  }, [id, inputValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Inputs.Input
      id={id}
      required={required}
      placeholder={placeholder}
      data={data}
      value={inputValue}
      errors={errors}
      fieldProps={fieldProps}
      onChange={handleChangeInputValue}
      onBlur={handleBlurInputValue}
      handleChangeError={handleChangeError}
      pageKey={pageKey}
    />
  );
}

OptimizedInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pageKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object,
  required: PropTypes.bool,
  fieldProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  handleChangeError: PropTypes.func.isRequired,
};

OptimizedInput.defaultProps = {
  errors: null,
  required: false,
  placeholder: '',
  fieldProps: {},
};
