export function normalizeFcrData(data) {
  const normalizedData = Object.fromEntries(
    Object.entries({
      id: Number(data.id),
      fcrNumber: data.fcrNumber,
      fcrRecipient: data.fcrRecipient,
      fcrMarks: data.fcrMarks,
      fcrRemarks: data.fcrRemarks,
      fcrInstructions: data.fcrInstructions,
      fcrFlag1: data.fcrFlag1,
      fcrFlag2: data.fcrFlag2,
    }).map(([key, value]) => (value !== '' ? [key, value] : [key, null]))
  );

  return normalizedData;
}
