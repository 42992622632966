/* eslint-disable max-lines-per-function */

import i18n from 'i18next';
import dayjs from 'dayjs';
import { PAGE_KEYS, SELECT_BOOL_OPTIONS } from '@constants';
import { Builder, Columns, INPUT_NORMALIZERS, Inputs, QUERY_FORMATTERS } from '@features/builder';
import { ApiService } from '@services';
import { normalize1520LogsBuilderData } from '@features/trackingLogs';

export function Tracking1520LogsPage() {
  const pageKey = PAGE_KEYS.TRACKING_LOGS;

  const sortFields = [
    {
      id: 'recordDate',
    },
    {
      id: 'trackingDate',
    },
    {
      id: 'reloadWagonNumber',
    },
    {
      id: 'trackingStation',
    },
    {
      id: 'trackingNumber',
    },
    {
      id: 'arrivalDate',
    },
    {
      id: 'eta',
    },
    {
      id: 'wagonStationFrom',
    },
    {
      id: 'wagonStationTo',
    },
    {
      id: 'wagonStationBorder',
    },
    {
      id: 'wagonStationDestionation',
    },
    {
      id: 'wagonGoods',
    },
    {
      id: 'inclusionDate',
    },
  ];

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read1520Logs,
    options: {
      searchPanelWrapHandler: true,
    },
    columns: [
      {
        id: 'reloadWagonNumber',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'trackingStation',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'recordDate',
        type: 'date',
        component: Columns.Text,
      },
      {
        id: 'trackingNumber',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'trackingDate',
        type: 'date',
        component: Columns.Text,
      },
      {
        id: 'arrivalDate',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'eta',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'wagonStationFrom',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'wagonStationTo',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'wagonStationBorder',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'wagonStationDestionation',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'wagonGoods',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'inclusionDate',
        type: 'string',
        component: Columns.Text,
      },
    ],
    searchPanel: {
      fields: [
        {
          id: 'filterNumber',
          component: Inputs.Input,
        },
        {
          id: 'filterLastState',
          component: Inputs.Select,
          fieldProps: {
            normalizer: INPUT_NORMALIZERS.selectValues,
            options: SELECT_BOOL_OPTIONS,
            defaultValue: false,
          },
        },
        {
          id: 'filterStationDestionation',
          component: Inputs.Input,
        },
        {
          id: 'filterComparison',
          component: Inputs.Select,
          fieldProps: {
            normalizer: INPUT_NORMALIZERS.selectValues,
            options: [
              { value: '=', label: '=' },
              { value: '<', label: '<' },
              { value: '>', label: '>' },
            ],
            defaultValue: '',
          },
        },
        {
          id: 'filterEta',
          component: Inputs.Input,
        },
        {
          id: 'filterGoods',
          component: Inputs.Input,
        },
        {
          id: 'filterArrivalDateFrom',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '100%' },
          },
        },
        {
          id: 'filterArrivalDateTo',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '100%' },
            defaultValue: dayjs(),
          },
        },
        {
          id: 'filterTrackingDateFrom',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '100%' },
          },
        },
        {
          id: 'filterTrackingDateTo',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '100%' },
          },
        },
        {
          id: 'filterTrackingStation',
          component: Inputs.Input,
        },
        {
          id: 'filterTrackingNumber',
          component: Inputs.Input,
        },
        {
          id: 'filterInclusionDateFrom',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '100%' },
          },
        },
        {
          id: 'filterInclusionDateTo',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '100%' },
          },
        },
        {
          id: 'filterStationTo',
          component: Inputs.Input,
        },
        {
          id: 'filterStationFrom',
          component: Inputs.Input,
        },
        {
          id: 'filterStationBorder',
          component: Inputs.Input,
        },
        {
          id: 'sortField',
          component: Inputs.Select,
          fieldProps: {
            normalizer: INPUT_NORMALIZERS.selectValues,
            options: sortFields.map(({ id }) => ({
              value: id,
              label: i18n.t(`tracking.fields.${id}`),
            })),
            includeAllOption: true,
            defaultValue: '',
          },
        },
        {
          id: 'sortDirection',
          component: Inputs.Select,
          fieldProps: {
            normalizer: INPUT_NORMALIZERS.selectValues,
            options: [
              { value: '', label: i18n.t(`wagons.fields.default`) },
              { value: 'asc', label: i18n.t(`wagons.fields.asc`) },
              { value: 'desc', label: i18n.t(`wagons.fields.desc`) },
            ],
            defaultValue: '',
          },
        },
      ],
    },
  };

  const schema = {
    READ: TABLE_SCHEMA,
    overrides: {
      normalizeBuilderData: normalize1520LogsBuilderData,
    },
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
