import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { showNotification } from '@lib';
import { NOTIFICATION_TYPES } from '@constants';
import { useTranslation } from '@features/localization';
import { normalizeBuilderData } from '@features/builder';
import { BuilderTable } from '../BuilderTable';
import { BuilderHeader } from '../BuilderHeader';

export function Builder({ schema, pageKey }) {
  const { t } = useTranslation();

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [shouldRefresh, setShouldRefresh] = useState(false);

  const handleRefresh = useCallback((value = true) => {
    setShouldRefresh(value);
  }, []);

  const handleFetchData = useCallback(async (apiCall) => {
    try {
      setIsLoading(true);

      const newData = await apiCall();

      const normalizer = schema?.overrides?.normalizeBuilderData;
      const normalizedData = normalizer ? await normalizer(newData) : normalizeBuilderData(newData);

      setData(normalizedData);
    } catch (error) {
      // TODO: Remove after integration is fully tested
      console.warn(error);

      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('notifications:title.error'),
        description: t('notifications:messages.READ.error'),
      });
    }
    setIsLoading(false);
  }, []);

  return (
    <div style={{ backgroundColor: '#D0D0D0' }}>
      <BuilderHeader
        pageKey={pageKey}
        isLoading={isLoading}
        schema={schema?.CREATE}
        refreshData={handleRefresh}
        normalizer={schema?.overrides?.normalizeApiData}
        validation={schema?.validationSchema}
        overrides={schema?.overrides}
        defaultQuery={schema?.defaultQuery}
        searchPanel={schema?.READ?.searchPanel}
      />
      <BuilderTable
        pageKey={pageKey}
        schema={schema}
        data={data}
        onFetchData={handleFetchData}
        shouldRefresh={shouldRefresh}
        refreshData={handleRefresh}
        isLoading={isLoading}
      />
    </div>
  );
}

Builder.propTypes = {
  pageKey: PropTypes.string.isRequired,
  schema: PropTypes.shape({
    CREATE: PropTypes.shape({
      api: PropTypes.func,
      fields: PropTypes.array,
    }),
    READ: PropTypes.shape({
      api: PropTypes.func,
      options: PropTypes.shape({
        defaultRemovedColumns: PropTypes.array,
      }),
      columns: PropTypes.array,
      searchPanel: PropTypes.shape({
        fields: PropTypes.array,
      }),
    }).isRequired,
    UPDATE: PropTypes.shape({
      api: PropTypes.func,
      fields: PropTypes.array,
    }),
    DELETE: PropTypes.shape({
      api: PropTypes.func,
    }),
    overrides: PropTypes.object,
    defaultQuery: PropTypes.object,
    validationSchema: PropTypes.object,
  }).isRequired,
};
