import { Spin, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { NOTIFICATION_TYPES, PAGE_KEYS } from '@constants';
import { useTranslation } from '@features/localization';
import {
  ConfigurationForm,
  getFieldsData,
  normalizeApiData,
  normalizeData,
} from '@features/configuration';
import { ApiService } from '@services';
import { showNotification } from '@lib';
import { normalizeSchedulesApiData, normalizeSchedulesData } from '@features/clients';

export function ConfigurationPage() {
  const { t } = useTranslation();

  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [timeData, setTimeData] = useState([]);

  const handleFetchData = useCallback(async () => {
    try {
      setIsLoading(true);

      const parametersData = await ApiService[PAGE_KEYS.CONFIGURATION].read();
      const scheduleData = await ApiService[PAGE_KEYS.CONFIGURATION].readSchedule();

      const normalizedParametersData = normalizeData(parametersData);
      const normalizedScheduleData = normalizeSchedulesData(scheduleData);

      const fieldsData = getFieldsData(normalizedParametersData);

      setData(normalizedParametersData);
      setTimeData(normalizedScheduleData);
      setFormData(fieldsData);
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('notifications:title.error'),
        description: t('notifications:messages.configuration.READ.error'),
      });
    }
    setIsLoading(false);
  }, []);

  const handleSubmit = useCallback(async () => {
    const normalizedConfig = normalizeApiData(formData);
    const normalizedSchedule = normalizeSchedulesApiData(timeData);

    try {
      setIsLoading(true);
      const sendConfig = () => ApiService[PAGE_KEYS.CONFIGURATION].update(normalizedConfig);
      const sendSchedules = () =>
        ApiService[PAGE_KEYS.CONFIGURATION].updateSchedules(normalizedSchedule);

      await Promise.all([sendConfig(), sendSchedules()]);

      await showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        message: t('notifications:title.success'),
        description: t('notifications:messages.configuration.UPDATE.success'),
      });
      handleFetchData();
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('notifications:title.error'),
        description: t('notifications:messages.configuration.UPDATE.error'),
      });
    }
    setIsLoading(false);
  }, [formData, timeData]);

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    if (!timeData || !timeData.length) {
      setTimeData([{ fieldId: 0, time: null }]);
    }
  }, [timeData]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography.Title>{t(`layout.menu.configuration`)}</Typography.Title>
      </div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '70vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin size='large' />
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ConfigurationForm
            data={data}
            onSubmit={handleSubmit}
            formData={formData}
            setFormData={setFormData}
            timeData={timeData}
            setTimeData={setTimeData}
          />
        </div>
      )}
    </div>
  );
}
