import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { ConfigProvider } from 'antd';
import { PersistGate } from 'redux-persist/integration/react';
import { CustomRouter, history } from '@features/navigation';
import { store, persistor } from '@store';
import './index.css';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/reset.css';
import i18n from './i18n';
import { theme } from './theme';

const root = ReactDOM.createRoot(document.getElementById('root'));

const ReactApp = (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CustomRouter history={history}>
          <ConfigProvider theme={theme}>
            <App />
          </ConfigProvider>
        </CustomRouter>
      </PersistGate>
    </Provider>
  </I18nextProvider>
);

root.render(ReactApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
