export function normalizeBuilderData(data) {
  const { records, count, page, total } = data;
  const meta = { count, page, total };

  const tableData = records.map((record) => ({
    id: record.id,
    country: record.country.short,
    name: record.name,
    number: record.stationNumber,
    psc: record.psc,
    note: record.note,
  }));

  const modalData = records.map((record) => ({
    id: record.id,
    name: record.name,
    number: record.stationNumber,
    psc: record.psc,
    note: record.note,
    country: {
      value: record.country?.id,
      label: record.country?.name,
    },
  }));

  return { tableData, modalData, meta };
}
