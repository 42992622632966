export function normalizeBuilderData(data) {
  const { records, count, page, total } = data;
  const meta = { count, page, total };

  const tableData = records.map((media) => ({
    id: media.id,
    mimetype: media.mimetype,
    filename: media.filename,
    filesize: media.filesize,
    note: media.note,
    entity: media.entity,
    entityId: media.entityId,
    createdAt: media.createdAt,
  }));

  const modalData = records.map((media) => ({
    id: media.id,
    mimetype: media.mimetype,
    filename: media.filename,
    filesize: media.filesize,
    note: media.note,
    entity: media.entity,
    entityId: media.entityId,
    createdAt: media.createdAt,
  }));

  return { tableData, modalData, meta };
}
