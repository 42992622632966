/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Col, Table, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { useTranslation } from '@features/localization';
import {
  useHiddenColumns,
  useQuery,
  normalizeTableDataSource,
  usePagination,
} from '@features/builder';
import { BuilderSearchPanel } from '../BuilderSearchPanel';
import { ExpandIcon } from '../ExpandIcon';

export function BuilderTable({
  pageKey,
  schema,
  data,
  onFetchData,
  refreshData,
  isLoading,
  shouldRefresh,
}) {
  const params = useParams();
  const { api, columns, searchPanel, actionPanel: ActionPanel, options } = schema.READ;
  const { overrides } = schema;
  const { t } = useTranslation();
  const tableRef = useRef();

  const { page, count, handleChangePage, handleChangeCount } = usePagination();

  const { visibleColumns, handleHideColumn, ...tableFeatures } = useHiddenColumns(
    columns,
    options?.defaultRemovedColumns,
    options?.queryRemovedColumns,
    pageKey
  );

  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectRow = useCallback((selectedRowKeys) => {
    setSelectedRows(selectedRowKeys);
  }, []);

  const [query, setQuery] = useQuery(searchPanel?.fields);

  const handleSearch = useCallback(
    (queryParams) => {
      setQuery({ ...query, ...queryParams });
    },
    [query]
  );

  const handleFetchData = useCallback((queryParams) => {
    onFetchData(() => api(queryParams));
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      handleFetchData({ ...query, ...params, page, count, total: true });
      refreshData(false);
    }
  }, [shouldRefresh, params, page, count]);

  useEffect(() => {
    handleFetchData({ ...query, ...params, page, count, total: true });
  }, [query, params, page, count]);

  const handleTableSortingChange = useCallback(
    (pagination, filters, sorter) => {
      const { field, order } = sorter;

      let updatedQuery = { ...query };

      if (order) {
        const sortOrder = order === 'ascend' ? 'asc' : 'desc';

        updatedQuery = { ...query, sortField: field, sortDirection: sortOrder };
      }

      handleFetchData({ ...updatedQuery, ...params, page, count, total: true });
    },
    [query, params, page, count]
  );

  const tableColumns = visibleColumns.map(
    ({ id, secondaryId, type, width, component: Component, removable, columnProps }) => ({
      dataIndex: id,
      ...(options?.sorter && type && { sorter: true }),
      width,
      title: id !== 'actions' && (
        <Col
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text style={{ color: 'white' }}>
              {t(`${pageKey}.fields.${id}`)}
            </Typography.Text>
            {secondaryId && (
              <Typography.Text style={{ color: 'white' }}>
                {t(`${pageKey}.fields.${secondaryId}`)}
              </Typography.Text>
            )}
          </div>
          {removable && (
            <Button
              type='link'
              danger
              shape='circle'
              size='small'
              onClick={() => handleHideColumn(id)}
              icon={<CloseOutlined />}
            />
          )}
        </Col>
      ),
      ...columnProps,
      render: (text, record) => (
        <Component
          text={text}
          record={record}
          pageKey={pageKey}
          overrrides={overrides}
          schema={schema}
          isLoading={isLoading}
          refreshData={refreshData}
          data={data?.tableData}
          modalData={data?.modalData}
          columnProps={columnProps}
          id={id}
        />
      ),
    })
  );
  const dataSource = useMemo(() => normalizeTableDataSource(data?.tableData), [data]);

  return (
    <>
      {overrides?.searchPanel ? (
        <overrides.searchPanel data={data?.searchPanel} isLoading={isLoading} pageKey={pageKey} />
      ) : (
        searchPanel && (
          <BuilderSearchPanel
            fields={searchPanel.fields}
            isLoading={isLoading}
            pageKey={pageKey}
            onSearch={handleSearch}
            query={query}
            tableFeatures={tableFeatures}
            options={options?.searchPanelWrapHandler}
          />
        )
      )}
      {ActionPanel && (
        <ActionPanel
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          refreshData={refreshData}
          query={query}
        />
      )}
      {/* workaround https://github.com/ant-design/ant-design/issues/11615 */}
      {dataSource ? (
        <div style={{ minWidth: '600px', backgroundColor: 'white', overflowX: 'auto' }}>
          <Table
            ref={tableRef}
            key='loaded'
            columns={tableColumns}
            dataSource={dataSource}
            scroll={options?.transferredColumns ? undefined : { x: 'max-content' }}
            rowSelection={
              options?.rowSelection && {
                selectedRowKeys: selectedRows,
                onChange: handleSelectRow,
                getCheckboxProps: (record) => ({
                  disabled: record.id.includes('-'),
                }),
              }
            }
            expandable={{
              expandIcon: (p) => <ExpandIcon {...p} />,
              childrenColumnName: 'children',
              defaultExpandAllRows: true,
            }}
            pagination={{
              // eslint-disable-next-line no-magic-numbers
              pageSizeOptions: [10, 25, 50],
              position: ['bottomCenter'],
              showSizeChanger: true,
              current: page,
              pageSize: count,
              onChange: handleChangePage,
              onShowSizeChange: handleChangeCount,
              total: data?.meta?.total,
            }}
            onRow={(record) => ({
              onClick: () => {
                const updateBtn = tableRef.current.querySelector(`#update-btn-${record.id}`);

                if (!updateBtn) return;

                updateBtn.click();
              },
            })}
            onChange={handleTableSortingChange}
          />
        </div>
      ) : (
        <Table loading={isLoading} key='loading' columns={tableColumns} />
      )}
    </>
  );
}

BuilderTable.propTypes = {
  pageKey: PropTypes.string.isRequired,
  schema: PropTypes.shape({
    READ: PropTypes.shape({
      api: PropTypes.func,
      options: PropTypes.shape({
        defaultRemovedColumns: PropTypes.array,
        queryRemovedColumns: PropTypes.array,
        rowSelection: PropTypes.bool,
        transferredColumns: PropTypes.bool,
        searchPanelWrapHandler: PropTypes.bool,
        sorter: PropTypes.bool,
      }),
      columns: PropTypes.array,
      searchPanel: PropTypes.shape({
        fields: PropTypes.array,
      }),
      actionPanel: PropTypes.func,
    }).isRequired,
    UPDATE: PropTypes.shape({
      api: PropTypes.func,
      fields: PropTypes.array,
    }),
    COPY: PropTypes.shape({
      api: PropTypes.func,
      fields: PropTypes.array,
    }),
    DELETE: PropTypes.shape({
      api: PropTypes.func,
    }),
    overrides: PropTypes.object,
  }).isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onFetchData: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  shouldRefresh: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

BuilderTable.defaultProps = {
  data: null,
  isLoading: false,
};
