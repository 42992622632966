import dayjs from 'dayjs';
import { getProperWagonName } from './getProperWagonName';

export function normalize1435BuilderData(data) {
  const tableData = data.map((record) => ({
    ...record,
    wagonNumber: getProperWagonName(record, 'wagon', 'wagonNumber'),
    recordDate: record?.recordDate && dayjs.utc(record.recordDate).format('DD.MM.YYYY, HH:mm'),
    wagonSendDate: record?.wagonSendDate && dayjs(record.wagonSendDate).format('DD.MM.YYYY'),
    trackingDate:
      record?.trackingDate && dayjs.utc(record.trackingDate).format('DD.MM.YYYY, HH:mm'),
  }));

  const modalData = data.map((record) => ({
    ...record,
    wagonId: record.wagon.id,
    wagonNumber: getProperWagonName(record, 'wagon', 'wagonNumber'),
    recordDate: record?.recordDate && dayjs.utc(record.recordDate),
    trackingDate: record?.trackingDate && dayjs.utc(record.trackingDate),
    wagonSendDate: record?.wagonSendDate && dayjs(record.wagonSendDate),
  }));

  return { tableData, modalData };
}
