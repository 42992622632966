import i18n from '@i18n/index';

export const errorTexts = {
  required: () => `${i18n.t('errors:validation.isRequired')}`,
  maxLength: (length) => `${i18n.t('errors:validation.maxLength')} ${length}`,
  minLength: (length) => `${i18n.t('errors:validation.minLength')} ${length}`,
  email: () => `${i18n.t('errors:validation.isEmail')}`,
  phone: () => `${i18n.t('errors:validation.isPhone')}`,
  url: () => `${i18n.t('errors:validation.isUrl')}`,
  unique: () => `${i18n.t('errors:validation.isUnique')}`,
  formatNumber: () => `${i18n.t('errors:validation.isNumber')}`,
};
