/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Divider, Empty, Spin, Table } from 'antd';
import { useTranslation } from '@features/localization';

import { normalize1435BuilderData } from '@features/tracking';
import { ApiService } from '@services';
import { NOTIFICATION_TYPES, PAGE_KEYS } from '@constants';
import { showNotification } from '@lib';

export function Tracking({ wagonId, handleCancel }) {
  const { t } = useTranslation();

  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleFetchData = useCallback(async () => {
    try {
      setIsLoading(true);

      const newData = await ApiService[PAGE_KEYS.TRACKING].read1435({ id: wagonId });

      const { tableData: normalizedData } = normalize1435BuilderData(newData);

      setData(normalizedData);
    } catch (error) {
      console.warn(error);

      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('notifications:title.error'),
        description: t('notifications:messages.READ.error'),
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    handleFetchData();
  }, []);

  console.log(data);

  const columns = [
    {
      title: t('tracking.fields.trackingDate'),
      key: 'trackingDate',
      dataIndex: 'trackingDate',
      render: (_, render) => render.trackingDate || '-',
    },
    {
      title: t('tracking.fields.trackingStation'),
      key: 'trackingStation',
      dataIndex: 'trackingStation',
      render: (_, render) => render.trackingStation || '-',
    },
    {
      title: t('tracking.fields.trackingNumber'),
      key: 'trackingNumber',
      dataIndex: 'trackingNumber',
      render: (_, render) => render.trackingNumber || '-',
    },
    {
      title: t('tracking.fields.wagonStationBorder'),
      key: 'wagonStationBorder',
      dataIndex: 'wagonStationBorder',
      render: (_, render) => render.wagonStationBorder || '-',
    },
    {
      title: t('tracking.fields.cim'),
      key: 'cim',
      dataIndex: 'cim',
      render: (_, render) => render.cim || '-',
    },
  ];

  return (
    <>
      <form
        autoComplete='off'
        style={{
          height: '23.5rem',
          maxHeight: '23.5rem',
          overflowY: 'auto',
          marginRight: '-1rem',
          paddingRight: '1rem',
        }}
      >
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '20rem',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spin />
          </div>
        ) : !data?.length ? (
          <div
            style={{
              height: '20rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Empty description={t(`tracking.fields.noTracking`)} />
          </div>
        ) : (
          <Table
            dataSource={data}
            columns={columns}
            pagination={false}
            scroll={{
              y: '21rem',
            }}
            // eslint-disable-next-line react/jsx-no-bind
            rowKey='id'
          />
        )}
      </form>
      <Divider style={{ margin: '1rem 0' }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
        <Button disabled={isLoading} onClick={handleCancel}>
          {t(`modal.cancel`)}
        </Button>
      </div>
    </>
  );
}

Tracking.propTypes = {
  wagonId: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
