import { setMultipleValues } from '@features/builder';

export function normalizeApiData(data) {
  return {
    id: data.id,
    short: data.short,
    name: data.name,
    ico: data.ico,
    dic: data.dic,
    icDph: data.icDph,
    address: data.address,
    postcode: data.postcode,
    city: data.city,
    countryId: data.country?.value,
    contactName: data.contactName,
    contactSurname: data.contactSurname,
    phones: [
      ...setMultipleValues(data, 'mobilePhones', 'mobilePhone', { type: 'mobile' }, 'phone'),
      ...setMultipleValues(data, 'phones', 'phone', { type: 'stationary' }, 'phone'),
    ],
    emailMain: data.emailMain,
    site: data.site,
    emailsAvizo: setMultipleValues(data, 'emailsAvizo', 'emailAvizo'),
    hidden: data.hidden?.hasOwnProperty('value') ? !data.hidden.value : !data.hidden,
  };
}
