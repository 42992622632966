import { getProperDate } from '@lib';

export function normalizeApiData(data) {
  const reloads = data.map((record) => ({
    sendDate: record.sendDate && getProperDate(record.sendDate),
    number: record.number,
    number1435: record.number1435,
    reloadDate: record.reloadDate && getProperDate(record.reloadDate),
    smgsDate: record.smgsDate && getProperDate(record.smgsDate),
    smgs: record.smgs,
    weight: record.weight,
    quantity: record.quantity,
    note: record?.note,
    isTracking: record?.isTracking?.hasOwnProperty('value')
      ? record.isTracking.value
      : record.isTracking,
    isStatistic: record?.isStatistic?.hasOwnProperty('value')
      ? record.isStatistic.value
      : record.isStatistic,
  }));

  return { reloads };
}
