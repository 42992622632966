import queryString from 'query-string';
import { Base } from '.';

export class Queue extends Base {
  read = async (params) => {
    const result = await this.apiClient.get('/queue', { query: { ...params } });

    return result;
  };

  status = async (params) => {
    const result = await this.apiClient.get('/queue/status', { query: { ...params } });

    return result;
  };

  sendEmails = async () => {
    await this.apiClient.post('/queue/mail');
  };

  deleteMany = async ({ ids }) => {
    const query = queryString.stringify({ ids });

    await this.apiClient.delete(`/queue?${query}`);
  };
}
