import dayjs from 'dayjs';
import { Base } from './Base';

export class Tracking extends Base {
  read = async (params) => {
    const query = {
      ...params,
      filterSendDateFrom:
        params?.filterSendDateFrom || dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      filterSendDateTo: params?.filterSendDateTo || dayjs().format('YYYY-MM-DD'),
    };

    const result = await this.apiClient.get('/tracking', { query: { ...query } });

    return result;
  };

  read1435 = async (params) => {
    const { id: wagonId, ...restParams } = params;

    const result = await this.apiClient.get('/tracking/1435', {
      query: { ...restParams, wagonId },
    });

    return result;
  };

  read1520 = async (params) => {
    const { id: reloadId, ...restParams } = params;

    const result = await this.apiClient.get('/tracking/1520', {
      query: { ...restParams, reloadId },
    });

    return result;
  };

  create1435 = async (data, params) => {
    const bodyData = {
      tracking: [
        {
          ...data[0],
          wagonId: Number(params.id),
        },
      ],
    };

    await this.apiClient.post('/tracking/1435', { body: bodyData });
  };

  create1520 = async (data, params) => {
    const bodyData = {
      tracking1520: [
        {
          ...data[0],
          reloadId: Number(params.id),
        },
      ],
    };

    await this.apiClient.post('/tracking/1520', { body: bodyData });
  };

  create1435Simple = async (data) => {
    const bodyData = {
      tracking: [
        {
          ...data,
          wagonId: Number(data.wagonId),
        },
      ],
    };

    await this.apiClient.post('/tracking/1435', { body: bodyData });
  };

  create1520Simple = async (data) => {
    const bodyData = {
      tracking1520: [data],
    };

    await this.apiClient.post('/tracking/1520', { body: bodyData });
  };

  create1520Many = async (data) => {
    await this.apiClient.post('/tracking/1520', { body: { ...data } });
  };

  addLastTracking = async (data) => {
    await this.apiClient.post('/tracking/finish', { body: { ...data } });
  };

  update1435 = async (data) => {
    await this.apiClient.put('/tracking/1435', { body: { ...data } });
  };

  update1520 = async (data) => {
    await this.apiClient.put('/tracking/1520', { body: { ...data } });
  };

  delete1435 = async ({ id }) => {
    await this.apiClient.delete(`/tracking/1435/${id}`);
  };

  delete1520 = async ({ id }) => {
    await this.apiClient.delete(`/tracking/1520/${id}`);
  };
}
