/* eslint-disable max-lines-per-function */
import { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { Button } from 'antd';
import { generatePath } from 'react-router-dom';
import { useTranslation } from '@features/localization';
import { Inputs } from '@features/builder';
import { ROUTES } from '@features/navigation';

export function FcrSelect({ wagonId, pageKey, isLoading }) {
  const { t } = useTranslation();

  const [data, setData] = useState({ wagonId, fcrType: 'original' });

  const handleChange = useCallback((name, { value }) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const selectOptions = useMemo(
    () => [
      { value: 'original', label: t(`${pageKey}.fields.original`) },
      { value: 'copy', label: t(`${pageKey}.fields.copy`) },
    ],
    [t]
  );

  return (
    <div style={{ display: 'flex', gap: '1rem' }}>
      <Inputs.Select
        id={'fcrType'}
        placeholder={t(`${pageKey}.fields.fcrType`)}
        data={data}
        onChange={handleChange}
        fieldProps={{
          options: selectOptions,
          showSearch: false,
          style: { width: '10rem' },
        }}
      />

      <Button
        loading={isLoading}
        disabled={!data?.fcrType}
        type='dashed'
        href={generatePath(ROUTES.FCR.path, { id: wagonId, fcrType: data.fcrType })}
        target='blank'
      >
        FCR
      </Button>
    </div>
  );
}

FcrSelect.propTypes = {
  wagonId: PropTypes.string.isRequired,
  pageKey: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
