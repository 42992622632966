/* eslint-disable max-lines-per-function */
// eslint-disable-next-line max-lines-per-function
/* eslint-disable no-magic-numbers */

import { Divider, Modal, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from '@features/localization';
import { getNotificationMessages, showNotification } from '@lib';
import { NOTIFICATION_TYPES, PAGE_KEYS } from '@constants';
import { getErrorNotificationMessage, validate } from '@features/errors';
import {
  normalizeNotificationsApiData,
  normalizeNotificationsData,
  notificationSchema,
  prefilledSchema,
} from '@features/clients';
import { ApiService } from '@services';
import { Notifications } from './Notifications';

export function NotificationsModal({ isModalOpen, setIsModalOpen, record }) {
  const { t } = useTranslation();

  const pageKey = PAGE_KEYS.CLIENTS;

  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchData = useCallback(async () => {
    try {
      setIsFetching(true);

      const orderId = Number(record.id) ? record.id : record.id.split('-').at(-1);

      const emailsData = await ApiService[PAGE_KEYS.ORDERS].readEmailsById({ id: orderId });

      const normalizedEmails = normalizeNotificationsData(emailsData);

      setData(normalizedEmails);
    } catch (error) {
      console.warn(error);

      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('notifications:title.error'),
        description: t('notifications:messages.READ.error'),
      });
    }
    setIsFetching(false);
  }, [record]);

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    if (!data || !data.length) {
      setData([
        {
          fieldId: 0,
          position: 'Odosielateľ',
          emails: [],
          notification: [],
        },
        {
          fieldId: 1,
          position: 'Prijímateľ',
          emails: [],
          notification: [],
        },
        {
          fieldId: 2,
          position: 'Prekladateľ',
          emails: [],
          notification: [],
        },
      ]);
    }
  }, [data]);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);

      const prefilledErrors = validate(prefilledSchema, data.slice(0, 3)) || [];

      const newErrors = validate(notificationSchema, data.slice(3));

      const normalizedNewErrors =
        newErrors?.map((err) => ({ ...err, fieldId: err.fieldId + 3 })) || [];

      if (normalizedNewErrors?.length || prefilledErrors?.length) {
        setErrors([...prefilledErrors, ...normalizedNewErrors]);

        return;
      }

      const orderId = Number(record.id) ? record.id : record.id.split('-').at(-1);

      const normalizedData = normalizeNotificationsApiData(orderId, data);

      await ApiService[PAGE_KEYS.ORDERS].createEmails(normalizedData);

      showNotification(getNotificationMessages(pageKey).UPDATE.success);

      setData([]);

      handleCancel();
    } catch (error) {
      console.warn(error);

      if (error.errorFields) {
        return;
      }
      if (error.serverValidation) {
        setErrors(error.serverValidation);

        return;
      }

      const message = getErrorNotificationMessage(error.message);

      showNotification(
        message
          ? message
          : {
              type: NOTIFICATION_TYPES.ERROR,
              message: t('notifications:title.error'),
              description: t('notifications:messages.UPDATE.error'),
            }
      );
    } finally {
      setIsLoading(false);
    }
  }, [data, errors]);

  const handleCloseModal = useCallback(() => {
    setData([]);
    setErrors([]);
    setIsModalOpen(false);
  }, []);

  const handleCancel = useCallback(() => {
    handleCloseModal();
  }, []);

  return (
    <>
      <Modal
        destroyOnClose
        title={t(`clients.modal.title.notifications`)}
        onCancel={handleCancel}
        onOk={handleSubmit}
        open={isModalOpen}
        confirmLoading={isFetching || isLoading}
        cancelText={t(`modal.cancel`)}
        okText={t(`modal.save`)}
        centered
        width={1200}
      >
        <Divider style={{ margin: '0.5rem 0' }} />
        {isFetching ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '30rem',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spin />
          </div>
        ) : (
          <>
            <Notifications
              data={data}
              errors={errors}
              setErrors={setErrors}
              setData={setData}
              pageKey={pageKey}
              handleCancel={handleCancel}
            />
          </>
        )}
      </Modal>
    </>
  );
}

NotificationsModal.propTypes = {
  record: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};
