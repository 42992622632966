export const allPermissionsList = {
  wagons: {
    key: 'wagons',
    options: ['write', 'read'],
  },
  clients: {
    key: 'clients',
    options: ['write', 'read'],
  },
  tracking: {
    key: 'tracking',
    options: ['write', 'read'],
  },
  orders: {
    key: 'orders',
    options: ['write', 'read'],
  },
  reloading: {
    key: 'transshipment',
    options: ['write', 'read'],
  },
  emailSending: {
    key: 'emailSending',
    options: ['write', 'read'],
  },
  referenceBooks: {
    key: 'catalogs',
    options: ['write', 'read'],
  },
  relations: {
    key: 'relations',
    options: ['write', 'read'],
  },
  adminPermissions: {
    key: 'admin',
    options: ['write'],
  },
};
