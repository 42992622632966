import dayjs from 'dayjs';

export function normalizePrefill1435Data(data) {
  return {
    wagonNumber: data?.number,
    recipientName: data?.order?.recipient?.name,
    recipientCode: data?.order?.recipient?.ico,
    senderName: data?.order?.sender?.name,
    senderCode: data?.order?.sender?.ico,
    isEmpty: data?.isEmpty ? data?.isEmpty : false,
    stationFromNumber: data?.order?.stationFrom?.number,
    stationFromName: data?.order?.stationFrom?.name,
    stationFromAdministration: data?.order?.stationFrom?.code,
    stationToNumber: data?.order?.stationTo?.number,
    stationToName: data?.order?.stationTo?.name,
    stationToAdministration: data?.order?.stationTo?.code,
    wagonSendDate: data?.sendDate && dayjs(data.sendDate),
    recordFrom: data?.recordFrom ? data?.recordFrom : 1,
    wagonNhm: data?.order?.nhm,
    wagonWeight: data?.weight,
  };
}
