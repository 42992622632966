/* eslint-disable react/jsx-no-bind */
import i18next from 'i18next';
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import queryString from 'query-string';
import { MENU_ICONS_BY_ROUTE } from '@features/navigation';

export function ButtonGroup({ buttons, record }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  function renderButton(button) {
    if (button.showCondition) {
      const result = button.showCondition(record);

      if (!result) return;
    }

    if (button?.component) {
      return (
        <div key={button.id} onClick={(e) => e.stopPropagation()}>
          <Button
            style={{ width: 24, height: 24, padding: 0 }}
            type='primary'
            title={i18next.t(`tooltip.${button.id}`)}
            icon={button?.icon || MENU_ICONS_BY_ROUTE[button.path]}
            onClick={handleOpenModal}
          />
          {isModalOpen && (
            <button.component
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              record={record}
            />
          )}
        </div>
      );
    }

    const getPath = () => {
      if (button.params && Object.keys(record)?.length) {
        const queryParams = {};

        button.params.forEach((param) => {
          queryParams[param.queryKey] = record[param.dataKey];
        });

        return `${button.path}?${queryString.stringify(queryParams, {
          skipNull: true,
          skipEmptyString: true,
        })}`;
      }

      return button.path;
    };

    return (
      <RouterLink to={getPath()} key={button.id} onClick={(e) => e.stopPropagation()}>
        <Button
          style={{ width: 24, height: 24, padding: 0 }}
          type='primary'
          title={i18next.t(`tooltip.${button.id}`)}
          icon={button?.icon || MENU_ICONS_BY_ROUTE[button.path]}
        />
      </RouterLink>
    );
  }

  return (
    <div style={{ display: 'flex', gap: '0.25rem', justifyContent: 'flex-end' }}>
      {buttons.map(renderButton)}
    </div>
  );
}

ButtonGroup.propTypes = {
  record: PropTypes.object,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
};

ButtonGroup.defaultProps = {
  record: {},
};
