import { getProperDate } from '@lib';

export function normalizeApiData(records) {
  const normalizedData = {
    wagons: records.map((record) => ({
      orderId: record.order?.value,
      shopId: record.shop?.value,
      number: record?.number,
      sendDate: record.sendDate && getProperDate(record.sendDate),
      weight: Number(record?.weight),
      quantity: record?.quantity === null ? null : Number(record?.quantity),
      orderNumber: record?.orderNumber,
      note: record?.note,
      cim: record?.cim,
      fcrNumber: record?.fcrNumber,
      fcrRecipient: record?.fcrRecipient,
      fcrMarks: record?.fcrMarks,
      fcrRemarks: record?.fcrRemarks,
      fcrInstructions: record?.fcrInstructions,
    })),
  };

  return normalizedData;
}
