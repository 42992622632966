/* eslint-disable react/no-danger */
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Spin } from 'antd';

import { PAGE_KEYS } from '@constants';
import { ApiService } from '@services';
import { ROUTES } from '@features/navigation';
import { getErrorNotificationMessage } from '@features/errors';
import { getNotificationMessages, showNotification } from '@lib';

export function FcrPage() {
  const { id, fcrType } = useParams();

  const [html, setHtml] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleFetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await ApiService[PAGE_KEYS.WAGONS].readFcr({ id, fcrType });

      setHtml(res);
    } catch (error) {
      const message = getErrorNotificationMessage(error.message);

      showNotification(message ? message : getNotificationMessages(PAGE_KEYS.WAGONS).READ.error);

      navigate(ROUTES.WAGONS.path);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    if (html) {
      const newWindow = window.open('', '_self');

      newWindow.document.write(html);
      newWindow.document.close();
    }
  }, [html]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spin size='large' />
      </div>
    );
  }

  return null;
}
