import { Inputs } from '@features/builder';

export const COLUMNS = [
  {
    id: 'reloadWagonNumber',
    component: Inputs.OptimizedInput,
    required: true,
  },
  {
    id: 'trackingStation',
    component: Inputs.OptimizedInput,
    required: true,
  },
  {
    id: 'trackingNumber',
    component: Inputs.OptimizedInput,
  },
  {
    id: 'trackingDate',
    component: Inputs.Date,
    required: true,
    width: 180,
    fieldProps: {
      showDateTime: true,
    },
  },
  {
    id: 'wagonStationDestionation',
    component: Inputs.OptimizedInput,
  },
  {
    id: 'arrivalDate',
    component: Inputs.Date,
  },
  {
    id: 'eta',
    component: Inputs.OptimizedInput,
    fieldProps: {
      type: 'number',
      min: 0,
    },
  },
  {
    id: 'inclusionDate',
    component: Inputs.Date,
  },
  {
    id: 'wagonStationFrom',
    component: Inputs.OptimizedInput,
  },
  {
    id: 'wagonStationTo',
    component: Inputs.OptimizedInput,
  },
  {
    id: 'wagonStationBorder',
    component: Inputs.OptimizedInput,
  },
  {
    id: 'wagonGoods',
    component: Inputs.OptimizedInput,
  },
  {
    id: 'recordDate',
    component: Inputs.Date,
    width: 180,
    fieldProps: {
      showDateTime: true,
    },
  },
];
