/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines-per-function */
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, TimePicker } from 'antd';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { ItemLabel } from '@components';
import { useTranslation } from '@features/localization';

export function Schedules({ data, setData, pageKey }) {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (name, value, index) => {
      const newData = data.map((rowData, i) =>
        i === index ? { ...rowData, [name]: value } : rowData
      );

      setData(newData);
    },
    [data]
  );

  const handleAdd = useCallback(() => {
    const newData = [...data, { time: null }];

    setData(newData);
  }, [data]);

  const handleRemove = useCallback(
    (index) => {
      const newData = data.filter((_, i) => i !== index);

      setData(newData);
    },
    [data]
  );

  const format = 'HH:mm';

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          maxHeight: '12rem',
          minHeight: '4rem',
          overflowY: 'auto',
          overflowX: 'hidden',
          paddingRight: '1rem',
          marginRight: '-1rem',
          gap: '1rem',
        }}
      >
        {data.map((item, index) => {
          const rowValue = item.time;
          const label = `${t(`${pageKey}.time`)} ${index + 1}`;

          return (
            <div key={index} style={{ width: '7rem' }}>
              <div style={{ display: 'flex', width: '100%', gap: '0.5rem' }}>
                <ItemLabel id={'time'} label={label}>
                  <TimePicker
                    format={format}
                    style={{ width: '6rem' }}
                    value={rowValue}
                    onChange={(value) => handleChange('time', value, index)}
                  />
                </ItemLabel>
                {index >= 1 && (
                  <Button
                    style={{ marginTop: '1.25rem' }}
                    type='link'
                    danger
                    onClick={() => handleRemove(index)}
                    icon={<MinusCircleOutlined />}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <Button type='dashed' onClick={handleAdd} style={{ width: '10rem', marginTop: '1rem' }}>
        <PlusOutlined /> {t(`modal.addField`)}
      </Button>
    </>
  );
}

Schedules.propTypes = {
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  pageKey: PropTypes.string.isRequired,
};
