import { useEffect } from 'react';
import { Modal } from 'antd';
import { useTranslation } from '@features/localization';
import { useHistory } from '../useHistory';

export function useConfirmLeave(condition) {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (!condition) return;

    const unblock = history.block(({ retry }) => {
      if (condition) {
        Modal.confirm({
          title: t(`modal.aboutToLeave`),
          okText: t(`modal.yes`),
          cancelText: t(`modal.no`),
          onOk: () => {
            unblock();
            retry();
          },
        });
      }
    });

    return () => {
      unblock();
    };
  }, [condition, history]);
}
