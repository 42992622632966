export function getMultipleValues(rawData, valueKey, dataKey, filterKey, filterValue) {
  let data = rawData;

  if (filterKey) {
    data = rawData.filter((obj) => obj[filterKey] === filterValue);
  }

  const multipleValues = data.map((value, index) => {
    if (filterKey) {
      return { fieldId: index, [valueKey]: value[dataKey] };
    }

    return { fieldId: index, [valueKey]: value };
  });

  return multipleValues;
}
