import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

export function ExpandIcon({ expandable, onExpand, expanded, record }) {
  const handleExpand = useCallback(
    (e) => {
      e.stopPropagation();

      onExpand(record, e);
    },
    [record]
  );

  const style = useMemo(() => ({ float: 'left', marginTop: '4px', marginInlineEnd: '8px' }), []);

  if (!expandable?.length) return null;
  if (expanded) {
    return <UpCircleOutlined onClick={handleExpand} style={style} />;
  }

  return <DownCircleOutlined onClick={handleExpand} style={style} />;
}

ExpandIcon.propTypes = {
  expandable: PropTypes.array,
  record: PropTypes.object.isRequired,
  onExpand: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
};

ExpandIcon.defaultProps = {
  expandable: undefined,
};
