import dayjs from 'dayjs';

export function parseXLSXData(rawData) {
  const parsedData = rawData.map((item) => ({
    id: item.A,
    shop: item.P,
    order: `${item.D} ${item.G}`,
    number: item.A,
    sendDate: dayjs(item.K, 'DD.MM.YYYY'),
    weight: item.H || 0,
    quantity: item.S || null,
    orderNumber: item.Q,
    cim: item.J,
    note: item.R,
    fcrNumber: item.T,
    fcrRecipient: item.U,
    fcrMarks: item.V,
    fcrRemarks: item.W,
    fcrInstructions: item.X,
  }));

  const tableData = parsedData.map((item, index) => ({
    id: item.id,
    key: item.id,
    fieldId: index,
    number: item.number,
    sendDate: item.sendDate,
    quantity: item.quantity,
    weight: item.weight,
    orderNumber: item.orderNumber,
    cim: item.cim,
    note: item.note,
    fcrNumber: item.fcrNumber,
    fcrRecipient: item.fcrRecipient,
    fcrMarks: item.fcrMarks,
    fcrRemarks: item.fcrRemarks,
    fcrInstructions: item.fcrInstructions,
  }));
  const tableLabelsData = parsedData.map((item) => ({ shop: item.shop, order: item.order }));

  return { parsedData, tableData, tableLabelsData };
}
