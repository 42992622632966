import { PAGE_KEYS } from '@constants';
import { Builder, Columns } from '@features/builder';
import { ActionPanel, normalizeBuilderData } from '@features/queue';
import { ApiService } from '@services';

export function QueuePage() {
  const pageKey = PAGE_KEYS.QUEUE;

  const TABLE_SCHEME = {
    api: ApiService[PAGE_KEYS.QUEUE].read,
    options: {
      rowSelection: true,
      transferredColumns: false,
    },
    columns: [
      {
        id: 'type',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'wagon',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'stationFrom',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'stationTo',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'nhm',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'shop',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'order',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'emails',
        width: 250,
        component: Columns.MultilineText,
        type: 'string',
      },
    ],
    actionPanel: ActionPanel,
  };

  const schema = {
    READ: TABLE_SCHEME,
    overrides: {
      normalizeBuilderData,
    },
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
