import { allPermissionsList } from '../constants';
import { translatePermission } from './translatePermission';

export const getPermissionsOptionList = () => {
  const permissionsOptions = Object.keys(allPermissionsList).flatMap((permission) => {
    const { key, options } = allPermissionsList[permission];

    return options.map((option) => {
      let permissionLabel = `${key}_${option}`;
      let permissionOption = `${permission}_${option}`;

      if (key === 'admin') {
        permissionLabel = key;
        permissionOption = key;
      }

      return {
        id: permissionOption,
        label: translatePermission(permissionLabel),
        option: permissionOption,
        value: permissionOption,
      };
    });
  });

  return permissionsOptions;
};
