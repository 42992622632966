import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

export function SubmitButton({ text, data, setData, name, value, handleSubmit }) {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = useCallback(() => {
    setData(name, value);
    setIsClicked(true);
  }, [setData, data]);

  useEffect(() => {
    if (isClicked) {
      handleSubmit();
      setIsClicked(false);
    }
  }, [isClicked]);

  return (
    <Button onClick={handleClick} type='dashed'>
      {text}
    </Button>
  );
}

SubmitButton.propTypes = {
  text: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
