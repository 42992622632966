import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { userSelectors } from '@features/user/store';
import { refreshToken } from '@features/user/store/userSlice';
import { LOADING_STATES } from '@store/constants';
import { ROUTES } from '@features/navigation';

export function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const token = useSelector(userSelectors.token);
  const user = useSelector(userSelectors.userData);
  const loadingState = useSelector(userSelectors.loadingState);

  useEffect(() => {
    if (!user && !token && loadingState !== LOADING_STATES.LOADING) {
      navigate(ROUTES.LOGIN.path);
    }
  }, [user, token, loadingState]);

  useEffect(() => {
    if (token) {
      dispatch(refreshToken());
    }
  }, []);
}
