/* eslint-disable no-magic-numbers */
import * as yup from 'yup';
import { errorTexts } from '@features/errors';

export const trackingImportSchema = yup.array().of(
  yup.object().shape({
    trackingDate: yup.object().required(errorTexts.required()),
    trackingStation: yup.string().required(errorTexts.required()),
  })
);
