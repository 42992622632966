import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from '@components';

export function Text({ text, record, columnProps }) {
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef();

  const checkTruncate = () => {
    const element = textRef.current;

    setIsTruncated(element.offsetWidth < element.scrollWidth);
  };

  useEffect(() => {
    checkTruncate();
    window.addEventListener('resize', checkTruncate);

    return window.removeEventListener('resize', checkTruncate);
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '400px',
        }}
        ref={textRef}
        title={isTruncated ? text : ''}
      >
        {text}
      </div>
      {columnProps?.buttons && text && (
        <ButtonGroup buttons={columnProps?.buttons} record={record} />
      )}
    </div>
  );
}

Text.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  record: PropTypes.object,
  columnProps: PropTypes.object,
};

Text.defaultProps = {
  text: null,
  record: {},
  columnProps: {},
};
