export function normalizeBuilderData(data) {
  const { records, count, page, total } = data;
  const meta = { count, page, total };

  const tableData = records.map((record) => ({
    id: record.short,
    short: record.short,
    name: record.name,
  }));

  const modalData = records.map((record) => ({
    id: record.short,
    short: record.short,
    name: record.name,
  }));

  return { tableData, modalData, meta };
}
