/* eslint-disable no-magic-numbers */
import dayjs from 'dayjs';

export class DateService {
  static getFullYear(date) {
    return dayjs(date).format('YYYY');
  }

  static getFormatDate(date) {
    return dayjs(date).format('DD.MM.YYYY');
  }
}
