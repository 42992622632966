import PropTypes from 'prop-types';
// import { Button } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import { generatePath } from 'react-router';
import { ButtonGroup } from '@components';
import { getTableColData } from '@features/builder/lib';

export function Link({ text, record, data, columnProps }) {
  const { colData } = getTableColData(data, record.id);

  const renderLink = () => {
    if (columnProps.link) {
      const { key, target } = columnProps.link;

      return (
        <a href={colData[key]} target={target}>
          {text}
        </a>
      );
    }
    if (columnProps.path && columnProps.key && record?.[columnProps.key]) {
      const isChild = data.some(({ children }) => children.some(({ id }) => id === colData.id));

      const path = (isChild && columnProps?.secondaryPath) || columnProps.path;
      const dataKey = (isChild && columnProps?.secondaryKey) || columnProps.key;

      return <RouterLink to={generatePath(path, { id: record[dataKey] })}>{text}</RouterLink>;
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {renderLink()}
      {columnProps?.buttons && text && (
        <ButtonGroup buttons={columnProps.buttons} record={record} />
      )}
    </div>
  );
}

Link.propTypes = {
  text: PropTypes.string,
  record: PropTypes.object.isRequired,
  data: PropTypes.array,
  columnProps: PropTypes.object,
};

Link.defaultProps = {
  text: null,
  data: null,
  columnProps: {},
};
