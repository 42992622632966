export function normalizeBuilderData(data) {
  const { records, count, page, total } = data;
  const meta = { count, page, total };

  const tableData = records.map((record) => record);

  const modalData = records.map((record) => record);

  return { tableData, modalData, meta };
}
