/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines-per-function */

import { Divider, Modal, theme } from 'antd';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router';
import { useTranslation } from '@features/localization';
import { getNotificationMessages, showNotification } from '@lib';
import { getErrorNotificationMessage, validate } from '@features/errors';
import { ItemLabel } from '@components';
import { ApiService } from '@services';
import { NOTIFICATION_TYPES } from '@constants';
import { normalizeOrdersData, ordersSchema } from '@features/orders';
import { BuilderModalButton } from '@features/builder';

const { useToken } = theme;

export function OrdersModal({
  defaultData,
  mode,
  schema: { api, fields },
  pageKey,
  isPageLoading,
  refreshData,
  normalizer,
}) {
  const params = useParams();
  const { t } = useTranslation();
  const { token } = useToken();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [isModalLoading, setIsModalLoading] = useState(false);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleChange = useCallback((name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleFetchData = useCallback(async () => {
    try {
      setIsModalLoading(true);

      const newData = await ApiService[pageKey].readById({ id: defaultData.id });

      const normalizedData = normalizeOrdersData(newData);

      setData(normalizedData);
    } catch (error) {
      console.warn(error);

      showNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('notifications:title.error'),
        description: t('notifications:messages.READ.error'),
      });
    }
    setIsModalLoading(false);
  }, [defaultData.id]);

  useEffect(() => {
    handleFetchData();
  }, []);

  const handleSubmit = useCallback(async () => {
    const notificationMode = mode === 'COPY' ? 'CREATE' : mode;

    try {
      setIsModalLoading(true);

      const validationErrors = validate(ordersSchema, data);

      if (validationErrors) {
        setErrors(validationErrors);

        return;
      }

      const normalizedData = normalizer ? normalizer(data) : data;

      await api(normalizedData, params);

      showNotification(getNotificationMessages(pageKey)[notificationMode].success);

      // setData({});
      refreshData();
      handleCloseModal();
    } catch (error) {
      console.warn(error);

      if (error.errorFields) {
        return;
      }
      if (error.serverValidation) {
        setErrors(error.serverValidation);

        return;
      }

      const message = getErrorNotificationMessage(error.message);

      showNotification(
        message ? message : getNotificationMessages(pageKey)[notificationMode].error
      );
    } finally {
      setIsModalLoading(false);
    }
  }, [data, params]);

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <div onClick={(e) => e.stopPropagation()}>
      <BuilderModalButton
        mode={mode}
        defaultData={defaultData}
        pageKey={pageKey}
        isPageLoading={isPageLoading}
        handleOpenModal={handleOpenModal}
      />
      <Modal
        confirmLoading={isModalLoading}
        destroyOnClose
        title={t(`${pageKey}.modal.title.${mode.toLowerCase()}`)}
        okText={t(
          `modal.${
            mode === 'CREATE' || mode === 'COPY' ? 'add' : mode === 'UPDATE' ? 'save' : 'delete'
          }`
        )}
        cancelText={t(`modal.cancel`)}
        onOk={handleSubmit}
        onCancel={handleCloseModal}
        okButtonProps={{ ...(mode === 'DELETE' && { danger: true }) }}
        open={isModalOpen}
        width={905}
        centered
      >
        <Divider style={{ margin: '1rem 0' }} />
        <form
          autoComplete='off'
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            gap: '0.5rem',
            maxHeight: '38rem',
            overflowY: 'auto',
            marginRight: '-1rem',
            paddingRight: '1rem',
          }}
        >
          {fields.map(({ id, required, fieldProps, component: Component, style }) => {
            const labelText = t(`${pageKey}.fields.${id}`);

            return (
              <div key={id} style={{ display: 'inline-block' }}>
                <ItemLabel
                  key={id}
                  label={labelText}
                  required={required}
                  style={{ ...style, color: token.colorPrimary }}
                >
                  {Component && (
                    <Component
                      id={id}
                      required={required}
                      placeholder={labelText}
                      data={data}
                      value={data[id]}
                      errors={errors}
                      fieldProps={fieldProps}
                      onChange={handleChange}
                      pageKey={pageKey}
                    />
                  )}
                </ItemLabel>
              </div>
            );
          })}
        </form>
        <Divider style={{ margin: '1rem 0' }} />
      </Modal>
    </div>
  );
}

OrdersModal.propTypes = {
  mode: PropTypes.oneOf(['CREATE', 'UPDATE', 'COPY', 'DELETE']).isRequired,
  defaultData: PropTypes.object,
  pageKey: PropTypes.string.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  refreshData: PropTypes.func.isRequired,
  schema: PropTypes.shape({
    api: PropTypes.func,
    fields: PropTypes.array,
  }).isRequired,
  normalizer: PropTypes.func,
};

OrdersModal.defaultProps = {
  defaultData: undefined,
  normalizer: undefined,
};
