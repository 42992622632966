/* eslint-disable max-lines-per-function */
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { Button, Divider, Modal } from 'antd';
import { useTranslation } from '@features/localization';
import { NOTIFICATION_TYPES, PAGE_KEYS } from '@constants';
import { getErrorNotificationMessage, validate } from '@features/errors';
import { normalize1520SimpleApiData, tracking1520Schema } from '@features/tracking';
import { ApiService } from '@services';
import { getNotificationMessages, showNotification } from '@lib';
import { Tracking1520 } from '@features/tracking/components/AddTracking1520Modal/Tracking1520';

export function AddTracking1520Modal({ isModalOpen, setIsModalOpen, record }) {
  const { t } = useTranslation();

  const pageKey = PAGE_KEYS.TRACKING;

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchData = useCallback(() => {
    setData({ reloadId: record.reloadId, number1520: record.number });
  }, [record]);

  useEffect(() => {
    handleFetchData();
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);

      const validationErrors = validate(tracking1520Schema, data);

      if (validationErrors) {
        setErrors(validationErrors);

        return;
      }

      const normalizeData = normalize1520SimpleApiData(data);

      await ApiService[PAGE_KEYS.TRACKING].create1520Simple(normalizeData);

      showNotification(getNotificationMessages(pageKey).CREATE.success);

      handleCancel();
    } catch (error) {
      console.warn(error);

      if (error.errorFields) {
        return;
      }
      if (error.serverValidation) {
        setErrors(error.serverValidation);

        return;
      }

      const message = getErrorNotificationMessage(error.message);

      showNotification(
        message
          ? message
          : {
              type: NOTIFICATION_TYPES.ERROR,
              message: t('notifications:title.error'),
              description: t('notifications:messages.CREATE.error'),
            }
      );
    } finally {
      setIsLoading(false);
    }
  }, [data, errors]);

  const handleCloseModal = useCallback(() => {
    setData({});
    setErrors({});
    setIsModalOpen(false);
  }, []);

  const handleCancel = useCallback(() => {
    handleCloseModal();
  }, []);

  return (
    <Modal
      destroyOnClose
      title={t(`tracking.modal.title.addTracking1520`)}
      onCancel={handleCancel}
      open={isModalOpen}
      confirmLoading={isLoading}
      width={416}
      footer={[
        <Button key='ok' onClick={handleSubmit} type='primary'>
          {t(`modal.add`)}
        </Button>,
        <Button key='cancel' onClick={handleCancel}>
          {t(`modal.cancel`)}
        </Button>,
      ]}
      centered
    >
      <Divider style={{ margin: '1rem 0' }} />
      <Tracking1520 data={data} setData={setData} pageKey={pageKey} errors={errors} />
    </Modal>
  );
}

AddTracking1520Modal.propTypes = {
  record: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};
