/* eslint-disable more/no-c-like-loops */
/* eslint-disable max-lines-per-function */

import { read, utils } from 'xlsx';
import { useCallback, useState } from 'react';
import { NOTIFICATION_TYPES, PAGE_KEYS } from '@constants';
import {
  COLUMNS,
  filterByEmptyWagon,
  Header,
  normalizeApiData,
  parseXLSXData,
  Table,
  wagonsImportSchema,
} from '@features/wagonsImport';
import { ApiService } from '@services';
import { getErrorNotificationMessage, validate } from '@features/errors';
import { getNotificationMessages, showNotification } from '@lib';
import { useTranslation } from '@features/localization';
import { useConfirmLeave } from '@features/navigation';

export function WagonsImportPage() {
  const pageKey = PAGE_KEYS.WAGONS_IMPORT;
  const { t } = useTranslation();

  const [file, setFile] = useState('');
  const [showEmptyWagons, setShowEmptyWagons] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [labelsData, setLabelsData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useConfirmLeave(file);

  const handleSelectRow = useCallback((selectedRowKeys) => {
    setSelectedRows(selectedRowKeys);
  }, []);

  const handleChangeError = useCallback((name, value) => {
    setErrors((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleChangeData = useCallback(
    async ({ name, value, index }) => {
      if (name === 'shop') {
        const autofillShop = async (shopIndex) => {
          setFilteredData((prevData) =>
            prevData.map((item, dataIndex) => {
              if (shopIndex === dataIndex) {
                return { ...item, [name]: value, ...(name === 'shop' && { order: null }) };
              }

              return item;
            })
          );

          const row = filteredData[shopIndex];

          if (value.value && row?.orderNumber) {
            const payload = { shopId: value.value, orderNumber: row.orderNumber };

            const wagons = await ApiService[PAGE_KEYS.WAGONS].findByShopIdAndOrderNumber(payload);

            setLabelsData((prevData) =>
              prevData.map((item, dataIndex) => {
                if (shopIndex === dataIndex) {
                  return { ...item, orderNumber: wagons?.[0]?.number };
                }

                return item;
              })
            );
          }
        };
        const shopPromises = [];

        for (let i = index; i <= filteredData.length; i++) {
          shopPromises.push(async () => autofillShop(i));
        }

        await Promise.all(shopPromises.map(async (cb) => cb()));

        return;
      }

      setFilteredData((prevData) =>
        prevData.map((item, dataIndex) => {
          if (index === dataIndex) {
            return { ...item, [name]: value };
          }

          return item;
        })
      );

      if (name === 'order' && index <= filteredData.length) {
        handleChangeData({ name, value, index: index + 1 });
      }
    },
    [filteredData]
  );

  const toggleShowEmptyWagons = useCallback(
    ({ target }) => {
      const showEmpty = target.checked;
      const newFilteredData = showEmpty ? data : filterByEmptyWagon(data);

      setFilteredData(newFilteredData);
      setShowEmptyWagons(showEmpty);
      setErrors([]);
    },
    [data]
  );

  const handleReset = useCallback(() => {
    setData([]);
    setFilteredData([]);
    setLabelsData([]);
    setFile('');
    setErrors([]);
    setSelectedRows([]);
  }, []);

  const handleChangeFile = useCallback(
    async (newValue) => {
      if (!(newValue.file instanceof File)) return void handleReset();

      const buffer = await newValue.file.arrayBuffer();
      const wb = read(buffer);

      const jsonResult = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
        blankrows: false,
        raw: false,
        range: 10,
        header: 'A',
      });

      const { tableData, tableLabelsData } = parseXLSXData(jsonResult);
      const newFilteredData = showEmptyWagons ? tableData : filterByEmptyWagon(tableData);

      setData(tableData);
      setFilteredData(newFilteredData);
      setLabelsData(tableLabelsData);
      setFile(newValue.file);
      setErrors([]);
    },
    [showEmptyWagons]
  );

  const handleSave = useCallback(async () => {
    try {
      const validationErrors = validate(wagonsImportSchema, filteredData);

      if (validationErrors) return void setErrors(validationErrors);

      const selectedData = filteredData.filter(({ id }) => selectedRows.includes(id));
      const normalizedPayload = normalizeApiData(selectedData);

      await ApiService[PAGE_KEYS.WAGONS].create(normalizedPayload);

      setData((prev) => prev.filter(({ id }) => !selectedRows.includes(id)));
      setFilteredData((prev) => prev.filter(({ id }) => !selectedRows.includes(id)));
      setErrors([]);
      setSelectedRows([]);

      showNotification(getNotificationMessages(pageKey).CREATE.success);
    } catch (error) {
      console.warn(error);

      if (error.errorFields) return;
      if (error.serverValidation) {
        const formatErrors = error.serverValidation.wagons;

        if (formatErrors) return void setErrors(formatErrors);

        const newErrors = [];
        const newData = [];

        Object.keys(error.serverValidation).forEach((errorKey) => {
          const [property, value] = errorKey.split('-');
          const errorMessage = error.serverValidation[errorKey];

          const currentItem = filteredData.find((item) => item[property] === value);

          newData.push(currentItem);
          newErrors.push({ fieldId: currentItem.fieldId, [property]: errorMessage });
        });

        setErrors(newErrors);
        setFilteredData(newData);
        setData(newData);

        return;
      }

      const message = getErrorNotificationMessage(error.message);

      showNotification(
        message
          ? message
          : {
              type: NOTIFICATION_TYPES.ERROR,
              message: t('notifications:title.error'),
              description: t(`notifications:messages.${pageKey}.CREATE.error`),
            }
      );
    }
  }, [filteredData, selectedRows]);

  return (
    <div>
      <Header
        pageKey={pageKey}
        file={file}
        showEmptyWagons={showEmptyWagons}
        saveDisabled={!filteredData.length || !selectedRows.length}
        onChangeShowEmptyWagons={toggleShowEmptyWagons}
        onChangeFile={handleChangeFile}
        onSave={handleSave}
      />
      <Table
        pageKey={pageKey}
        data={filteredData}
        errors={errors}
        onSelectRow={handleSelectRow}
        onChangeError={handleChangeError}
        onChange={handleChangeData}
        labelsData={labelsData}
        columns={COLUMNS}
      />
    </div>
  );
}
