import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoginForm } from '@features/login';

import { LOADING_STATES } from '@store/constants';

import { login, userSelectors } from '@features/user/store/userSlice';
import { validate } from '@features/errors';
import { loginSchema } from '@features/login/schemas';

export function LoginPage() {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const loadingState = useSelector(userSelectors.loadingState);

  const handleChange = useCallback((name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleLogin = useCallback(() => {
    const validationErrors = validate(loginSchema, data);

    if (validationErrors) {
      setErrors(validationErrors);

      return;
    }

    dispatch(login(data));
  }, [data]);

  return (
    <LoginForm
      handleLogin={handleLogin}
      handleChange={handleChange}
      data={data}
      isLoading={loadingState === LOADING_STATES.LOADING}
      errors={errors}
    />
  );
}
