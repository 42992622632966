export function normalizeOrdersData(order) {
  return {
    id: order.id,
    sessionAbbreviation: order.name,
    maxTransportationTime: order.expectedDays,
    dateSMGS: order.CimSmgsDate,
    display: !order.hidden,
    presetTracking: order.isTracking,
    currencyOfValueOfGoods: {
      value: order.orderCurrency,
      label: order.orderCurrency,
    },
    prefixXLSNotification: order.notificationFileName,
    note: order.note,
    noteForNotification: order.notificationNote,
    nhm: order.nhm,
    goods: order.goods,
    stationFrom: {
      value: order.stationFrom?.id,
      label: order.stationFrom?.name,
    },
    stationTo: {
      value: order.stationTo?.id,
      label: order.stationTo?.name,
    },
    orderStationFrom: {
      value: order.orderStationFrom?.id,
      label: order.orderStationFrom?.name,
    },
    orderStationTo: {
      value: order.orderStationTo?.id,
      label: order.orderStationTo?.name,
    },
    recipient: {
      value: order.recipient?.id,
      label: order.recipient?.short,
    },
    sessionSentCondition: {
      value: order.orderSendIncoterms?.id,
      label: order.orderSendIncoterms?.short,
    },
    sessionDeliveryCondition: {
      value: order.orderDeliveryIncoterms?.id,
      label: order.orderDeliveryIncoterms?.short,
    },
    logispedSentCondition: {
      value: order.sendIncoterms?.id,
      label: order.sendIncoterms?.short,
    },
    logispedDeliveryCondition: {
      value: order.deliveryIncoterms?.id,
      label: order.deliveryIncoterms?.short,
    },
  };
}
