import i18n from '@i18n';

const FILTER_FIELDS = [
  'name',
  'organization',
  'orderName',
  'stationFrom',
  'stationTo',
  'orderStationFrom',
  'orderStationTo',
  'sender',
  'recipient',
  'nhm',
  'goods',
];

export const getFilterFieldsOptions = () =>
  FILTER_FIELDS.map((field) => ({
    value: field,
    label: i18n.t(`clients.fields.${field}`),
  }));
