/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines-per-function */

import { PAGE_KEYS } from '@constants';
import { Builder, Columns, Inputs, INPUT_NORMALIZERS } from '@features/builder';
import { ApiService } from '@services';
import { normalizeBuilderData, normalizeApiData, usersSchema } from '@features/users';

export function UsersPage() {
  const pageKey = PAGE_KEYS.USERS;

  const modalFields = [
    {
      id: 'login',
      component: Inputs.Input,
      required: true,
    },
    {
      id: 'firm',
      component: Inputs.Input,
    },
    {
      id: 'password',
      component: Inputs.Input,
      required: true,
      fieldProps: {
        type: 'password',
      },
    },
    {
      id: 'roleId',
      required: true,
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.ROLES].read,
        allowClear: true,
        showArrow: true,
      },
    },
    {
      id: 'fullname',
      component: Inputs.Input,
    },
    {
      id: 'shopIds',
      required: true,
      component: Inputs.Select,
      fieldProps: {
        normalizer: INPUT_NORMALIZERS.treeSelect,
        api: ApiService[PAGE_KEYS.CLIENTS].read,
        selectApiRecord: (record) => ({
          value: record.id,
          title: record.name,
          children: record?.orders.map(({ id, name }) => ({
            title: name,
            value: `${record.id}-${id}`,
          })),
        }),
        type: 'nested',
        allowClear: true,
        showSearch: true,
        showArrow: true,
        fetchOnMount: true,
      },
    },
  ];

  const CREATE_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const UPDATE_MODAL = {
    api: ApiService[pageKey].update,
    fields: modalFields,
  };

  const DELETE_MODAL = {
    api: ApiService[pageKey].delete,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read,
    columns: [
      {
        id: 'login',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'fullname',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'firm',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'UPDATE' }, { type: 'DELETE' }],
        },
        component: Columns.Actions,
      },
    ],
  };

  const schema = {
    CREATE: CREATE_MODAL,
    READ: TABLE_SCHEMA,
    UPDATE: UPDATE_MODAL,
    DELETE: DELETE_MODAL,
    overrides: {
      normalizeBuilderData,
      normalizeApiData,
    },
    validationSchema: usersSchema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
