/* eslint-disable more/prefer-includes */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useCallback } from 'react';
import { AutoComplete, Form, Spin } from 'antd';
import PropTypes from 'prop-types';
import { showNotification } from '@lib';
import { NOTIFICATION_TYPES } from '@constants';
import { useTranslation } from '@features/localization';

export function AutoCompleteInput({
  id,
  placeholder,
  fieldProps,
  onChange,
  value,
  errors,
  fieldKey,
  query,
}) {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { api, selectApiRecord, ...autocompleteProps } = fieldProps;
  const { t } = useTranslation();

  const handleSearch = useCallback(
    async (startWith) => {
      if (!api) return;

      try {
        setIsLoading(true);
        const response = await api(query ? query : { startWith }, startWith);

        const newOptions =
          (response?.records || response)?.map((record) =>
            selectApiRecord
              ? selectApiRecord(record)
              : { value: String(record.id), label: record.name }
          ) || [];

        setOptions(newOptions);
      } catch (error) {
        showNotification({
          type: NOTIFICATION_TYPES.ERROR,
          message: t('notifications:title.error'),
          description: t(`errors:server.${error.message}`),
        });
      } finally {
        setIsLoading(false);
      }
    },
    [api]
  );

  const handleFilter = useCallback(
    (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase()),
    []
  );

  const handleChange = useCallback(
    (values, selectedValue) => {
      const selectedLabel = selectedValue ? selectedValue.label : values;
      const selectedOption = options.find((option) => option.label === selectedLabel);
      const selectedOptionValue = selectedOption ? selectedOption.label : values;

      onChange(id, selectedOptionValue, fieldKey);
    },
    [id, onChange, fieldKey, options]
  );

  return (
    <Form.Item help={errors[id]} validateStatus={errors[id] && 'error'} style={{ marginBottom: 0 }}>
      <AutoComplete
        placeholder={placeholder}
        onSearch={handleSearch}
        filterOption={handleFilter}
        onChange={handleChange}
        options={options}
        notFoundContent={
          isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spin size='small' />
            </div>
          ) : null
        }
        value={value}
        {...autocompleteProps}
      />
    </Form.Item>
  );
}

AutoCompleteInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  fieldProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  errors: PropTypes.object,
  fieldKey: PropTypes.number,
  query: PropTypes.object,
};

AutoCompleteInput.defaultProps = {
  placeholder: '',
  fieldProps: {},
  errors: {},
  value: undefined,
  fieldKey: undefined,
  query: undefined,
};
