export const ROUTES = {
  FCR: { path: '/FCR/:id/:fcrType' },
  LOGIN: { path: '/login', protected: false },
  CLIENTS: { path: '/clients', protected: true, restricted: true },
  ORDERS: { path: '/clients/:id', protected: true, restricted: true },
  WAGONS: { path: '/wagons', protected: true },
  WAGONS_IMPORT: { path: '/wagons/import', protected: true, restricted: true },
  TRANSSHIPMENT: { path: '/transshipment', protected: true },
  TRANSSHIPMENT_IMPORT: { path: '/transshipment/import', protected: true, restricted: true },
  TRACKING: { path: '/tracking', protected: true },
  TRACKING1435: { path: '/tracking1435/:id', protected: true, restricted: true },
  TRACKING1520: { path: '/tracking1520/:id', protected: true, restricted: true },
  TRACKING1520_IMPORT: { path: '/tracking1520/import', protected: true, restricted: true },
  QUEUE: { path: '/queue', protected: true },
  QUEUE_STATUS: { path: '/queue/status', protected: true, restricted: true },
  TRACKING_LOGS: {
    path: '/tracking_logs',
    protected: true,
    children: {
      TRACKING1435LOGS: {
        path: `/tracking_logs/tracking1435`,
        protected: true,
      },
      TRACKING1520LOGS: {
        path: `/tracking_logs/tracking1520`,
        protected: true,
      },
    },
  },
  SETTINGS: {
    path: '/settings',
    protected: true,
    children: {
      CATALOGS: {
        path: `/settings/catalogs`,
        protected: true,
        restricted: true,
        children: {
          CURRENCY: { path: `/settings/catalogs/currency`, protected: true },
          ORGANIZATIONS: { path: `/settings/catalogs/organizations`, protected: true },
          INCONTERMS: { path: `/settings/catalogs/inconterms`, protected: true },
          STATIONS: { path: `/settings/catalogs/stations`, protected: true },
          COUNTRIES: { path: `/settings/catalogs/countries`, protected: true },
          WAGONTYPE: { path: `/settings/catalogs/wagontype`, protected: true },
        },
      },
      USERS: { path: `/settings/users`, protected: true, restricted: true },
      ROLES: { path: `/settings/roles`, protected: true, restricted: true },
      CONFIGURATION: { path: `/settings/configuration`, protected: true, restricted: true },
      MEDIA_LIBRARY: { path: `/settings/media_library`, protected: true },
    },
  },
};
