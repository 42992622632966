/* eslint-disable no-magic-numbers */
import * as yup from 'yup';
import { errorTexts } from '@features/errors';

export const incontermsSchema = yup.object().shape({
  short: yup
    .string()
    .max(3, errorTexts.maxLength(3, errorTexts.maxLength(3)))
    .required(errorTexts.required()),
  description: yup.string().required(errorTexts.required()),
});
