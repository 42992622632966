import PropTypes from 'prop-types';
import { ButtonGroup } from '@components';

export function MultilineText({ text, record, columnProps }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {text}
      {columnProps?.buttons && text && (
        <ButtonGroup buttons={columnProps?.buttons} record={record} />
      )}
    </div>
  );
}

MultilineText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  record: PropTypes.object,
  columnProps: PropTypes.object,
};

MultilineText.defaultProps = {
  text: null,
  record: {},
  columnProps: {},
};

export default MultilineText;
