import dayjs from 'dayjs';

export function normalize1435LogsBuilderData(data) {
  const { records, count, page, total } = data;
  const meta = { count, page, total };

  const tableData = records.map((record) => ({
    ...record,
    recordDate: record?.recordDate && dayjs.utc(record.recordDate).format('DD.MM.YYYY, HH:mm'),
    wagonSendDate: record?.wagonSendDate && dayjs(record.wagonSendDate).format('DD.MM.YYYY'),
    trackingDate:
      record?.trackingDate && dayjs.utc(record.trackingDate).format('DD.MM.YYYY, HH:mm'),
  }));

  return { tableData, meta };
}
