import { DateService } from '@features/app';

export function normalizeBuilderData(data) {
  const { orders, count, page, total } = data;
  const meta = { count, page, total };

  const searchPanelData = {
    id: data.id,
    name: data.name,
    sender: data.organization?.short,
    organization: data.organization?.short,
    createdAt: DateService.getFormatDate(data.createdAt),
    reload: data.reload,
    note: data.note,
  };

  const tableData = orders.map((order) => {
    return {
      id: order.id,
      sessionAbbreviation: order.name,
      stationFrom: order.stationFrom?.name,
      stationTo: order.stationTo?.name,
      nhm: order.nhm,
      goods: order.goods,
      orderStationFrom: order.orderStationFrom?.name,
      orderStationTo: order.orderStationTo?.name,
      recipient: order.recipient?.short,
    };
  });

  const modalData = orders.map((order) => {
    return {
      id: order.id,
      sessionAbbreviation: order.name,
      nhm: order.nhm,
      goods: order.goods,
      stationFrom: {
        value: order.stationFrom?.id,
        label: order.stationFrom?.name,
      },
      stationTo: {
        value: order.stationTo?.id,
        label: order.stationTo?.name,
      },
      orderStationFrom: {
        value: order.orderStationFrom?.id,
        label: order.orderStationFrom?.name,
      },
      orderStationTo: {
        value: order.orderStationTo?.id,
        label: order.orderStationTo?.name,
      },
      recipient: {
        value: order.recipient?.id,
        label: order.recipient?.short,
      },
      hidden: data?.hidden,
    };
  });

  return {
    searchPanel: searchPanelData,
    tableData,
    modalData,
    meta,
  };
}
