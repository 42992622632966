import { getProperDate, getProperDateWithTime } from '@lib';

export function normalize1520ApiData(data, mode) {
  const normalized = {
    ...data,
    id: Number(data.id),
    arrivalDate: data?.arrivalDate && getProperDate(data?.arrivalDate),
    inclusionDate: data?.inclusionDate && getProperDate(data?.inclusionDate),
    recordDate: data?.recordDate && getProperDateWithTime(data?.recordDate),
    trackingDate: data?.trackingDate && getProperDateWithTime(data?.trackingDate),
  };

  return mode === 'CREATE' || mode === 'COPY' ? [normalized] : normalized;
}
