import i18n from '@i18n';

export function translatePermission(permission) {
  if (permission === 'admin') {
    return i18n.t(`permissions.admin`);
  }

  const [rawPrefix, rawAction] = permission.toLowerCase().split('_');

  const prefix = i18n.t(`permissions.${rawPrefix}`);
  const action = i18n.t(`permissions.${rawAction}`);

  return `${prefix}-${action}`;
}
