import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/en';
import 'dayjs/locale/sk';
import dayjs from 'dayjs';

import resources from '../locales';

export const AVAILABLE_LOCALES = Object.keys(resources);

const fallbackLng = 'en';

dayjs.extend(localeData);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
