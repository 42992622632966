export function normalizeBuilderData(data) {
  const { records, count, page, total } = data;
  const meta = { count, page, total };

  const tableData = records.map((record) => ({
    id: record?.id,
    shop: record?.shop?.name,
    order: record?.order?.name,
    nhm: record?.order?.nhm,
    stationFrom: record?.order?.stationFrom?.name,
    stationTo: record?.order?.stationTo?.name,
    wagon: record?.wagon?.number,
    type: record?.type?.titleSk,
    emails: record?.emails.join(', '),
  }));

  return { tableData, meta };
}
