import { getProperDate } from '@lib';

export function normalizeApiData(data) {
  const { shop, order, wagontype, ...restData } = data;

  const normalizedData = {
    id: restData.id,
    wagons: [
      {
        ...restData,
        hidden: restData?.hidden?.hasOwnProperty('value')
          ? !restData.hidden.value
          : !restData.hidden,
        isTracking: restData?.isTracking?.hasOwnProperty('value')
          ? restData.isTracking.value
          : restData.isTracking,
        wagonTypeId: wagontype?.value,
        currency: restData?.currency?.value,
        orderId: order?.value,
        shopId: shop?.value,
        price: restData?.price && Number(restData?.price),
        damagePrice: Number(restData?.damagePrice),
        damageCurrency: restData?.damageCurrency?.value,
        sendDate: restData.sendDate && getProperDate(restData.sendDate),
        weight: Number(restData?.weight),
      },
    ],
  };

  return normalizedData;
}
