/* eslint-disable func-names */

import * as yup from 'yup';
import { errorTexts } from '@features/errors';

yup.addMethod(yup.array, 'unique', function (message, path) {
  return this.test('unique', message, function (list) {
    const mapper = (x) => path.split('.').reduce((acc, val) => acc && acc[val], x);
    const seen = new Set();
    const duplicates = new Set();

    list.forEach((item) => {
      const val = mapper(item);

      if (seen.has(val)) {
        duplicates.add(val);
      } else {
        seen.add(val);
      }
    });

    if (!duplicates.size) {
      return true;
    }

    const errors = [];

    list.forEach((item, index) => {
      const val = mapper(item);

      const firstOccurrence = list.findIndex(({ position }) => position === val) === index;

      if (duplicates.has(val) && val !== '' && !firstOccurrence) {
        const error = this.createError({
          path: `[${index}].${path}`,
          message: message || 'This field must be unique',
        });

        errors.push(error);
      }
    });

    if (errors.length) {
      throw new yup.ValidationError(errors);
    }

    return true;
  });
});

export const notificationSchema = yup
  .array()
  .of(
    yup.object().shape({
      fieldId: yup.number().required(),
      position: yup.string().required(errorTexts.required()),
      emails: yup.array().of(
        yup.object().shape({
          fieldId: yup.number().required(),
          email: yup.string().email(errorTexts.email()).required(errorTexts.required()),
        })
      ),
      notification: yup
        .array()
        .of(yup.string().required(errorTexts.required()))
        .min(1, errorTexts.required()),
    })
  )
  .unique(errorTexts.unique(), 'position');

export const prefilledSchema = yup.array().of(
  yup.lazy((_, options) => {
    const filledSchema = yup.object().shape({
      fieldId: yup.number().required(),
      position: yup.string().required(errorTexts.required()),
      emails: yup.array().of(
        yup.object().shape({
          fieldId: yup.number().required(),
          email: yup.string().email(errorTexts.email()).required(errorTexts.required()),
        })
      ),
      notification: yup
        .array()
        .of(yup.string().required(errorTexts.required()))
        .min(1, errorTexts.required()),
    });
    const nullableSchema = yup.object().shape({
      fieldId: yup.number().nullable(),
      position: yup.string().nullable(),
      emails: yup.array().of(
        yup.object().shape({
          fieldId: yup.number().nullable(),
          email: yup.string().email(errorTexts.email()).nullable(),
        })
      ),
      notification: yup.array().of(yup.string().nullable()),
    });

    const isFilled = options?.parent?.some(
      (opt) =>
        opt && opt.position && opt.emails.every(({ email }) => email) && opt.notification.length
    );

    return isFilled ? nullableSchema : filledSchema;
  })
);
