/* eslint-disable max-lines-per-function */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Select as ASelect, TreeSelect, Form, Spin, Empty } from 'antd';
import { useTranslation } from '@features/localization';
import { useSelectHandlers } from './useSelectHandlers';

const { SHOW_PARENT, SHOW_ALL } = TreeSelect;

export function Select({
  id,
  placeholder,
  disabled,
  fieldProps,
  onChange,
  data,
  errors,
  fieldKey,
  query,
}) {
  const {
    options: rawOptions,
    includeAllOption,
    normalizer,
    type,
    defaultValue,
    api,
    apiById,
    selectApiRecord,
    customOptionKey,
    customSourceKey,
    includeQuery,
    queryKey,
    fetchOnMount,
    ...selectProps
  } = fieldProps;
  const value = data[id];

  const { t } = useTranslation();
  const allOption = { label: t(`modal.all`), value: '' };

  const defaultOptions = useMemo(() => {
    const newDefaultOptions = rawOptions || [];

    if (includeAllOption) {
      return [allOption, ...newDefaultOptions];
    }

    return newDefaultOptions;
  }, [rawOptions]);

  const [options, setOptions] = useState(defaultOptions);
  const [isLoading, setIsLoading] = useState(false);

  const { handleSearch, handleFilter, handleChange, handleSelectClick, handleFetchById } =
    useSelectHandlers({
      id,
      value,
      data,
      includeAllOption,
      allOption,
      rawOptions,
      options,
      api,
      normalizer,
      selectApiRecord,
      setIsLoading,
      setOptions,
      onChange,
      fieldKey,
      customOptionKey,
      customSourceKey,
      includeQuery,
      query,
    });

  useEffect(() => {
    if (fetchOnMount) handleSelectClick();
  }, [fetchOnMount]);

  useEffect(() => {
    if (defaultValue !== undefined && !data.hasOwnProperty(id)) {
      onChange(id, defaultValue);
    }
  }, [id, fieldProps?.defaultValue]);

  useEffect(() => {
    if (query?.[queryKey]) {
      onChange(id, { id: query[queryKey], api: apiById });
    }
  }, [query]);

  useEffect(() => {
    if (value?.api) {
      handleFetchById();
    }
  }, [value]);

  function renderSelect() {
    const inputProps = {
      placeholder,
      disabled,
      onChange: handleChange,
      value,
      dropdownMatchSelectWidth: false,
      dropdownStyle: { maxWidth: 'fit-content' },
      ...selectProps,
    };

    switch (type) {
      case 'nested':
        return (
          <TreeSelect
            {...inputProps}
            treeData={options}
            showCheckedStrategy={SHOW_PARENT}
            treeNodeFilterProp={'title'}
            onClick={fetchOnMount ? null : handleSelectClick}
            multiple
            showSearch
            notFoundContent={
              isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Spin size='small' />
                </div>
              ) : null
            }
          />
        );
      default:
        return (
          <ASelect
            onClick={handleSelectClick}
            options={options}
            filterOption={handleFilter}
            onSearch={api && handleSearch}
            showSearch
            notFoundContent={
              isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Spin size='small' />
                </div>
              ) : null
            }
            {...inputProps}
          />
        );
    }
  }

  return (
    <Form.Item help={errors[id]} validateStatus={errors[id] && 'error'} style={{ marginBottom: 0 }}>
      {renderSelect()}
    </Form.Item>
  );
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  fieldProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object,
  disabledValidation: PropTypes.bool,
  fieldKey: PropTypes.number,
  query: PropTypes.object,
};

Select.defaultProps = {
  placeholder: '',
  disabled: false,
  fieldProps: {},
  errors: {},
  disabledValidation: false,
  fieldKey: undefined,
  query: undefined,
};
