import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { appReducer } from '@features/app';
import { userReducer } from '@features/user';

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['app', 'user'],
};

const appPersistConfig = {
  key: 'app',
  storage,
};

const userPersistConfig = {
  key: 'user',
  storage,
  blacklist: ['userData', 'loadingState', 'role'],
};

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  user: persistReducer(userPersistConfig, userReducer),
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export function createStore() {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  const persistor = persistStore(store);

  return { store, persistor };
}

const { store, persistor } = createStore();

export { store, persistor };
