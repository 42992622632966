import dayjs from 'dayjs';

export function parseXLSXData(rawData) {
  const tableData = rawData.map((item, index) => ({
    id: +item.C + index,
    key: +item.C + index,
    fieldId: index,
    sendDate: dayjs(item.A, 'DD.MM.YYYY'),
    number1435: item.B.trim(),
    number: item.C.trim(),
    reloadDate: dayjs(item.D, 'DD.MM.YYYY'),
    smgsDate: dayjs(item.E, 'DD.MM.YYYY'),
    smgs: item.F,
    weight: +item.G || 0,
    quantity: +item.H || 0,
    isTracking: item.I === 'TRUE',
    isStatistic: item.J === 'TRUE',
    note: item.K,
  }));

  return { tableData };
}
