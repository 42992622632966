import { Base } from './Base';

export class Transshipment extends Base {
  read = async (params) => {
    const result = await this.apiClient.get('/reloads', { query: { ...params } });

    return result;
  };

  readById = async ({ id }) => {
    const result = await this.apiClient.get(`/reloads/${id}`);

    return result;
  };

  create = async (data) => {
    await this.apiClient.post('/reloads', { body: { ...data } });
  };

  update = async (data) => {
    await this.apiClient.put('/reloads', { body: { ...data } });
  };

  delete = async ({ id }) => {
    await this.apiClient.delete(`/reloads/${id}`);
  };

  exportXls = async (params) => {
    const result = await this.apiClient.getBlob(`/reloads/export${params ? `?${params}` : ''}`);

    return result;
  };
}
