import { Button } from 'antd';
import PropTypes from 'prop-types';

import { MODAL_ICONS_BY_MODE } from '@constants';
import { useTranslation } from '@features/localization';

export function BuilderModalButton({ mode, defaultData, pageKey, isPageLoading, handleOpenModal }) {
  const { t } = useTranslation();

  const buttonProps = {
    onClick: handleOpenModal,
    disabled: isPageLoading,
  };

  switch (mode) {
    case 'CREATE':
      return <Button type='link' {...buttonProps}>{`+ ${t(`${pageKey}.modal.openBtn`)}`}</Button>;
    case 'UPDATE':
      return (
        <Button
          type='primary'
          id={`update-btn-${defaultData.id}`}
          style={{ width: 24, height: 24, padding: 0 }}
          title={t(`modal.update`)}
          icon={MODAL_ICONS_BY_MODE[mode]}
          {...buttonProps}
        />
      );
    case 'COPY':
      return (
        <Button
          ghost
          type='primary'
          style={{ width: 24, height: 24, padding: 0 }}
          title={t(`modal.copy`)}
          icon={MODAL_ICONS_BY_MODE[mode]}
          {...buttonProps}
        />
      );
    case 'DELETE':
      return (
        <Button
          danger
          type='primary'
          style={{ width: 24, height: 24, padding: 0 }}
          title={t(`modal.delete`)}
          icon={MODAL_ICONS_BY_MODE[mode]}
          {...buttonProps}
        />
      );
    case 'ADD':
      return (
        <Button
          type='primary'
          style={{ width: 24, height: 24, padding: 0 }}
          title={t(`modal.add`)}
          icon={MODAL_ICONS_BY_MODE[mode]}
          {...buttonProps}
        />
      );
    default:
      return null;
  }
}

BuilderModalButton.propTypes = {
  mode: PropTypes.oneOf(['CREATE', 'UPDATE', 'COPY', 'DELETE', 'ADD']).isRequired,
  defaultData: PropTypes.object,
  pageKey: PropTypes.string.isRequired,
  isPageLoading: PropTypes.bool.isRequired,
  handleOpenModal: PropTypes.func,
};

BuilderModalButton.defaultProps = {
  defaultData: undefined,
  handleOpenModal: undefined,
};
