/* eslint-disable no-magic-numbers */
import * as yup from 'yup';
import { errorTexts } from '@features/errors';

export const tracking1435Schema = yup.object().shape({
  trackingDate: yup.object().required(errorTexts.required()),
  wagonSendDate: yup.object().required(errorTexts.required()),
  trackingStation: yup.string().required(errorTexts.required()),
  recordDate: yup.object().nonNullable(errorTexts.required()),
});
