/* eslint-disable max-lines-per-function */
import { PAGE_KEYS, SELECT_BOOL_OPTIONS } from '@constants';
import { ApiService } from '@services';
import i18n from '@i18n';
import { Builder, Columns, Inputs, INPUT_NORMALIZERS, QUERY_FORMATTERS } from '@features/builder';
import {
  HeaderActions,
  normalizeBuilderData,
  normalizeApiData,
  TransshipmentModal,
  transshipmentSchema,
  ActionPanel,
} from '@features/transshipment';

export function TransshipmentPage() {
  const pageKey = PAGE_KEYS.TRANSSHIPMENT;

  const modalFields = [
    {
      id: 'mainWagon',
      required: true,
      component: Inputs.Select,
      fieldProps: {
        api: ({ filterOrderId, filterShopId }, startWith) =>
          ApiService[PAGE_KEYS.WAGONS].read({
            filterOrderId,
            filterShopId,
            startWith,
          }),
        selectApiRecord: (record) => ({ value: record.id, label: record.number }),
        includeQuery: true,
      },
    },
    {
      id: 'reloadDate',
      required: true,
      component: Inputs.Date,
      fieldProps: {
        style: { width: '100%' },
      },
    },
    {
      id: 'smgsDate',
      required: true,
      component: Inputs.Date,
      fieldProps: {
        style: { width: '100%' },
      },
    },
    {
      id: 'smgs',
      component: Inputs.Input,
    },
    {
      id: 'sendDate',
      required: true,
      component: Inputs.Date,
      fieldProps: {
        style: { width: '100%' },
      },
    },
    {
      id: 'weight',
      component: Inputs.Input,
      fieldProps: {
        style: { width: '100%' },
        type: 'number',
        min: 1,
      },
    },
    {
      id: 'isTracking',
      required: true,
      component: Inputs.Select,
      fieldProps: {
        options: SELECT_BOOL_OPTIONS,
        defaultValue: true,
      },
    },
    {
      id: 'isStatistic',
      required: true,
      component: Inputs.Select,
      fieldProps: {
        options: SELECT_BOOL_OPTIONS,
        defaultValue: true,
      },
    },
    {
      id: 'quantity',
      component: Inputs.Input,
      fieldProps: {
        style: { width: '100%' },
        type: 'number',
        min: 1,
      },
    },
    {
      id: 'numbers',
      component: Inputs.MultipleInput,
      fieldProps: { id: 'number' },
    },
  ];

  const CREATE_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const UPDATE_MODAL = {
    api: ApiService[pageKey].update,
    fields: modalFields,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read,
    columns: [
      {
        id: 'number',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'sendDate',
        type: 'date',
        component: Columns.Text,
      },
      {
        id: 'weight',
        type: 'number',
        component: Columns.Text,
      },
      {
        id: 'relation',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'smgsDate',
        type: 'date',
        component: Columns.Text,
      },
      {
        id: 'smgs',
        component: Columns.Boolean,
      },
      {
        id: 'firstWagon',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'secondWagon',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'moreExists',
        component: Columns.Boolean,
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'UPDATE' }],
        },
        component: Columns.Actions,
      },
    ],
    searchPanel: {
      fields: [
        {
          id: 'startWith',
          component: Inputs.Input,
          fieldProps: {
            style: { width: '7rem' },
          },
        },
        {
          id: 'filterNumber',
          component: Inputs.Input,
          fieldProps: {
            style: { width: '7rem' },
          },
        },
        {
          id: 'filterShopId',
          component: Inputs.Select,
          fieldProps: {
            style: { width: '8rem' },
            normalizer: INPUT_NORMALIZERS.selectValues,
            api: ApiService[PAGE_KEYS.CLIENTS].read,
            apiById: ApiService[PAGE_KEYS.CLIENTS].readById,
            includeAllOption: true,
            defaultValue: '',
          },
        },
        {
          id: 'filterOrderId',
          component: Inputs.Select,
          fieldProps: {
            style: { width: '8rem' },
            normalizer: INPUT_NORMALIZERS.selectValues,
            api: ApiService[PAGE_KEYS.CLIENTS].readById,
            apiById: ApiService[PAGE_KEYS.ORDERS].readById,
            customOptionKey: 'orders',
            customSourceKey: 'filterShopId',
            includeAllOption: true,
            defaultValue: '',
          },
        },
        {
          id: 'filterNumber1520',
          component: Inputs.Input,
          fieldProps: {
            style: { width: '8rem' },
          },
        },
        {
          id: 'filterSendDateFrom',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '8rem' },
          },
        },
        {
          id: 'filterSendDateTo',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '8rem' },
          },
        },
        {
          id: 'isReload',
          component: Inputs.Select,
          fieldProps: {
            style: { width: '8rem' },
            normalizer: INPUT_NORMALIZERS.selectValues,
            options: [
              { value: true, label: i18n.t(`transshipment.fields.showUntranslated`) },
              { value: false, label: i18n.t(`transshipment.fields.showTranslated`) },
            ],
            defaultValue: '',
            includeAllOption: true,
          },
        },
      ],
    },
    actionPanel: ActionPanel,
  };

  const schema = {
    CREATE: CREATE_MODAL,
    UPDATE: UPDATE_MODAL,
    READ: TABLE_SCHEMA,
    overrides: {
      normalizeBuilderData,
      headerActions: HeaderActions,
      normalizeApiData,
      customModal: { modal: TransshipmentModal, modes: ['UPDATE'] },
      queryAddCondition: (query) => query.filterOrderId && query.filterShopId,
    },
    validationSchema: transshipmentSchema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
