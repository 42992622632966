/* eslint-disable no-magic-numbers */
import { Button, Divider, Form, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Inputs } from '@features/builder';
import { useTranslation } from '@features/localization';
import { Schedules } from '@features/clients/components/NotificationsModal/Schedules';
import { PAGE_KEYS } from '@constants';

export function ConfigurationForm({
  data,
  onSubmit,
  formData,
  setFormData,
  timeData,
  setTimeData,
}) {
  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    onSubmit(formData, timeData);
  }, [formData, timeData]);

  const handleChange = useCallback(
    (id, value) => {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    },
    [setFormData]
  );

  function renderInput(id) {
    const inputProps = { id, data: formData, onChange: handleChange, value: formData[id] };

    switch (id) {
      case 3:
        return <Inputs.Input {...inputProps} />;
      case 4:
        return <Inputs.Date {...inputProps} fieldProps={{ allowClear: false }} />;
      default:
        return <Inputs.Input {...inputProps} fieldProps={{ type: 'number', min: 1 }} />;
    }
  }

  return (
    <div
      style={{
        maxWidth: '45rem',
        backgroundColor: '#ffff',
        padding: '1rem',
        borderRadius: '0.5rem',
      }}
    >
      <Typography.Title level={2}>{t(`configuration.title`)}</Typography.Title>
      <Divider />
      <form autoComplete='off'>
        {data.map(({ id, description }) => (
          <Form.Item label={description} key={id}>
            {renderInput(id)}
          </Form.Item>
        ))}
        <Schedules data={timeData} setData={setTimeData} pageKey={PAGE_KEYS.CONFIGURATION} />
      </form>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type='primary' onClick={handleSubmit}>
          {t(`configuration.save`)}
        </Button>
      </div>
    </div>
  );
}

ConfigurationForm.propTypes = {
  data: PropTypes.array.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  timeData: PropTypes.array.isRequired,
  setTimeData: PropTypes.func.isRequired,
};
