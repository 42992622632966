import queryString from 'query-string';
import { Base } from '.';

export class Wagons extends Base {
  read = async (params) => {
    const result = await this.apiClient.get('/wagons', { query: { ...params } });

    return result;
  };

  readById = async ({ id }) => {
    const result = await this.apiClient.get(`/wagons/${id}`);

    return result;
  };

  readFcr = async (params) => {
    const result = await this.apiClient.get('/wagons/order/fcr', { query: { ...params } }, false);

    return result;
  };

  findByShopIdAndOrderNumber = async (params) => {
    const result = await this.apiClient.get('/wagons/order/find', { query: params });

    return result;
  };

  create = async (data) => {
    await this.apiClient.post('/wagons', { body: { ...data } });
  };

  update = async (data) => {
    await this.apiClient.put('/wagons', { body: { ...data } });
  };

  delete = async ({ id }) => {
    await this.apiClient.delete(`/wagons/${id}`);
  };

  deleteMany = async ({ ids }) => {
    const query = queryString.stringify({ ids });

    await this.apiClient.delete(`/wagons?${query}`);
  };

  exportXls = async (params) => {
    const result = await this.apiClient.getBlob(
      `/wagons/order/export${params ? `?${params}` : ''}`
    );

    return result;
  };
}
