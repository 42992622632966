/* eslint-disable max-lines-per-function */

import { PAGE_KEYS, SELECT_BOOL_OPTIONS } from '@constants';
import { ApiService } from '@services';
import { Builder, Inputs, Columns, SubmitButton } from '@features/builder';
import {
  normalize1435ApiData,
  normalize1435BuilderData,
  normalizePrefill1435Data,
  tracking1435Schema,
} from '@features/tracking';

export function Tracking1435Page() {
  const pageKey = PAGE_KEYS.TRACKING;

  const modalFields = [
    {
      id: 'wagonNumber',
      component: Inputs.Input,
    },
    {
      id: 'trackingDate',
      required: true,
      component: Inputs.Date,
      fieldProps: {
        showDateTime: true,
        style: { width: '100%' },
      },
    },
    {
      id: 'trackingStation',
      required: true,
      component: Inputs.AutoCompleteInput,
      fieldProps: {
        api: ApiService[PAGE_KEYS.STATIONS].read,
      },
    },
    {
      id: 'trackingNumber',
      component: Inputs.Input,
    },
    {
      id: 'trackingMessage',
      component: Inputs.Input,
    },
    {
      id: 'tracking',
      component: Inputs.Input,
    },
    {
      id: 'isEmpty',
      component: Inputs.Select,
      fieldProps: {
        options: SELECT_BOOL_OPTIONS,
        defaultValue: false,
      },
    },
    {
      id: 'stationToAdministration',
      component: Inputs.Input,
    },
    {
      id: 'stationToNumber',
      component: Inputs.Input,
    },
    {
      id: 'stationToName',
      component: Inputs.Input,
    },
    {
      id: 'wagonRegistrationNumber',
      component: Inputs.Input,
    },
    {
      id: 'wagonSendDate',
      component: Inputs.Date,
      required: true,
      fieldProps: {
        style: { width: '100%' },
      },
    },
    {
      id: 'wagonWeight',
      component: Inputs.Input,
      fieldProps: {
        type: 'number',
        min: 1,
        style: { width: '100%' },
      },
    },
    {
      id: 'wagonNhm',
      component: Inputs.Input,
    },
    {
      id: 'senderCode',
      component: Inputs.Input,
    },
    {
      id: 'senderName',
      component: Inputs.Input,
    },
    {
      id: 'stationFromAdministration',
      component: Inputs.Input,
    },
    {
      id: 'stationFromNumber',
      component: Inputs.Input,
    },
    {
      id: 'stationFromName',
      component: Inputs.Input,
    },
    {
      id: 'recipientCode',
      component: Inputs.Input,
    },
    {
      id: 'recipientName',
      component: Inputs.Input,
    },
    {
      id: 'stationBorderAdministration',
      component: Inputs.Input,
    },
    {
      id: 'stationBorderNumber',
      component: Inputs.Input,
    },
    {
      id: 'stationBorderName',
      component: Inputs.Input,
    },
    {
      id: 'recordDate',
      component: Inputs.Date,
      fieldProps: {
        showDateTime: true,
        style: { width: '100%' },
      },
    },
    {
      id: 'recordFrom',
      component: Inputs.Input,
      fieldProps: {
        type: 'number',
        min: 1,
        style: { width: '100%' },
      },
    },
    {
      id: 'trackingNote',
      component: Inputs.Input,
      fieldProps: { type: 'textarea' },
      style: { gridColumnEnd: 'span 3' },
    },
  ];

  const CREATE_MODAL = {
    api: ApiService[pageKey].create1435,
    fields: modalFields,
  };

  const COPY_MODAL = {
    api: ApiService[pageKey].create1435,
    fields: modalFields,
  };

  const UPDATE_MODAL = {
    api: ApiService[pageKey].update1435,
    fields: modalFields,
  };

  const DELETE_MODAL = {
    api: ApiService[pageKey].delete1435,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read1435,
    options: {
      sorter: true,
    },
    columns: [
      {
        id: 'wagonNumber',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'trackingMessage',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'trackingNumber',
        type: 'number',
        component: Columns.Text,
      },
      {
        id: 'trackingStation',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'trackingDate',
        type: 'date',
        component: Columns.Text,
      },
      {
        id: 'tracking',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'trackingNote',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'isEmpty',
        component: Columns.Boolean,
      },
      {
        id: 'stationFromAdministration',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationFromNumber',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationFromName',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationBorderAdministration',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationBorderNumber',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationBorderName',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationToAdministration',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationToNumber',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationToName',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'wagonWeight',
        type: 'number',
        component: Columns.Text,
      },
      {
        id: 'wagonNhm',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'wagonRegistrationNumber',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'wagonSendDate',
        type: 'date',
        component: Columns.Text,
      },
      {
        id: 'senderCode',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'senderName',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'recipientCode',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'recipientName',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'recordDate',
        type: 'date',
        component: Columns.Text,
      },
      {
        id: 'recordFrom',
        type: 'number',
        component: Columns.Text,
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'UPDATE' }, { type: 'DELETE' }, { type: 'COPY' }],
        },
        component: Columns.Actions,
      },
    ],
  };

  const schema = {
    CREATE: CREATE_MODAL,
    DELETE: DELETE_MODAL,
    COPY: COPY_MODAL,
    READ: TABLE_SCHEMA,
    UPDATE: UPDATE_MODAL,
    overrides: {
      normalizeBuilderData: normalize1435BuilderData,
      normalizeApiData: normalize1435ApiData,
      modalButtons: [
        { key: 'createNewMail', component: SubmitButton, value: true, modes: ['UPDATE'] },
      ],
      prefill: {
        api: ApiService[PAGE_KEYS.WAGONS].readById,
        normalize: normalizePrefill1435Data,
      },
    },
    validationSchema: tracking1435Schema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
