import { NOTIFICATION_TYPES } from '@constants';
import i18n from '@i18n';

export const getNotificationMessages = (pageKey) => {
  return {
    READ: {
      success: {
        type: NOTIFICATION_TYPES.SUCCESS,
        message: i18n.t('notifications:title.success'),
        description: i18n.t(`notifications:messages.READ.success`),
      },
      error: {
        type: NOTIFICATION_TYPES.ERROR,
        message: i18n.t('notifications:title.error'),
        description: i18n.t(`notifications:messages.READ.error`),
      },
    },
    CREATE: {
      success: {
        type: NOTIFICATION_TYPES.SUCCESS,
        message: i18n.t('notifications:title.success'),
        description: i18n.t(`notifications:messages.${pageKey}.CREATE.success`),
      },
      error: {
        type: NOTIFICATION_TYPES.ERROR,
        message: i18n.t('notifications:title.error'),
        description: i18n.t(`notifications:messages.${pageKey}.CREATE.error`),
      },
    },
    UPDATE: {
      success: {
        type: NOTIFICATION_TYPES.SUCCESS,
        message: i18n.t('notifications:title.success'),
        description: i18n.t(`notifications:messages.${pageKey}.UPDATE.success`),
      },
      error: {
        type: NOTIFICATION_TYPES.ERROR,
        message: i18n.t('notifications:title.error'),
        description: i18n.t(`notifications:messages.${pageKey}.UPDATE.error`),
      },
    },
    DELETE: {
      success: {
        type: NOTIFICATION_TYPES.SUCCESS,
        message: i18n.t('notifications:title.success'),
        description: i18n.t(`notifications:messages.${pageKey}.DELETE.success`),
      },
      error: {
        type: NOTIFICATION_TYPES.ERROR,
        message: i18n.t('notifications:title.error'),
        description: i18n.t(`notifications:messages.${pageKey}.DELETE.error`),
      },
    },
    FINISHED: {
      success: {
        type: NOTIFICATION_TYPES.SUCCESS,
        message: i18n.t('notifications:title.success'),
        description: i18n.t(`notifications:messages.${pageKey}.FINISHED.success`),
      },
      error: {
        type: NOTIFICATION_TYPES.ERROR,
        message: i18n.t('notifications:title.error'),
        description: i18n.t(`notifications:messages.${pageKey}.FINISHED.error`),
      },
    },
    ADD: {
      success: {
        type: NOTIFICATION_TYPES.SUCCESS,
        message: i18n.t('notifications:title.success'),
        description: i18n.t(`notifications:messages.${pageKey}.CREATE.success`),
      },
      error: {
        type: NOTIFICATION_TYPES.ERROR,
        message: i18n.t('notifications:title.error'),
        description: i18n.t(`notifications:messages.${pageKey}.CREATE.error`),
      },
    },
  };
};
