export const INPUT_NORMALIZERS = {
  selectAll: ({ values, options }) => {
    let selectedValues = values;

    const selectAllOption = values.includes('');

    if (selectAllOption) {
      const optionValues = options.map(({ value }) => value);

      const shouldClear = optionValues.every((value) => selectedValues.includes(value));

      selectedValues = optionValues
        .concat(selectedValues)
        .filter((value, index, self) => value !== '' && self.indexOf(value) === index);

      if (shouldClear) {
        selectedValues = selectedValues.filter((value) => !optionValues.includes(value));
      }
    }

    return selectedValues;
  },
  selectPermission: ({ values, selectedValue }) => {
    if (!selectedValue.length) return values;

    const [prefix, action] = selectedValue.at(-1).value.split('_');

    if (action === 'write') {
      const readPermission = `${prefix}_read`;

      if (!values.includes(readPermission)) {
        values.push(readPermission);
      }
    }

    return values;
  },
  treeSelect: ({ data, id, options, treeExtra }) => {
    const { triggerValue: curValue } = treeExtra;
    const curValues = data[id] || [];

    let updatedValues;

    // Check if selected node is child
    const isChild = isNaN(curValue);

    // Child was clicked
    if (isChild) {
      const parent = +curValue.split('-')[0];

      // Current child already selected
      if (curValues.includes(curValue)) {
        updatedValues = curValues.filter((v) => v !== curValue);

        // Check if any child of current parent still present in selected options, if not remove parent
        const stillHasChildren = updatedValues.find((v) => isNaN(v) && v.includes(String(parent)));

        return stillHasChildren ? updatedValues : updatedValues.filter((v) => v !== parent);
      }

      // The current child is not selected, check if the parent is selected, if so, just add the child
      // otherwise add both child and parent
      return curValues.includes(parent)
        ? [...curValues, curValue]
        : [...curValues, parent, curValue];
    }

    // Parent was clicked
    // Remove parent and all it's children
    if (curValues.includes(curValue)) {
      updatedValues = curValues.filter((v) => !String(v).includes(String(curValue)));

      return updatedValues;
    }

    // Add parent and all it's children
    const parentOption = options.find(({ value }) => value === curValue)?.children || [];

    updatedValues = parentOption.map(({ value }) => value);

    return [curValue, ...updatedValues, ...curValues];
  },
  selectValues: ({ values }) => values,
  selectValue: ({ value }) => value,
};
