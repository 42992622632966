import { Button, Popover } from 'antd';
import PropTypes from 'prop-types';

export function PopoverView({ content, placement, children }) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        flexDirection: 'column',
        marginTop: '0.2rem',
      }}
    >
      <Popover placement={placement} content={content}>
        <Button type='primary'>{children}</Button>
      </Popover>
    </div>
  );
}

PopoverView.propTypes = {
  content: PropTypes.object.isRequired,
  children: PropTypes.string.isRequired,
  placement: PropTypes.string,
};

PopoverView.defaultProps = {
  placement: 'bottom',
};
