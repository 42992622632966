/* eslint-disable max-lines-per-function */
import { PAGE_KEYS, SELECT_BOOL_OPTIONS } from '@constants';
import { Builder, Columns, Inputs } from '@features/builder';
import { ApiService } from '@services';
import {
  ClientCard,
  normalizeBuilderData,
  normalizeApiData,
  OrdersModal,
  ordersSchema,
} from '@features/orders';

export function OrdersPage() {
  const pageKey = PAGE_KEYS.ORDERS;

  const modalFields = [
    {
      id: 'sessionAbbreviation',
      required: true,
      component: Inputs.Input,
      style: {
        fontWeight: 'bold',
      },
      fieldProps: {
        style: { fontWeight: 'bold' },
      },
    },
    {
      id: 'logispedSection',
      style: {
        color: '#335d8f',
        fontWeight: 'bold',
      },
    },
    {
      id: 'logispedSentCondition',
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.INCONTERMS].read,
        selectApiRecord: (record) => ({ value: record.id, label: record.short }),
      },
    },
    {
      id: 'stationFrom',
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.STATIONS].read,
      },
    },
    {
      id: 'logispedDeliveryCondition',
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.INCONTERMS].read,
        selectApiRecord: (record) => ({ value: record.id, label: record.short }),
      },
    },
    {
      id: 'stationTo',
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.STATIONS].read,
      },
    },
    {
      id: 'currencyOfValueOfGoods',
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.CURRENCY].read,
        apiById: ApiService[PAGE_KEYS.CURRENCY].readById,
        selectApiRecord: (record) => ({ value: record.short, label: record.name }),
      },
    },
    {
      id: 'maxTransportationTime',
      component: Inputs.Input,
      fieldProps: {
        type: 'number',
        min: 0,
        style: { width: '100%' },
      },
    },
    {
      id: 'dateSMGS',
      component: Inputs.Select,
      fieldProps: {
        options: SELECT_BOOL_OPTIONS,
        defaultValue: false,
      },
    },
    {
      id: 'sessionSection',
      style: {
        color: '#335d8f',
        fontWeight: 'bold',
        marginTop: '4.13rem',
      },
    },
    {
      id: 'sessionSentCondition',
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.INCONTERMS].read,
        selectApiRecord: (record) => ({ value: record.id, label: record.short }),
      },
    },
    {
      id: 'orderStationFrom',
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.STATIONS].read,
      },
    },
    {
      id: 'sessionDeliveryCondition',
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.INCONTERMS].read,
        selectApiRecord: (record) => ({ value: record.id, label: record.short }),
      },
    },
    {
      id: 'orderStationTo',
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.STATIONS].read,
      },
    },
    {
      id: 'note',
      component: Inputs.Input,
      fieldProps: { type: 'textarea' },
    },
    {
      id: 'display',
      component: Inputs.Select,
      fieldProps: {
        options: SELECT_BOOL_OPTIONS,
        defaultValue: false,
        style: {
          marginBottom: '2rem',
        },
      },
    },
    {
      id: 'goods',
      component: Inputs.Input,
      style: {
        marginTop: '6.25rem',
      },
    },
    {
      id: 'nhm',
      component: Inputs.Input,
    },
    {
      id: 'recipient',
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.ORGANIZATIONS].read,
      },
    },
    {
      id: 'presetTracking',
      component: Inputs.Select,
      fieldProps: {
        options: SELECT_BOOL_OPTIONS,
        defaultValue: false,
      },
    },
    {
      id: 'noteForNotification',
      component: Inputs.Input,
      fieldProps: { type: 'textarea' },
    },
    {
      id: 'prefixXLSNotification',
      required: true,
      component: Inputs.Input,
    },
  ];

  const CREATE_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
    heightModal: '38rem',
  };

  const UPDATE_MODAL = {
    api: ApiService[pageKey].update,
    fields: modalFields,
  };

  const COPY_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const DELETE_MODAL = {
    api: ApiService[pageKey].delete,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read,
    columns: [
      {
        id: 'sessionAbbreviation',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationFrom',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationTo',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'nhm',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'goods',
        type: 'string',
        width: 200,
        component: Columns.Text,
      },
      {
        id: 'orderStationFrom',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'orderStationTo',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'recipient',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [
            { type: 'UPDATE' },
            { type: 'DELETE' },
            { type: 'COPY' },
            { type: 'NOTIFICATIONS' },
          ],
        },
        component: Columns.Actions,
      },
    ],
  };

  const schema = {
    CREATE: CREATE_MODAL,
    READ: TABLE_SCHEMA,
    UPDATE: UPDATE_MODAL,
    DELETE: DELETE_MODAL,
    COPY: COPY_MODAL,
    overrides: {
      searchPanel: ClientCard,
      normalizeBuilderData,
      normalizeApiData,
      customModal: { modal: OrdersModal, modes: ['UPDATE', 'COPY'] },
    },
    validationSchema: ordersSchema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
