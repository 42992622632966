import { PAGE_KEYS } from '@constants';
import { Inputs, QUERY_FORMATTERS } from '@features/builder';
import { ApiService } from '@services';

export const COLUMNS = [
  {
    id: 'shop',
    type: 'string',
    width: 280,
    required: true,
    component: Inputs.Select,
    fieldProps: {
      api: ApiService[PAGE_KEYS.CLIENTS].read,
    },
  },
  {
    id: 'order',
    type: 'string',
    width: 280,
    required: true,
    component: Inputs.Select,
    fieldProps: {
      api: ApiService[PAGE_KEYS.CLIENTS].readById,
      customOptionKey: 'orders',
      customSourceKey: 'shop',
    },
  },
  {
    id: 'number',
    width: 150,
    type: 'number',
    required: true,
    component: Inputs.OptimizedInput,
  },
  {
    id: 'sendDate',
    width: 150,
    type: 'date',
    required: true,
    component: Inputs.Date,
    formatter: QUERY_FORMATTERS.date,
  },
  {
    id: 'weight',
    width: 100,
    type: 'number',
    component: Inputs.OptimizedInput,
    fieldProps: {
      type: 'number',
      min: 1,
    },
  },
  {
    id: 'quantity',
    width: 100,
    type: 'number',
    component: Inputs.OptimizedInput,
    fieldProps: {
      type: 'number',
      min: 1,
    },
  },
  {
    id: 'orderNumber',
    width: 150,
    type: 'string',
    component: Inputs.OptimizedInput,
  },
  {
    id: 'cim',
    width: 150,
    type: 'string',
    component: Inputs.OptimizedInput,
  },
  {
    id: 'note',
    width: 200,
    type: 'string',
    component: Inputs.OptimizedInput,
  },
  {
    id: 'fcrNumber',
    width: 200,
    type: 'string',
    component: Inputs.OptimizedInput,
  },
  {
    id: 'fcrRecipient',
    width: 300,
    type: 'string',
    component: Inputs.OptimizedInput,
    fieldProps: {
      type: 'textarea',
    },
  },
  {
    id: 'fcrMarks',
    width: 300,
    type: 'string',
    component: Inputs.OptimizedInput,
    fieldProps: {
      type: 'textarea',
    },
  },
  {
    id: 'fcrRemarks',
    width: 300,
    type: 'string',
    component: Inputs.OptimizedInput,
    fieldProps: {
      type: 'textarea',
    },
  },
  {
    id: 'fcrInstructions',
    width: 300,
    type: 'string',
    component: Inputs.OptimizedInput,
    fieldProps: {
      type: 'textarea',
    },
  },
];
