import dayjs from 'dayjs';
import { PAGE_KEYS } from '@constants';
import { Builder, Columns, Inputs, QUERY_FORMATTERS } from '@features/builder';
import { normalizeBuilderData } from '@features/queueStatus';
import { ApiService } from '@services';

export function QueueStatusPage() {
  const pageKey = PAGE_KEYS.QUEUE_STATUS;

  const TABLE_SCHEME = {
    api: ApiService[PAGE_KEYS.QUEUE].status,
    options: {
      transferredColumns: false,
      defaultRemovedColumns: ['emailId', 'wagonId', 'typeId'],
    },
    columns: [
      {
        id: 'status',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'sendDate',
        type: 'date',
        component: Columns.Text,
      },
      {
        id: 'emailId',
        component: Columns.Text,
        type: 'string',
        removable: true,
      },
      {
        id: 'errorMessage',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'number',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'wagonId',
        component: Columns.Text,
        type: 'number',
        removable: true,
      },
      {
        id: 'wagonSendDate',
        component: Columns.Text,
        type: 'date',
      },
      {
        id: 'typeId',
        component: Columns.Text,
        type: 'number',
        removable: true,
      },
      {
        id: 'titleSk',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'titleEn',
        component: Columns.Text,
        type: 'string',
      },
      {
        id: 'emails',
        width: 250,
        component: Columns.MultilineText,
        type: 'string',
      },
    ],
    searchPanel: {
      fields: [
        {
          id: 'date',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '8rem' },
            defaultValue: dayjs(),
          },
        },
      ],
    },
  };

  const schema = {
    READ: TABLE_SCHEME,
    overrides: {
      normalizeBuilderData,
    },
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
