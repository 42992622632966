/* eslint-disable max-lines-per-function */

import { PAGE_KEYS } from '@constants';
import { Builder, Columns, Inputs } from '@features/builder';
import { ApiService } from '@services';
import { mediaSchema, normalizeBuilderData } from '@features/media';

export function MediaLibraryPage() {
  const pageKey = PAGE_KEYS.MEDIA_LIBRARY;

  const CREATE_MODAL = {
    api: ApiService[pageKey].create,
    fields: [
      {
        id: 'media',
        component: Inputs.Upload,
      },
      {
        id: 'note',
        component: Inputs.Input,
        fieldProps: { type: 'textarea' },
      },
    ],
  };

  const DELETE_MODAL = {
    api: ApiService[pageKey].delete,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read,
    columns: [
      {
        id: 'mimetype',
        type: 'string',
        // width: 450,
        component: Columns.MediaViewer,
        columnProps: {
          link: {
            key: 'id',
          },
        },
      },
      {
        id: 'filesize',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'note',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'DELETE' }],
        },
        component: Columns.Actions,
      },
    ],
  };

  const schema = {
    CREATE: CREATE_MODAL,
    READ: TABLE_SCHEMA,
    DELETE: DELETE_MODAL,
    overrides: {
      normalizeBuilderData,
    },
    validationSchema: mediaSchema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
