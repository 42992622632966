export function normalizeApiData(data) {
  const { shopIds: shopData } = data;

  const shopIds = shopData?.filter(Number.isInteger);
  const orderIds = shopData
    ?.filter((item) => typeof item === 'string' && item.includes('-'))
    .map((item) => item.split('-')[1])
    .map(Number);

  return {
    ...data,
    orderIds,
    shopIds,
    roleId: data.roleId?.value,
  };
}
