import { Base } from '.';

export class User extends Base {
  login = async ({ login, password }) => {
    const { token } = await this.apiClient.post('/auth/login', { body: { login, password } });

    return token;
  };

  getCurrentUser = async () => {
    const user = await this.apiClient.get('/user/current');

    return user;
  };

  refreshToken = async () => {
    const { token } = await this.apiClient.post('/auth/refresh');

    return token;
  };

  logout = async () => {};
}
