/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from '@features/localization';
import { Inputs } from '..';

// eslint-disable-next-line max-lines-per-function
export function MultipleInput({
  id,
  onChange,
  fieldProps,
  data,
  errors,
  handleChangeError,
  pageKey,
  fieldKey,
}) {
  const { t } = useTranslation();

  const { id: componentId, ...inputProps } = fieldProps;

  useEffect(() => {
    if (!data[id] || !data[id].length) {
      const newData = [{ fieldId: 0, [componentId]: '' }];

      onChange(id, newData, fieldKey);
    }
  }, [data, id, fieldKey, onChange]);

  const handleChange = useCallback(
    (fieldName, fieldValue, key) => {
      const currentData = data[id] || [];

      const rowData = currentData?.find(({ fieldId }) => fieldId === key);

      if (!currentData?.length || !rowData) {
        const newData = [...currentData, { fieldId: key, [fieldName]: fieldValue }];

        onChange(id, newData, fieldKey);

        return;
      }

      const newData = currentData.map((rowData) =>
        rowData.fieldId === key ? { ...rowData, [fieldName]: fieldValue } : rowData
      );

      onChange(id, newData, fieldKey);
    },
    [id, data, onChange, fieldKey]
  );

  const handleAdd = useCallback(() => {
    const fieldId = data[id].length;

    const newData = [...data[id], { fieldId, [componentId]: '' }];

    onChange(id, newData, fieldKey);
  }, [data, fieldKey, onChange]);

  const handleRemove = useCallback(
    (key) => {
      const newData = data[id]
        ?.filter(({ fieldId }) => fieldId !== key)
        .map((fieldValue, index) => ({ ...fieldValue, fieldId: index }));

      onChange(id, newData, fieldKey);

      const newErrors = errors[id]
        ?.filter(({ fieldId }) => fieldId !== key)
        .map(({ fieldId, ...rest }) => ({
          ...rest,
          fieldId: fieldId > key ? fieldId - 1 : fieldId,
        }));

      handleChangeError(id, newErrors, fieldKey);
    },

    [data, errors, fieldKey, onChange]
  );

  return (
    <div>
      {data[id]?.map((_, index) => {
        const translatedText = t(`${pageKey}.fields.${componentId}`);
        const labelText = `${translatedText} ${index + 1}`;

        const error = Array.isArray(errors[id])
          ? errors[id]?.find(({ fieldId }) => fieldId === index)
          : {};

        const rowData = data[id]?.find(({ fieldId }) => fieldId === index) || {};

        return (
          // eslint-disable-next-line react/no-array-index-key
          <div style={{ display: 'flex', width: '100%' }} key={index}>
            <Form.Item
              validateStatus={error && 'error'}
              style={{ width: '100%', marginBottom: '2.1rem' }}
            >
              <Inputs.Input
                id={componentId}
                data={rowData}
                value={rowData[componentId]}
                placeholder={labelText}
                onChange={handleChange}
                fieldKey={index}
                errors={error}
                {...inputProps}
                disabledValidation
              />
            </Form.Item>
            {data[id]?.length > 1 && (
              <Button
                type='link'
                danger
                onClick={() => handleRemove(index)}
                icon={<MinusCircleOutlined />}
              />
            )}
          </div>
        );
      })}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type='dashed' onClick={handleAdd}>
          <PlusOutlined /> {t(`modal.addField`)}
        </Button>
      </div>
    </div>
  );
}

MultipleInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  fieldProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleChangeError: PropTypes.func,
  pageKey: PropTypes.string.isRequired,
  input: PropTypes.func,
  fieldKey: PropTypes.number,
};

MultipleInput.defaultProps = {
  placeholder: '',
  disabled: false,
  required: false,
  fieldProps: {},
  errors: {},
  handleChangeError: undefined,
  input: undefined,
  fieldKey: undefined,
};
