import { ROUTES } from '@features/navigation';
import { permissionsSchema } from '../constants';

export const getAllowedRoute = (permissionsList) => {
  const allowedPermissionKey = Object.keys(permissionsList).find(
    (key) => permissionsList[key] !== 'none'
  );

  const allowedPage = Object.keys(permissionsSchema).find(
    (page) => permissionsSchema[page].key === allowedPermissionKey
  );

  // TODO: Find a way to handle lack of permissions
  if (!allowedPage) {
    return '/404';
  }

  const allowedRoute = ROUTES[allowedPage.toUpperCase()];

  return allowedRoute.path;
};
