/* eslint-disable max-lines-per-function */
import { MailOutlined } from '@ant-design/icons';
import { PAGE_KEYS, SELECT_BOOL_OPTIONS, SELECT_HIDDEN_OPTIONS } from '@constants';
import { Builder, Columns, Inputs, INPUT_NORMALIZERS } from '@features/builder';
import { ApiService } from '@services';
import { ROUTES } from '@features/navigation';
import {
  normalizeBuilderData,
  normalizeApiData,
  getFilterFieldsOptions,
  NotificationsModal,
  clientsSchema,
} from '@features/clients';

export function ClientsPage() {
  const pageKey = PAGE_KEYS.CLIENTS;

  const modalFields = [
    {
      id: 'name',
      required: true,
      component: Inputs.Input,
    },
    {
      id: 'hidden',
      component: Inputs.Select,
      fieldProps: {
        options: SELECT_BOOL_OPTIONS,
        defaultValue: true,
      },
    },
    {
      id: 'reload',
      component: Inputs.Select,
      fieldProps: {
        options: SELECT_BOOL_OPTIONS,
        defaultValue: true,
      },
    },

    // {
    //   id: 'numberOfItemsPerInvoice',
    //   required: true,
    //   component: Inputs.Input,
    // },
    {
      id: 'organization',
      required: true,
      component: Inputs.Select,
      fieldProps: {
        api: ApiService[PAGE_KEYS.ORGANIZATIONS].read,
      },
    },
    // {
    //   id: 'invoiceLanguage',
    //   required: true,
    //   component: Inputs.Select,
    //   fieldProps: {
    //     options: [
    //       {
    //         value: 'EN',
    //         label: 'English',
    //       },
    //       {
    //         value: 'SL',
    //         label: 'Slovak',
    //       },
    //     ],
    //
    //     allowClear: true,
    //     showSearch: true,
    //     showArrow: true,
    //   },
    // },
    // {
    //   id: 'mjForYield',
    //   required: true,
    //   component: Inputs.Input,
    // },
    // {
    //   id: 'sender',
    //   required: true,
    //   component: Inputs.Input,
    // },
    // {
    //   id: 'bankAccount',
    //   required: true,
    //   component: Inputs.Input,
    // },
    // {
    //   id: 'textForYield',
    //   required: true,
    //   component: Inputs.Input,
    // },
    // {
    //   id: 'currency',
    //   required: true,
    //   component: Inputs.Select,
    //   fieldProps: {
    //     options: [
    //       {
    //         value: 'CZK',
    //         label: 'CZK',
    //       },
    //       {
    //         value: 'USD',
    //         label: 'USD',
    //       },
    //       {
    //         value: 'EUR',
    //         label: 'EUR',
    //       },
    //     ],
    //
    //     allowClear: true,
    //     showSearch: true,
    //     showArrow: true,
    //   },
    // },
    // {
    //   id: 'maturity',
    //   required: true,
    //   component: Inputs.Input,
    // },
    // {
    //   id: 'mjForFreight',
    //   required: true,
    //   component: Inputs.Input,
    // },
    // {
    //   id: 'withTransshipment',
    //   component: Inputs.Select,
    //   fieldProps: {
    //     options: SELECT_BOOL_OPTIONS,
    //     defaultValue: false,
    //   },
    // },
    // {
    //   id: 'vatType',
    //   required: true,
    //   component: Inputs.Input,
    // },
    // {
    //   id: 'textForExcl',
    //   required: true,
    //   component: Inputs.Input,
    // },
    {
      id: 'note',
      component: Inputs.Input,
      fieldProps: { type: 'textarea' },
    },
    // {
    //   id: 'invoiceText',
    //   component: Inputs.Input,
    //   fieldProps: { type: 'textarea' },
    // },
  ];

  const CREATE_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const UPDATE_MODAL = {
    api: ApiService[pageKey].update,
    fields: modalFields,
  };

  const DELETE_MODAL = {
    api: ApiService[pageKey].delete,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read,
    options: {
      defaultRemovedColumns: ['orderStationTo', 'stationFrom', 'stationTo', 'nhm', 'goods'],
    },
    columns: [
      {
        id: 'name',
        type: 'string',
        // width: 250,
        component: Columns.Link,
        columnProps: {
          path: ROUTES.ORDERS.path,
          key: 'clientId',
        },
      },
      {
        id: 'organization',
        type: 'string',
        width: 250,
        component: Columns.Text,
        removable: true,
        columnProps: {
          buttons: [
            {
              id: 'wagons',
              path: ROUTES.WAGONS.path,
              params: [
                {
                  dataKey: 'clientId',
                  queryKey: 'filterShopId',
                },
              ],
            },
            {
              id: 'transshipment',
              path: ROUTES.TRANSSHIPMENT.path,
              showCondition: (record) => !!record.reload,
              params: [
                {
                  dataKey: 'clientId',
                  queryKey: 'filterShopId',
                },
              ],
            },
            {
              id: 'tracking',
              path: ROUTES.TRACKING.path,
              params: [
                {
                  dataKey: 'clientId',
                  queryKey: 'filterShopId',
                },
              ],
            },
          ],
        },
      },
      {
        id: 'sessionAbbreviation',
        type: 'string',
        width: 350,
        component: Columns.Link,
        columnProps: {
          path: ROUTES.ORDERS.path,
          key: 'clientId',
          buttons: [
            {
              id: 'wagons',
              path: ROUTES.WAGONS.path,
              params: [
                {
                  dataKey: 'clientId',
                  queryKey: 'filterShopId',
                },
                {
                  dataKey: 'orderId',
                  queryKey: 'filterOrderId',
                },
              ],
            },
            {
              id: 'tracking',
              path: ROUTES.TRACKING.path,
              params: [
                {
                  dataKey: 'clientId',
                  queryKey: 'filterShopId',
                },
                {
                  dataKey: 'orderId',
                  queryKey: 'filterOrderId',
                },
              ],
            },
          ],
        },
      },
      {
        id: 'sender',
        type: 'string',
        // width: 350,
        component: Columns.Text,
        removable: true,
      },
      {
        id: 'orderStationFrom',
        type: 'string',
        // width: 200,
        component: Columns.Text,
        removable: true,
      },
      {
        id: 'orderStationTo',
        type: 'string',
        // width: 450,
        component: Columns.Text,
        removable: true,
      },
      {
        id: 'stationFrom',
        type: 'string',
        // width: 450,
        component: Columns.Text,
        removable: true,
      },
      {
        id: 'stationTo',
        // width: 450,
        component: Columns.Text,
        type: 'date',
        removable: true,
      },
      {
        id: 'nhm',
        // width: 450,
        component: Columns.Text,
        type: 'number',
        removable: true,
      },
      {
        id: 'goods',
        width: 250,
        component: Columns.Text,
        type: 'number',
        removable: true,
      },
      {
        id: 'recipient',
        // width: 300,
        component: Columns.Text,
        type: 'number',
        removable: true,
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'UPDATE' }, { type: 'DELETE' }],
          childrenColumn: {
            component: Columns.Buttons,
            columnProps: {
              buttons: [
                {
                  id: 'notifications',
                  component: NotificationsModal,
                  icon: <MailOutlined />,
                },
              ],
            },
          },
        },
        component: Columns.Actions,
      },
    ],
    searchPanel: {
      fields: [
        {
          id: 'filterValue',
          component: Inputs.Input,
          fieldProps: {
            style: { width: '10rem' },
          },
        },
        {
          id: 'filterFields',
          component: Inputs.Select,
          fieldProps: {
            style: { minWidth: '8rem' },
            options: getFilterFieldsOptions(),
            includeAllOption: true,
            defaultValue: [''],
            allowClear: true,
            mode: 'multiple',
            maxTagCount: 2,
            normalizer: INPUT_NORMALIZERS.selectAll,
          },
        },
        {
          id: 'hidden',
          component: Inputs.Select,
          fieldProps: {
            style: { width: '6rem' },
            normalizer: INPUT_NORMALIZERS.selectValues,
            options: SELECT_HIDDEN_OPTIONS,
            includeAllOption: true,
            defaultValue: '',
          },
        },
      ],
    },
  };

  const schema = {
    CREATE: CREATE_MODAL,
    READ: TABLE_SCHEMA,
    UPDATE: UPDATE_MODAL,
    DELETE: DELETE_MODAL,
    overrides: { normalizeBuilderData, normalizeApiData },
    validationSchema: clientsSchema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
