import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { useCallback } from 'react';
import { ItemLabel } from '@components';
import { Inputs } from '@features/builder';
import { useTranslation } from '@features/localization';
import { ApiService } from '@services';
import { PAGE_KEYS } from '@constants';

export function Tracking1520({ data, errors, setData, pageKey }) {
  const { t } = useTranslation();

  const handleChange = useCallback((name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  return (
    <form
      autoComplete='off'
      style={{
        display: 'grid',
        gridTemplateRows: 'auto',
        gridTemplateColumns: 'repeat(auto-fit, minmax(12rem, 1fr))',
        gap: '0.5rem',
        maxHeight: '40.5rem',
      }}
    >
      <ItemLabel id={'number1520'} label={t(`${pageKey}.fields.number1520`)}>
        <Inputs.Input
          id={'number1520'}
          data={data}
          placeholder={t(`${pageKey}.fields.number1520`)}
          onChange={handleChange}
          errors={errors}
          value={data.number1520}
        />
      </ItemLabel>
      <ItemLabel id={'trackingDate'} label={t(`${pageKey}.fields.trackingDate`)}>
        <Inputs.Date
          id={'trackingDate'}
          data={data}
          placeholder={t(`${pageKey}.fields.trackingDate`)}
          errors={errors}
          fieldProps={{ style: { width: '100%' }, showDateTime: true }}
          onChange={handleChange}
          value={data.trackingDate}
          format='DD.MM.YYYY'
        />
      </ItemLabel>
      <ItemLabel id={'trackingStation'} label={t(`${pageKey}.fields.trackingStation`)}>
        <Inputs.AutoCompleteInput
          id={'trackingStation'}
          data={data}
          placeholder={t(`${pageKey}.fields.trackingStation`)}
          onChange={handleChange}
          errors={errors}
          value={data.trackingStation}
          fieldProps={{ api: ApiService[PAGE_KEYS.STATIONS].read }}
        />
      </ItemLabel>
      <Divider style={{ margin: '0.5rem 0' }} />
    </form>
  );
}

Tracking1520.propTypes = {
  data: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  pageKey: PropTypes.string.isRequired,
};
