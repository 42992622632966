import { PAGE_KEYS } from '@constants';
import { ApiService } from '@services';

export async function normalizeBuilderData(data) {
  const tableData = data.map(({ general, role }) => ({
    id: general.id,
    login: general.login,
    fullname: general.fullname,
    firm: general.firm,
    shopIds: general.orderIds,
    roleId: role.id,
  }));

  const { records: shops } = await ApiService[PAGE_KEYS.CLIENTS].read();

  const modalData = data.map(({ general, role }) => ({
    id: general.id,
    login: general.login,
    fullname: general.fullname,
    firm: general.firm,
    shopIds: [...general.shopIds, ...getOrders(general.orderIds, general.shopIds, shops)],
    roleId: { value: role.id, label: role.name },
  }));

  return { tableData, modalData };
}

const getOrders = (rawOrderIds, shopIds, shops) => {
  if (!rawOrderIds.length) {
    return [];
  }

  let propersOrderIds = [];

  shops.forEach(({ id, orders }) => {
    if (shopIds.includes(id)) {
      const allShopOrders = orders.map(({ id: orderId }) => orderId);

      const selectedShopOrders = allShopOrders
        .filter((v) => rawOrderIds.includes(v))
        .map((v) => `${id}-${v}`);

      propersOrderIds = [...propersOrderIds, ...selectedShopOrders];
    }
  });

  return propersOrderIds;
};
