import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useNavigate } from 'react-router';
import { ApiService } from '@services';
import { NOTIFICATION_TYPES, PAGE_KEYS } from '@constants';
import { showNotification } from '@lib';
import { getErrorNotificationMessage } from '@features/errors';
import { useTranslation } from '@features/localization';
import { ROUTES } from '@features/navigation';

export function ActionPanel({ selectedRows, setSelectedRows, refreshData }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleDeleteRows = useCallback(async () => {
    try {
      await ApiService[PAGE_KEYS.QUEUE].deleteMany({ ids: selectedRows });

      refreshData();

      setSelectedRows([]);

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        message: t('notifications:title.success'),
        description: t(`notifications:messages.${PAGE_KEYS.QUEUE}.DELETE_MANY.success`),
      });
    } catch (error) {
      const message = getErrorNotificationMessage(error.message);

      showNotification(
        message
          ? message
          : showNotification({
              type: NOTIFICATION_TYPES.ERROR,
              message: t('notifications:title.error'),
              description: t(`notifications:messages.${PAGE_KEYS.QUEUE}.DELETE_MANY.error`),
            })
      );
    }
  });

  const handleSendEmails = useCallback(async () => {
    try {
      await ApiService[PAGE_KEYS.QUEUE].sendEmails();

      refreshData();

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        message: t('notifications:title.success'),
        description: t(`notifications:messages.${PAGE_KEYS.QUEUE}.SEND_EMAILS.success`),
      });
    } catch (error) {
      const message = getErrorNotificationMessage(error.message);

      showNotification(
        message
          ? message
          : showNotification({
              type: NOTIFICATION_TYPES.ERROR,
              message: t('notifications:title.error'),
              description: t(`notifications:messages.${PAGE_KEYS.QUEUE}.SEND_EMAILS.error`),
            })
      );
    }
  });

  const handleOpenStatus = useCallback(() => {
    navigate(ROUTES.QUEUE_STATUS.path);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '1rem',
        marginBottom: '1rem',
        margin: '0.5rem',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
        <Button type='primary' danger disabled={!selectedRows.length} onClick={handleDeleteRows}>
          Delete
        </Button>
        <Button type='primary' onClick={handleSendEmails}>
          Send emails
        </Button>
      </div>
      <Button type='dashed' onClick={handleOpenStatus}>
        Status
      </Button>
    </div>
  );
}

ActionPanel.propTypes = {
  selectedRows: PropTypes.array.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
};

ActionPanel.defaultProps = {};
