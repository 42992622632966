/* eslint-disable max-lines-per-function */

import i18n from 'i18next';
import dayjs from 'dayjs';
import { PAGE_KEYS, SELECT_BOOL_OPTIONS } from '@constants';
import { Builder, Columns, INPUT_NORMALIZERS, Inputs, QUERY_FORMATTERS } from '@features/builder';
import { ApiService } from '@services';
import { normalize1435LogsBuilderData } from '@features/trackingLogs';

export function Tracking1435LogsPage() {
  const pageKey = PAGE_KEYS.TRACKING_LOGS;

  const sortFields = [
    {
      id: 'wagonNumber',
    },
    {
      id: 'trackingDate',
    },
    {
      id: 'trackingStation',
    },
    {
      id: 'trackingNumber',
    },
    {
      id: 'trackingMessage',
    },
    {
      id: 'tracking',
    },
    {
      id: 'isEmpty',
    },
    {
      id: 'stationToAdministration',
    },
    {
      id: 'stationToNumber',
    },
    {
      id: 'stationToName',
    },
    {
      id: 'wagonRegistrationNumber',
    },
    {
      id: 'wagonSendDate',
    },
    {
      id: 'wagonWeight',
    },
    {
      id: 'wagonNhm',
    },
    {
      id: 'senderCode',
    },
    {
      id: 'senderName',
    },
    {
      id: 'stationFromAdministration',
    },
    {
      id: 'stationFromNumber',
    },
    {
      id: 'stationFromName',
    },
    {
      id: 'recipientCode',
    },
    {
      id: 'recipientName',
    },
    {
      id: 'stationBorderAdministration',
    },
    {
      id: 'stationBorderNumber',
    },
    {
      id: 'stationBorderName',
    },
    {
      id: 'recordDate',
    },
    {
      id: 'recordFrom',
    },
    {
      id: 'trackingNote',
    },
  ];

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read1435Logs,
    options: {
      searchPanelWrapHandler: true,
    },
    columns: [
      {
        id: 'wagonNumber',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'trackingStation',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'recordDate',
        type: 'date',
        component: Columns.Text,
      },
      {
        id: 'trackingNumber',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'trackingDate',
        type: 'date',
        component: Columns.Text,
      },
      {
        id: 'tracking',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'wagonSendDate',
        type: 'date',
        component: Columns.Text,
      },
      {
        id: 'trackingMessage',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'trackingNote',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'isEmpty',
        component: Columns.Boolean,
      },
      {
        id: 'stationFromAdministration',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationFromNumber',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationFromName',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'wagonRegistrationNumber',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'wagonWeight',
        type: 'number',
        component: Columns.Text,
      },
      {
        id: 'wagonNhm',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'senderCode',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'senderName',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationToAdministration',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationToNumber',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationToName',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'recipientCode',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'recipientName',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationBorderAdministration',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationBorderNumber',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'stationBorderName',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'recordFrom',
        type: 'number',
        component: Columns.Text,
      },
    ],
    searchPanel: {
      fields: [
        {
          id: 'filterNumber',
          component: Inputs.Input,
        },
        {
          id: 'filterLastState',
          component: Inputs.Select,
          fieldProps: {
            normalizer: INPUT_NORMALIZERS.selectValues,
            options: SELECT_BOOL_OPTIONS,
            defaultValue: false,
          },
        },
        {
          id: 'filterIsEmpty',
          component: Inputs.Select,
          fieldProps: {
            normalizer: INPUT_NORMALIZERS.selectValues,
            options: SELECT_BOOL_OPTIONS,
            defaultValue: '',
          },
        },
        {
          id: 'filterSendDateFrom',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '100%' },
          },
        },
        {
          id: 'filterSendDateTo',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '100%' },
            defaultValue: dayjs(),
          },
        },
        {
          id: 'filterRegistrationNumber',
          component: Inputs.Input,
        },
        {
          id: 'filterNHM',
          component: Inputs.Input,
        },
        {
          id: 'filterSenderName',
          component: Inputs.Input,
        },
        {
          id: 'filterSenderCode',
          component: Inputs.Input,
        },
        {
          id: 'filterStationFromAdministration',
          component: Inputs.Input,
        },
        {
          id: 'filterStationFromNumber',
          component: Inputs.Input,
        },
        {
          id: 'filterStationFromName',
          component: Inputs.Input,
        },
        {
          id: 'filterTrackingDateFrom',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '100%' },
          },
        },
        {
          id: 'filterTrackingDateTo',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '100%' },
          },
        },
        {
          id: 'filterTrackingMessage',
          component: Inputs.Input,
        },
        {
          id: 'filterTrackingNumber',
          component: Inputs.Input,
        },
        {
          id: 'filterTrackingStation',
          component: Inputs.Input,
        },
        {
          id: 'filterTracking',
          component: Inputs.Input,
        },
        {
          id: 'filterRecipientName',
          component: Inputs.Input,
        },
        {
          id: 'filterRecipientCode',
          component: Inputs.Input,
        },
        {
          id: 'filterStationToAdministration',
          component: Inputs.Input,
        },
        {
          id: 'filterStationToNumber',
          component: Inputs.Input,
        },
        {
          id: 'filterStationToName',
          component: Inputs.Input,
        },
        {
          id: 'sortField',
          component: Inputs.Select,
          fieldProps: {
            normalizer: INPUT_NORMALIZERS.selectValues,
            options: sortFields.map(({ id }) => ({
              value: id,
              label: i18n.t(`tracking.fields.${id}`),
            })),
            includeAllOption: true,
            defaultValue: '',
          },
        },
        {
          id: 'sortDirection',
          component: Inputs.Select,
          fieldProps: {
            normalizer: INPUT_NORMALIZERS.selectValues,
            options: [
              { value: '', label: i18n.t(`wagons.fields.default`) },
              { value: 'asc', label: i18n.t(`wagons.fields.asc`) },
              { value: 'desc', label: i18n.t(`wagons.fields.desc`) },
            ],
            defaultValue: '',
          },
        },
      ],
    },
  };

  const schema = {
    READ: TABLE_SCHEMA,
    overrides: {
      normalizeBuilderData: normalize1435LogsBuilderData,
    },
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
