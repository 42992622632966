import { useCallback } from 'react';
import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Typography, Upload } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from '@features/localization';

export function Header({
  pageKey,
  file,
  showEmptyWagons,
  saveDisabled,
  onChangeShowEmptyWagons,
  onChangeFile,
  onSave,
}) {
  const { t } = useTranslation();

  const handleBeforeUpload = useCallback(() => false, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography.Title>{t(`layout.menu.${pageKey}`)}</Typography.Title>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <Checkbox checked={showEmptyWagons} onChange={onChangeShowEmptyWagons}>
            Show empty wagons
          </Checkbox>
          <Upload
            beforeUpload={handleBeforeUpload}
            fileList={file ? [file] : []}
            onChange={onChangeFile}
            maxCount={1}
          >
            <Button type='primary' icon={<UploadOutlined />}>
              {t(`modal.upload`)}
            </Button>
          </Upload>
        </div>
      </div>
      <div>
        <Button disabled={saveDisabled} type='primary' onClick={onSave} icon={<SaveOutlined />}>
          Save
        </Button>
      </div>
    </div>
  );
}

Header.propTypes = {
  pageKey: PropTypes.string.isRequired,
  file: PropTypes.any.isRequired,
  showEmptyWagons: PropTypes.bool.isRequired,
  saveDisabled: PropTypes.bool.isRequired,
  onChangeShowEmptyWagons: PropTypes.func.isRequired,
  onChangeFile: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
