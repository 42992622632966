/* eslint-disable max-lines-per-function */

import { PAGE_KEYS } from '@constants';
import { ApiService } from '@services';
import { Builder, Inputs, Columns, SubmitButton } from '@features/builder';
import {
  normalize1520ApiData,
  normalize1520BuilderData,
  tracking1520Schema,
} from '@features/tracking';

export function Tracking1520Page() {
  const pageKey = PAGE_KEYS.TRACKING;

  const modalFields = [
    {
      id: 'reloadWagonNumber',
      component: Inputs.Input,
    },
    {
      id: 'trackingDate',
      required: true,
      component: Inputs.Date,
      fieldProps: {
        showDateTime: true,
        style: { width: '100%' },
      },
    },
    {
      id: 'trackingStation',
      required: true,
      component: Inputs.Input,
    },
    {
      id: 'trackingNumber',
      component: Inputs.Input,
    },
    {
      id: 'arrivalDate',
      component: Inputs.Date,
      fieldProps: {
        style: { width: '100%' },
      },
    },
    {
      id: 'eta',
      component: Inputs.Input,
      fieldProps: {
        type: 'number',
        min: 0,
        style: { width: '100%' },
      },
    },
    {
      id: 'wagonStationFrom',
      component: Inputs.Input,
    },
    {
      id: 'wagonStationTo',
      component: Inputs.Input,
    },
    {
      id: 'wagonStationBorder',
      component: Inputs.Input,
    },
    {
      id: 'wagonStationDestionation',
      component: Inputs.Input,
    },
    {
      id: 'wagonGoods',
      component: Inputs.Input,
    },
    {
      id: 'inclusionDate',
      component: Inputs.Date,
      fieldProps: {
        style: { width: '100%' },
      },
    },
    {
      id: 'recordDate',
      component: Inputs.Date,
      fieldProps: {
        showDateTime: true,
        style: { width: '100%' },
      },
    },
  ];

  const CREATE_MODAL = {
    api: ApiService[pageKey].create1520,
    fields: modalFields,
  };

  const COPY_MODAL = {
    api: ApiService[pageKey].create1520,
    fields: modalFields,
  };

  const UPDATE_MODAL = {
    api: ApiService[pageKey].update1520,
    fields: modalFields,
  };

  const DELETE_MODAL = {
    api: ApiService[pageKey].delete1520,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read1520,
    options: {
      sorter: true,
    },
    columns: [
      {
        id: 'reloadWagonNumber',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'trackingStation',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'trackingNumber',
        type: 'number',
        component: Columns.Text,
      },
      {
        id: 'trackingDate',
        type: 'date',
        required: true,
        component: Columns.Text,
      },
      {
        id: 'wagonStationDestionation',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'arrivalDate',
        type: 'date',
        required: true,
        component: Columns.Text,
      },
      {
        id: 'eta',
        type: 'number',
        component: Columns.Text,
      },
      {
        id: 'inclusionDate',
        type: 'date',
        required: true,
        component: Columns.Text,
      },
      {
        id: 'wagonStationFrom',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'wagonStationTo',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'wagonStationBorder',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'wagonGoods',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'recordDate',
        type: 'date',
        required: true,
        component: Columns.Text,
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'UPDATE' }, { type: 'DELETE' }, { type: 'COPY' }],
        },
        component: Columns.Actions,
      },
    ],
  };

  const schema = {
    CREATE: CREATE_MODAL,
    DELETE: DELETE_MODAL,
    COPY: COPY_MODAL,
    READ: TABLE_SCHEMA,
    UPDATE: UPDATE_MODAL,
    overrides: {
      normalizeBuilderData: normalize1520BuilderData,
      normalizeApiData: normalize1520ApiData,
      modalButtons: [
        { key: 'createNewMail', component: SubmitButton, value: true, modes: ['UPDATE'] },
      ],
    },
    validationSchema: tracking1520Schema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
