import { Base } from '.';

export class Organizations extends Base {
  read = async (params) => {
    const result = await this.apiClient.get('/organizations', { query: { ...params } });

    return result;
  };

  create = async (data) => {
    await this.apiClient.post('/organizations', { body: { ...data } });
  };

  update = async (data) => {
    await this.apiClient.put('/organizations', { body: { ...data } });
  };

  delete = async ({ id }) => {
    await this.apiClient.delete(`/organizations/${id}`);
  };
}
