/* eslint-disable no-nested-ternary */
import { getProperDate } from '@lib';

export function normalizeApiData(data) {
  const reloads = data?.numbers.map(({ number }) => ({
    wagonId: Number(data.mainWagon?.value),
    sendDate: data.sendDate && getProperDate(data.sendDate),
    number,
    reloadDate: data.reloadDate && getProperDate(data.reloadDate),
    smgsDate: data.smgsDate && getProperDate(data.smgsDate),
    smgs: data.smgs,
    weight: data.weight,
    quantity: data.quantity,
    note: data?.note,
    isTracking: data?.isTracking?.hasOwnProperty('value') ? data.isTracking.value : data.isTracking,
    isStatistic: data?.isStatistic?.hasOwnProperty('value')
      ? data.isStatistic.value
      : data.isStatistic,
  }));

  return { reloads };
}
