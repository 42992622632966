import PropTypes from 'prop-types';
import { ButtonGroup } from '@components';

export function Buttons({ columnProps, record }) {
  return <ButtonGroup buttons={columnProps?.buttons} record={record} />;
}

Buttons.propTypes = {
  record: PropTypes.object,
  columnProps: PropTypes.object,
};

Buttons.defaultProps = {
  columnProps: {},
  record: {},
};
