/* eslint-disable max-lines-per-function */

import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';
import { PAGE_KEYS } from '@constants';
import { ApiService } from '@services';
import { Builder, Inputs, Columns, QUERY_FORMATTERS, INPUT_NORMALIZERS } from '@features/builder';
import { ROUTES } from '@features/navigation';
import {
  ActionPanel,
  AddTracking1520Modal,
  HeaderActions,
  normalizeBuilderData,
  normalizeSimpleApiData,
  tracking1435Schema,
} from '@features/tracking';

export function TrackingPage() {
  const pageKey = PAGE_KEYS.TRACKING;

  const modalFields = [
    {
      id: 'number',
      component: Inputs.Input,
    },
    {
      id: 'trackingDate',
      component: Inputs.Date,
      fieldProps: {
        showDateTime: true,
        style: { width: '100%' },
      },
    },
    {
      id: 'trackingStation',
      component: Inputs.AutoCompleteInput,
      fieldProps: {
        api: ApiService[PAGE_KEYS.STATIONS].read,
      },
    },
    {
      id: 'wagonSendDate',
      component: Inputs.Date,
      fieldProps: {
        style: { width: '100%' },
      },
    },
  ];

  const ADD_MODAL = {
    api: ApiService[pageKey].create1435Simple,
    fields: modalFields,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read,
    options: {
      rowSelection: true,
    },
    columns: [
      {
        id: 'number',
        secondaryId: 'number1520',
        type: 'number',
        component: Columns.Link,
        columnProps: {
          path: ROUTES.TRACKING1435.path,
          key: 'id',
          secondaryPath: ROUTES.TRACKING1520.path,
          secondaryKey: 'reloadId',
        },
      },
      {
        id: 'sendDate',
        secondaryId: 'reloadDate',
        type: 'date',
        component: Columns.Text,
      },

      {
        id: 'cim',
        secondaryId: 'smgs',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'weight',
        type: 'number',
        component: Columns.Text,
      },
      {
        id: 'quantity',
        type: 'number',
        component: Columns.Text,
      },
      {
        id: 'shop',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'order',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'trackingDate',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'ADD' }],
          childrenColumn: {
            component: Columns.Buttons,
            columnProps: {
              buttons: [
                {
                  id: 'add1520',
                  component: AddTracking1520Modal,
                  icon: <PlusOutlined />,
                },
              ],
            },
          },
        },
        component: Columns.Actions,
      },
      {
        id: 'trackingStation',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'smer',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'arrivalDate',
        type: 'string',
        component: Columns.Text,
      },
      {
        id: 'eta',
        type: 'string',
        component: Columns.Text,
      },
    ],
    searchPanel: {
      fields: [
        {
          id: 'startWith',
          component: Inputs.Input,
          fieldProps: {
            style: { width: '8rem' },
          },
        },
        {
          id: 'filterNumber',
          component: Inputs.Input,
          fieldProps: {
            style: { width: '8rem' },
          },
        },
        {
          id: 'filterNumber1520',
          component: Inputs.Input,
          fieldProps: {
            style: { width: '8rem' },
          },
        },
        {
          id: 'filterShopId',
          component: Inputs.Select,
          fieldProps: {
            style: { width: '8rem' },
            normalizer: INPUT_NORMALIZERS.selectValues,
            api: ApiService[PAGE_KEYS.CLIENTS].read,
            apiById: ApiService[PAGE_KEYS.CLIENTS].readById,
            includeAllOption: true,
            defaultValue: '',
          },
        },
        {
          id: 'filterOrderId',
          component: Inputs.Select,
          fieldProps: {
            style: { width: '8rem' },
            normalizer: INPUT_NORMALIZERS.selectValues,
            api: ApiService[PAGE_KEYS.CLIENTS].readById,
            apiById: ApiService[PAGE_KEYS.ORDERS].readById,
            customOptionKey: 'orders',
            customSourceKey: 'filterShopId',
            includeAllOption: true,
            defaultValue: '',
          },
        },
        {
          id: 'filterSendDateFrom',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '8rem' },
            defaultValue: dayjs().subtract(1, 'month'),
          },
        },
        {
          id: 'filterSendDateTo',
          component: Inputs.Date,
          formatter: QUERY_FORMATTERS.date,
          fieldProps: {
            style: { width: '8rem' },
            defaultValue: dayjs(),
          },
        },
      ],
    },
    actionPanel: ActionPanel,
  };

  const schema = {
    READ: TABLE_SCHEMA,
    CREATE: {},
    ADD: ADD_MODAL,
    defaultQuery: { filterSendDateFrom: dayjs().subtract(1, 'month'), filterSendDateTo: dayjs() },
    overrides: {
      normalizeBuilderData,
      normalizeApiData: normalizeSimpleApiData,
      headerActions: HeaderActions,
    },
    validationSchema: tracking1435Schema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
