/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox as ACheckbox, Form } from 'antd';

export function Checkbox({ id, placeholder, fieldProps, onChange, data, errors, disabled }) {
  const { defaultValue } = fieldProps;

  const value = data[id];

  const handleChange = useCallback(
    (event) => {
      onChange(id, event.target.checked);
    },
    [id]
  );

  useEffect(() => {
    if (defaultValue && !data.hasOwnProperty(id)) {
      onChange(id, defaultValue);
    }
  }, [id, fieldProps?.defaultValue]);

  return (
    <Form.Item help={errors[id]} validateStatus={errors[id] && 'error'} style={{ marginBottom: 0 }}>
      <ACheckbox
        defaultValue={fieldProps?.defaultValue}
        disabled={disabled}
        checked={value}
        placeholder={placeholder}
        onChange={handleChange}
        {...fieldProps}
      />
    </Form.Item>
  );
}

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  fieldProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  form: PropTypes.object,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  placeholder: '',
  fieldProps: {},
  errors: {},
  disabled: false,
  form: null,
};
