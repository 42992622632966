/* eslint-disable no-magic-numbers */
import * as yup from 'yup';
import { errorTexts } from '@features/errors';

export const rolesSchema = yup.object().shape({
  name: yup.string().required(errorTexts.required()),
  permissions: yup
    .array()
    .of(yup.string())
    .required(errorTexts.required())
    .min(1, errorTexts.required()),
});
