import { permissionsSchema } from '../constants';
import { checkPermission } from './checkPermission';

export function checkPresencePermisson(pathname, permissions) {
  const path = pathname.substring(1);

  const pathNames = path.split('/');

  const hasPermission = pathNames.every((key) => {
    const requiredPermission = permissionsSchema[key];

    return requiredPermission ? checkPermission(permissions, requiredPermission) : true;
  });

  return hasPermission;
}
