import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { BuilderModal } from '@features/builder';
import { ROUTES } from '@features/navigation';

export function HeaderActions({
  schema,
  pageKey,
  isLoading,
  refreshData,
  normalizer,
  validation,
  query,
}) {
  const navigate = useNavigate();

  const handleImportXLS = useCallback(() => {
    navigate(ROUTES.WAGONS_IMPORT.path);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        gap: 4,
        alignItems: 'center',
      }}
    >
      <Button type='primary' onClick={handleImportXLS}>
        Import XLS
      </Button>
      <BuilderModal
        mode='CREATE'
        pageKey={pageKey}
        isPageLoading={isLoading}
        schema={schema}
        refreshData={refreshData}
        normalizer={normalizer}
        validation={validation}
        query={query}
      />
    </div>
  );
}

HeaderActions.propTypes = {
  pageKey: PropTypes.string.isRequired,
  schema: PropTypes.shape({
    api: PropTypes.func,
    fields: PropTypes.array,
  }),
  isLoading: PropTypes.bool,
  refreshData: PropTypes.func.isRequired,
  normalizer: PropTypes.func,
  validation: PropTypes.object,
  query: PropTypes.object,
};

HeaderActions.defaultProps = {
  schema: null,
  isLoading: false,
  normalizer: undefined,
  validation: undefined,
  query: undefined,
};
