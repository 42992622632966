export function normalizeNotificationsApiData(orderId, data) {
  const normalizedData = data
    .map((entry) => ({
      position: entry.position,
      emails: entry.emails.filter(({ email }) => email).map(({ email }) => email),
      createNorm: entry.notification.includes('createNorm'),
      reloading: entry.notification.includes('reloading'),
      changeStation: entry.notification.includes('changeStation'),
      orgId: entry?.firm?.value,
    }))
    .filter(({ position }) => position);

  const apiData = {
    orderId: Number(orderId),
    data: normalizedData,
  };

  return apiData;
}
