import { getProperDate } from '@lib';

export function normalizeUpdateApiData(data) {
  const { shop, order, currency, wagontype, ...restData } = data;

  const normalizedData = {
    ...restData,
    id: Number(restData.id),
    currency: currency?.value,
    hidden: !restData.hidden?.value,
    isTracking: restData.isTracking?.value,
    price: restData?.price && Number(restData?.price),
    damagePrice: Number(restData.damagePrice),
    damageCurrency: restData?.damageCurrency?.value,
    shopId: shop?.value,
    orderId: order?.value,
    wagonTypeId: wagontype?.value,
    sendDate: restData?.sendDate && getProperDate(restData?.sendDate),
    damageDate: restData?.damageDate && getProperDate(restData?.damageDate),
    insuranceDate: restData?.insuranceDate && getProperDate(restData?.insuranceDate),
  };

  return normalizedData;
}
