/* eslint-disable max-lines-per-function */
import { useCallback, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from '@features/localization';
import { getNotificationMessages, showNotification } from '@lib';
import { ItemLabel } from '@components';
import { getErrorNotificationMessage, validate } from '@features/errors';
import { complexWagonsSchema, normalizeUpdateApiData } from '@features/wagons/';
import { UpdateFields } from './UpdateFields';

export function Update({
  defaultData,
  mode,
  fields,
  pageKey,
  refreshData,
  api,
  normalizer,
  handleCancel,
}) {
  const { t } = useTranslation();

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const normaliedFormData = useMemo(() => {
    return Object.fromEntries(
      Object.entries(defaultData).map(([key, value]) => {
        const formatter = fields?.find((field) => key === field.id)?.formatter;

        return [key, formatter ? formatter(value) : value];
      })
    );
  }, [defaultData, fields]);

  useEffect(() => {
    setData(normaliedFormData);
  }, [normaliedFormData]);

  const handleChange = useCallback((name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleSubmit = useCallback(async () => {
    const notificationMode = mode === 'COPY' ? 'CREATE' : mode;

    try {
      setIsLoading(true);

      const validationErrors = validate(complexWagonsSchema, data);

      if (validationErrors) {
        setErrors(validationErrors);

        return;
      }

      const normalizedData = mode === 'COPY' ? normalizer(data) : normalizeUpdateApiData(data);

      await api(normalizedData);

      showNotification(getNotificationMessages(pageKey)[notificationMode].success);

      setData({});
      refreshData();
      handleCancel();
    } catch (error) {
      console.warn(error);

      if (error.errorFields) {
        return;
      }
      if (error.serverValidation) {
        setErrors(error.serverValidation);

        return;
      }

      const message = getErrorNotificationMessage(error.message);

      showNotification(
        message ? message : getNotificationMessages(pageKey)[notificationMode].error
      );
    } finally {
      setIsLoading(false);
    }
  }, [data]);

  return (
    <>
      <form
        autoComplete='off'
        style={{
          maxHeight: '30rem',
          overflowY: 'auto',
          marginRight: '-1rem',
          paddingRight: '1rem',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateRows: 'auto',
            gridTemplateColumns: 'repeat(auto-fit, minmax(12rem, 1fr))',
            gap: '0.5rem',
            marginBottom: '1rem',
          }}
        >
          {fields.map(({ id, required, fieldProps, link, component: Component }) => {
            const labelText = t(`${pageKey}.fields.${id}`);

            const label = link ? <Link {...link}>{labelText}</Link> : labelText;

            return (
              <ItemLabel key={id} label={label} required={required}>
                <Component
                  id={id}
                  required={required}
                  placeholder={labelText}
                  data={data}
                  value={data[id]}
                  errors={errors}
                  fieldProps={fieldProps}
                  onChange={handleChange}
                  pageKey={pageKey}
                />
              </ItemLabel>
            );
          })}
        </div>
        <UpdateFields pageKey={pageKey} data={data} errors={errors} handleChange={handleChange} />
      </form>
      <Divider style={{ margin: '1rem 0' }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
        <Button disabled={isLoading} onClick={handleCancel}>
          {t(`modal.cancel`)}
        </Button>
        <Button loading={isLoading} type='primary' onClick={handleSubmit}>
          {t(`modal.save`)}
        </Button>
      </div>
    </>
  );
}

Update.propTypes = {
  mode: PropTypes.oneOf(['CREATE', 'UPDATE', 'COPY', 'DELETE']).isRequired,
  defaultData: PropTypes.object,
  fields: PropTypes.array.isRequired,
  pageKey: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  api: PropTypes.func.isRequired,
  normalizer: PropTypes.func,
};

Update.defaultProps = {
  defaultData: undefined,
  normalizer: undefined,
};
