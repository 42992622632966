/* eslint-disable max-lines-per-function */
import { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Checkbox, Divider, theme } from 'antd';
import { useTranslation } from '@features/localization';
import { getNotificationMessages, showNotification } from '@lib';
import { ItemLabel } from '@components';
import { normalizeFcrData } from '@features/wagons';
import { getErrorNotificationMessage } from '@features/errors';
import { Inputs } from '@features/builder';
import { ApiService } from '@services';
import { PAGE_KEYS } from '@constants';
import { FcrSelect } from './FcrSelect';

const { useToken } = theme;

export function Fcr({ defaultData, pageKey, refreshData, handleCancel }) {
  const { t } = useTranslation();
  const { token } = useToken();

  const [data, setData] = useState(defaultData);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = useCallback((name, value) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleCheck = useCallback(
    (event) => handleChange(event.target.id, event.target.checked),
    []
  );

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);

      const normalizedData = normalizeFcrData(data);

      await ApiService[PAGE_KEYS.WAGONS].update(normalizedData);

      showNotification(getNotificationMessages(pageKey).UPDATE.success);

      refreshData();
      handleCancel();
    } catch (error) {
      if (error.serverValidation) {
        setErrors(error.serverValidation);

        return;
      }

      const message = getErrorNotificationMessage(error.message);

      showNotification(message ? message : getNotificationMessages(pageKey).UPDATE.error);
    } finally {
      setIsLoading(false);
    }
  }, [data]);

  const commonProps = useMemo(
    () => ({ data, errors, onChange: handleChange }),
    [data, errors, handleChange]
  );

  return (
    <>
      <form
        autoComplete='off'
        style={{
          height: '23.5rem',
          maxHeight: '23.5rem',
          overflowY: 'auto',
          marginRight: '-1rem',
          paddingRight: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.25rem',
            marginBottom: '0.5rem',
          }}
        >
          <ItemLabel
            id='fcrNumber'
            label={t(`${pageKey}.fields.fcrNumber`)}
            style={{ color: token.colorPrimary }}
          >
            <Inputs.Input
              id={'fcrNumber'}
              placeholder={t(`${pageKey}.fields.fcrNumber`)}
              value={data.fcrNumber}
              {...commonProps}
            />
          </ItemLabel>
          <ItemLabel
            id='fcrRecipient'
            label={t(`${pageKey}.fields.fcrRecipient`)}
            style={{ color: token.colorPrimary }}
          >
            <Inputs.Input
              id={'fcrRecipient'}
              placeholder={t(`${pageKey}.fields.fcrRecipient`)}
              fieldProps={{ type: 'textarea' }}
              value={data.fcrRecipient}
              {...commonProps}
            />
          </ItemLabel>
          <ItemLabel
            id='fcrMarks'
            label={t(`${pageKey}.fields.fcrMarks`)}
            style={{ color: token.colorPrimary }}
          >
            <Inputs.Input
              id={'fcrMarks'}
              placeholder={t(`${pageKey}.fields.fcrMarks`)}
              fieldProps={{ type: 'textarea' }}
              value={data.fcrMarks}
              {...commonProps}
            />
          </ItemLabel>
          <ItemLabel
            id='fcrRemarks'
            label={t(`${pageKey}.fields.fcrRemarks`)}
            style={{ color: token.colorPrimary }}
          >
            <Inputs.Input
              id={'fcrRemarks'}
              placeholder={t(`${pageKey}.fields.fcrRemarks`)}
              fieldProps={{ type: 'textarea' }}
              value={data.fcrRemarks}
              {...commonProps}
            />
          </ItemLabel>
          <ItemLabel
            id='fcrInstructions'
            label={t(`${pageKey}.fields.fcrInstructions`)}
            style={{ color: token.colorPrimary }}
          >
            <Inputs.Input
              id={'fcrInstructions'}
              placeholder={t(`${pageKey}.fields.fcrInstructions`)}
              fieldProps={{ type: 'textarea' }}
              value={data.fcrInstructions}
              {...commonProps}
            />
          </ItemLabel>
          <div style={{ display: 'flex' }}>
            <Checkbox id='fcrFlag1' checked={data.fcrFlag1} onChange={handleCheck}>
              at the disposal of the consignee
            </Checkbox>
            <Checkbox id='fcrFlag2' checked={data.fcrFlag2} onChange={handleCheck}>
              at the forwarded of the consignee
            </Checkbox>
          </div>
        </div>
        <FcrSelect
          wagonId={data.id}
          pageKey={pageKey}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </form>
      <Divider style={{ margin: '1rem 0' }} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
        <Button disabled={isLoading} onClick={handleCancel}>
          {t(`modal.cancel`)}
        </Button>
        <Button loading={isLoading} type='primary' onClick={handleSubmit}>
          {t(`modal.save`)}
        </Button>
      </div>
    </>
  );
}

Fcr.propTypes = {
  defaultData: PropTypes.object.isRequired,
  pageKey: PropTypes.string.isRequired,
  refreshData: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
