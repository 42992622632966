import {
  AlertOutlined,
  BlockOutlined,
  BookOutlined,
  BoxPlotOutlined,
  CompassOutlined,
  ControlOutlined,
  FileImageOutlined,
  NodeIndexOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import { ROUTES } from './routes';

export const MENU_ICONS_BY_ROUTE = {
  [ROUTES.CLIENTS.path]: <ShoppingCartOutlined />,
  [ROUTES.WAGONS.path]: <BoxPlotOutlined />,
  [ROUTES.TRANSSHIPMENT.path]: <NodeIndexOutlined />,
  [ROUTES.TRACKING.path]: <CompassOutlined />,
  [ROUTES.QUEUE.path]: <BlockOutlined />,
  [ROUTES.TRACKING_LOGS.path]: <AlertOutlined />,
  [ROUTES.SETTINGS.path]: <SettingOutlined />,
  [ROUTES.SETTINGS.children.CATALOGS.path]: <BookOutlined />,
  [ROUTES.SETTINGS.children.ROLES.path]: <UsergroupAddOutlined />,
  [ROUTES.SETTINGS.children.MEDIA_LIBRARY.path]: <FileImageOutlined />,
  [ROUTES.SETTINGS.children.USERS.path]: <UserOutlined />,
  [ROUTES.SETTINGS.children.CONFIGURATION.path]: <ControlOutlined />,
};
