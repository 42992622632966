/* eslint-disable max-lines-per-function */

import { PAGE_KEYS } from '@constants';
import { Builder, Columns, Inputs } from '@features/builder';
import { ApiService } from '@services';
import { currencySchema, normalizeBuilderData } from '@features/currency';

export function CurrencyPage() {
  const pageKey = PAGE_KEYS.CURRENCY;

  const modalFields = [
    {
      id: 'short',
      required: true,
      component: Inputs.Input,
    },
    {
      id: 'name',
      required: true,
      component: Inputs.Input,
    },
  ];

  const CREATE_MODAL = {
    api: ApiService[pageKey].create,
    fields: modalFields,
  };

  const DELETE_MODAL = {
    api: ApiService[pageKey].delete,
  };

  const TABLE_SCHEMA = {
    api: ApiService[pageKey].read,
    columns: [
      {
        id: 'short',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'name',
        type: 'string',
        // width: 450,
        component: Columns.Text,
      },
      {
        id: 'actions',
        columnProps: {
          align: 'right',
          actions: [{ type: 'DELETE' }],
        },
        component: Columns.Actions,
      },
    ],
  };

  const schema = {
    CREATE: CREATE_MODAL,
    READ: TABLE_SCHEMA,
    DELETE: DELETE_MODAL,
    overrides: {
      normalizeBuilderData,
    },
    validationSchema: currencySchema,
  };

  return <Builder schema={schema} pageKey={pageKey} />;
}
