export const PAGE_KEYS = {
  CLIENTS: 'clients',
  ORDERS: 'orders',
  WAGONS: 'wagons',
  WAGONS_IMPORT: 'wagons_import',
  TRANSSHIPMENT: 'transshipment',
  TRANSSHIPMENT_IMPORT: 'transshipment_import',
  TRACKING: 'tracking',
  TRACKING1520_IMPORT: 'tracking_import',
  TRACKING_PLACE: 'tracking_place',
  TRACKING_LOGS: 'tracking_logs',
  TRACKING1435LOGS: 'tracking1435logs',
  TRACKING1520LOGS: 'tracking1520logs',
  QUEUE: 'queue',
  QUEUE_STATUS: 'queue_status',
  SETTINGS: 'settings',
  CURRENCY: 'currency',
  CATALOGS: 'catalogs',
  ORGANIZATIONS: 'organizations',
  INCONTERMS: 'inconterms',
  STATIONS: 'stations',
  COUNTRIES: 'countries',
  USERS: 'users',
  ROLES: 'roles',
  MEDIA_LIBRARY: 'media_library',
  CONFIGURATION: 'configuration',
  WAGONTYPE: 'wagontype',
};
