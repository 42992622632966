import { useCallback } from 'react';
import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Typography, Upload } from 'antd';
import PropTypes from 'prop-types';

import { useTranslation } from '@features/localization';

export function Header({ pageKey, file, saveDisabled, onChangeFile, onSave }) {
  const { t } = useTranslation();

  const handleBeforeUpload = useCallback(() => false, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography.Title>{t(`layout.menu.${pageKey}`)}</Typography.Title>
        <Upload
          beforeUpload={handleBeforeUpload}
          fileList={file ? [file] : []}
          onChange={onChangeFile}
          maxCount={1}
        >
          <Button type='primary' icon={<UploadOutlined />}>
            {t(`modal.upload`)}
          </Button>
        </Upload>
      </div>
      <div>
        <Button disabled={saveDisabled} type='primary' onClick={onSave} icon={<SaveOutlined />}>
          Save
        </Button>
      </div>
    </div>
  );
}

Header.propTypes = {
  pageKey: PropTypes.string.isRequired,
  file: PropTypes.any.isRequired,
  saveDisabled: PropTypes.bool.isRequired,
  onChangeFile: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
