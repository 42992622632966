import PropTypes from 'prop-types';

export function ServerErrorNotification({ property, value, error }) {
  return (
    <p>
      <strong>{`${property}-${value}`} </strong>
      {error}
    </p>
  );
}

ServerErrorNotification.propTypes = {
  property: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
};
