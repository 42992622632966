/* eslint-disable no-magic-numbers */
import * as yup from 'yup';
import { errorTexts } from '@features/errors';

export const wagonsSchema = yup.object().shape({
  shop: yup
    .object()
    .shape({
      value: yup.number().required(errorTexts.required()),
      label: yup.string().required(errorTexts.required()),
    })
    .required(errorTexts.required()),
  order: yup
    .object()
    .shape({
      value: yup.number().required(errorTexts.required()),
      label: yup.string().required(errorTexts.required()),
    })
    .required(errorTexts.required()),
  number: yup.string().required(errorTexts.required()),
  sendDate: yup.object().required(errorTexts.required()),
  weight: yup.number(errorTexts.formatNumber()).nullable(),
  quantity: yup.number(errorTexts.formatNumber()).nullable(),
  unit: yup.string().nullable(),
});
