import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  PlusOutlined,
} from '@ant-design/icons';

export const MODAL_ICONS_BY_MODE = {
  UPDATE: <EditOutlined />,
  COPY: <CopyOutlined />,
  DELETE: <DeleteOutlined />,
  NOTIFICATIONS: <MailOutlined />,
  ADD: <PlusOutlined />,
};
