export function getTableColData(data, id) {
  let colData;
  let isChildCol = false;

  data?.forEach((item) => {
    if (item.id === id) {
      colData = item;

      return;
    }

    if (!colData && item?.children && Array.isArray(item.children)) {
      const childRowData = item.children.find((child) => child.id === id);

      colData = childRowData;

      if (childRowData) isChildCol = true;
    }
  });

  return { colData, isChildCol };
}
