export function setMultipleValues(data, dataKey, accessKey, valueFormat, valueKey) {
  const multipleData = data[dataKey];

  if (!multipleData) {
    return [];
  }

  const multipleValues = multipleData.map((value) => {
    if (valueFormat) {
      return { ...valueFormat, [valueKey]: value[accessKey] };
    }

    return value[accessKey];
  });

  const normalizedValues = multipleValues.filter((value) =>
    valueFormat ? value[valueKey] : value
  );

  return normalizedValues;
}
