import React, { memo, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { getMenuItem, getOpenKeys, ROUTES } from '@features/navigation';
import { useCurrentLanguage } from '@features/localization';
import { userSelectors } from '@features/user';

export const CustomMenu = memo(() => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();
  const user = useSelector(userSelectors.userData);
  const permissions = user?.role?.permissions ?? [];

  const menuItems = useMemo(
    () => Object.keys(ROUTES).map((route) => getMenuItem(ROUTES, route, permissions)),
    [currentLanguage, permissions]
  );

  const handleNavigate = useCallback(({ key }) => {
    navigate(key, { replace: true });
  }, []);

  const openedKeys = useMemo(() => getOpenKeys(pathname), []);

  return (
    <Menu
      onClick={handleNavigate}
      theme='dark'
      mode='inline'
      selectedKeys={[pathname]}
      defaultOpenKeys={openedKeys}
      items={menuItems}
    />
  );
});

CustomMenu.displayName = 'CustomMenu';
