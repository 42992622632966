import { Base } from '.';

export class Stations extends Base {
  read = async (params) => {
    const result = await this.apiClient.get('/stations', { query: { ...params } });

    return result;
  };

  create = async (data) => {
    await this.apiClient.post('/stations', { body: { ...data } });
  };

  update = async (data) => {
    await this.apiClient.put('/stations', { body: { ...data } });
  };

  delete = async ({ id }) => {
    await this.apiClient.delete(`/stations/${id}`);
  };
}
