import dayjs from 'dayjs';

/* eslint-disable no-magic-numbers */
export function normalizeBuilderData(data) {
  const { records, count, page, total } = data;
  const meta = { count, page, total };

  const tableData = records.map((item) => {
    return {
      id: item.id,
      number: item.number,
      sendDate: item?.sendDate && dayjs(item.sendDate).format('DD.MM.YYYY'),
      reloadDate: item?.reloadDate && dayjs(item.reloadDate).format('DD.MM.YYYY'),
      weight: item.weight,
      relation: item?.order?.name,
      smgsDate: item?.smgsDate && dayjs(item.smgsDate).format('DD.MM.YYYY'),
      smgs: item.smgs,
      firstWagon: item.reloads[0]?.number,
      secondWagon: item.reloads[1]?.number,
      moreExists: item.reloads.length > 2,
    };
  });

  const modalData = records.map((item) => {
    return {
      id: item.id,
    };
  });

  return { tableData, modalData, meta };
}
