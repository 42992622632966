export function checkPermission(permissionsList, allowPermissions) {
  if (!permissionsList || !allowPermissions) return false;

  const isAdmin = permissionsList.adminPermissions !== 'none';

  if (isAdmin) {
    return true;
  }

  const permissionKey = allowPermissions.key;

  const permission = permissionsList[permissionKey];

  const hasPermission = permission !== allowPermissions.none;

  return hasPermission;
}
