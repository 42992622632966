/* eslint-disable no-magic-numbers */
import * as yup from 'yup';
import { errorTexts } from '@features/errors';

export const transshipmentSchema = yup.object().shape({
  mainWagon: yup.object().required(errorTexts.required()),
  reloadDate: yup.object().required(errorTexts.required()),
  numbers: yup.array().of(
    yup.object().shape({
      fieldId: yup.number().required(),
      number: yup.string().required(errorTexts.required()),
    })
  ),
});
