/* eslint-disable max-lines-per-function */

import { read, utils } from 'xlsx';
import { useCallback, useState } from 'react';
import { NOTIFICATION_TYPES, PAGE_KEYS } from '@constants';
import {
  COLUMNS,
  Header,
  normalizeApiData,
  parseXLSXData,
  Table,
  trackingImportSchema,
} from '@features/trackingImport';
import { ApiService } from '@services';
import { getErrorNotificationMessage, validate } from '@features/errors';
import { getNotificationMessages, showNotification } from '@lib';
import { useTranslation } from '@features/localization';
import { useConfirmLeave } from '@features/navigation';

export function Tracking1520ImportPage() {
  const pageKey = PAGE_KEYS.TRACKING1520_IMPORT;
  const { t } = useTranslation();

  const [file, setFile] = useState('');
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useConfirmLeave(file);

  const handleSelectRow = useCallback((selectedRowKeys) => {
    setSelectedRows(selectedRowKeys);
  }, []);

  const handleChangeError = useCallback((name, value) => {
    setErrors((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleChangeData = useCallback(async ({ name, value, index }) => {
    setData((prevData) =>
      prevData.map((item, dataIndex) => {
        if (index === dataIndex) {
          return { ...item, [name]: value };
        }

        return item;
      })
    );
  }, []);

  const handleReset = useCallback(() => {
    setData([]);
    setFile('');
    setErrors([]);
    setSelectedRows([]);
  }, []);

  const handleChangeFile = useCallback(async (newValue) => {
    if (!(newValue.file instanceof File)) return void handleReset();

    const buffer = await newValue.file.arrayBuffer();
    const wb = read(buffer);

    const jsonResult = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
      blankrows: false,
      raw: false,
      range: 10,
      header: 'A',
    });

    const { tableData } = parseXLSXData(jsonResult);

    setData(tableData);
    setFile(newValue.file);
    setErrors([]);
  }, []);

  const handleSave = useCallback(async () => {
    try {
      const validationErrors = validate(trackingImportSchema, data);

      if (validationErrors) return void setErrors(validationErrors);

      const selectedData = data.filter(({ id }) => selectedRows.includes(id));
      const normalizedPayload = normalizeApiData(selectedData);

      await ApiService[PAGE_KEYS.TRACKING].create1520Many(normalizedPayload);

      setData((prev) => prev.filter(({ id }) => !selectedRows.includes(id)));
      setErrors([]);
      setSelectedRows([]);

      showNotification(getNotificationMessages(pageKey).CREATE.success);
    } catch (error) {
      console.warn(error);

      if (error.errorFields) return;
      if (error.serverValidation) {
        const formatErrors = error.serverValidation.tracking1520;

        if (formatErrors) return void setErrors(formatErrors);

        const newErrors = [];
        const newData = [];

        Object.keys(error.serverValidation).forEach((errorKey) => {
          const [property, value] = errorKey.split('-');
          const errorMessage = error.serverValidation[errorKey];

          const currentItem = data.find((item) => item[property] === value);

          newData.push(currentItem);
          newErrors.push({ fieldId: currentItem.fieldId, [property]: errorMessage });
        });

        setErrors(newErrors);
        setData(newData);

        return;
      }

      const message = getErrorNotificationMessage(error.message);

      showNotification(
        message
          ? message
          : {
              type: NOTIFICATION_TYPES.ERROR,
              message: t('notifications:title.error'),
              description: t(`notifications:messages.${pageKey}.CREATE.error`),
            }
      );
    }
  }, [data, selectedRows]);

  return (
    <div>
      <Header
        pageKey={pageKey}
        file={file}
        saveDisabled={!data.length || !selectedRows.length}
        onChangeFile={handleChangeFile}
        onSave={handleSave}
      />
      <Table
        pageKey={pageKey}
        data={data}
        errors={errors}
        onSelectRow={handleSelectRow}
        onChangeError={handleChangeError}
        onChange={handleChangeData}
        columns={COLUMNS}
      />
    </div>
  );
}
