import { Base } from './Base';

export class WagonType extends Base {
  read = async () => {
    const result = await this.apiClient.get('/wagontype');

    return result;
  };

  create = async (data) => {
    await this.apiClient.post('/wagontype', { body: { ...data } });
  };

  update = async (data) => {
    await this.apiClient.put('/wagontype', { body: { ...data } });
  };

  delete = async ({ id }) => {
    await this.apiClient.delete(`/wagontype/${id}`);
  };
}
