import dayjs from 'dayjs';

export function normalizeSchedulesApiData(data) {
  const normalizedData = data
    .map(({ time }) => `${dayjs(time).format('HH:mm')}`)
    .filter((value) => value !== 'Invalid Date');

  const apiData = {
    schedules: normalizedData,
  };

  return apiData;
}
