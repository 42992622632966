import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import 'dayjs/locale/en';

export function getProperDate(rawDate) {
  dayjs.extend(utcPlugin);

  const isoString = `${rawDate.format('YYYY-MM-DD')}`;

  return isoString;
}
