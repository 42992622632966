import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from '@features/navigation';
import {
  CurrencyPage,
  ClientsPage,
  LoginPage,
  OrganizationsPage,
  IncontermsPage,
  CountriesPage,
  StationsPage,
  RolesPage,
  UsersPage,
  MediaLibraryPage,
  OrdersPage,
  ConfigurationPage,
  WagonsPage,
  WagonTypePage,
  TransshipmentPage,
  TrackingPage,
  TransshipmentImportPage,
  Tracking1435Page,
  Tracking1520Page,
  Tracking1520ImportPage,
  QueuePage,
  QueueStatusPage,
  Tracking1435LogsPage,
  Tracking1520LogsPage,
} from '@pages';
import { AuthenticatedLayout, UnAuthenticatedLayout } from '@components';
import { useAuth } from '@features/user';
import { WagonsImportPage } from '@pages/WagonsImportPage';
import { FcrPage } from '@pages/FcrPage';

function App() {
  useAuth();

  return (
    <Routes>
      <Route element={<AuthenticatedLayout />}>
        <Route path={ROUTES.CLIENTS.path} element={<ClientsPage />} />
        <Route path={ROUTES.ORDERS.path} element={<OrdersPage />} />
        <Route path={ROUTES.WAGONS.path} element={<WagonsPage />} />
        <Route path={ROUTES.WAGONS_IMPORT.path} element={<WagonsImportPage />} />
        <Route path={ROUTES.TRANSSHIPMENT.path} element={<TransshipmentPage />} />
        <Route path={ROUTES.TRANSSHIPMENT_IMPORT.path} element={<TransshipmentImportPage />} />
        <Route path={ROUTES.TRACKING.path} element={<TrackingPage />} />
        <Route path={ROUTES.QUEUE.path} element={<QueuePage />} />
        <Route path={ROUTES.QUEUE_STATUS.path} element={<QueueStatusPage />} />
        <Route path={ROUTES.TRACKING1435.path} element={<Tracking1435Page />} />
        <Route path={ROUTES.TRACKING1520.path} element={<Tracking1520Page />} />
        <Route path={ROUTES.TRACKING1520_IMPORT.path} element={<Tracking1520ImportPage />} />
        <Route path={ROUTES.TRACKING_LOGS.path}>
          <Route
            path={ROUTES.TRACKING_LOGS.children.TRACKING1435LOGS.path}
            element={<Tracking1435LogsPage />}
          />
          <Route
            path={ROUTES.TRACKING_LOGS.children.TRACKING1520LOGS.path}
            element={<Tracking1520LogsPage />}
          />
        </Route>
        <Route path={ROUTES.SETTINGS.path}>
          <Route
            path={ROUTES.SETTINGS.children.CONFIGURATION.path}
            element={<ConfigurationPage />}
          />
          <Route path={ROUTES.SETTINGS.children.ROLES.path} element={<RolesPage />} />
          <Route path={ROUTES.SETTINGS.children.CATALOGS.path}>
            <Route
              path={ROUTES.SETTINGS.children.CATALOGS.children.CURRENCY.path}
              element={<CurrencyPage />}
            />
            <Route
              path={ROUTES.SETTINGS.children.CATALOGS.children.ORGANIZATIONS.path}
              element={<OrganizationsPage />}
            />
            <Route
              path={ROUTES.SETTINGS.children.CATALOGS.children.INCONTERMS.path}
              element={<IncontermsPage />}
            />
            <Route
              path={ROUTES.SETTINGS.children.CATALOGS.children.STATIONS.path}
              element={<StationsPage />}
            />
            <Route
              path={ROUTES.SETTINGS.children.CATALOGS.children.COUNTRIES.path}
              element={<CountriesPage />}
            />
            <Route
              path={ROUTES.SETTINGS.children.CATALOGS.children.WAGONTYPE.path}
              element={<WagonTypePage />}
            />
          </Route>
          <Route path={ROUTES.SETTINGS.children.USERS.path} element={<UsersPage />} />
          <Route
            path={ROUTES.SETTINGS.children.MEDIA_LIBRARY.path}
            element={<MediaLibraryPage />}
          />
        </Route>
        <Route path='/' element={<Navigate to={ROUTES.CLIENTS.path} />} />
      </Route>
      <Route element={<UnAuthenticatedLayout />}>
        <Route path={ROUTES.LOGIN.path} element={<LoginPage />} />
      </Route>
      <Route path={ROUTES.FCR.path} element={<FcrPage />} />
    </Routes>
  );
}

export default App;
