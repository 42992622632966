import * as yup from 'yup';
import { errorTexts } from '@features/errors';

export const clientsSchema = yup.object().shape({
  name: yup.string().required(errorTexts.required()),
  organization: yup
    .object()
    .shape({
      value: yup.number().required('alo'),
      label: yup.string().required(errorTexts.required()),
    })
    .required(errorTexts.required()),
});
